import React from 'react'
import { Image as ChakraImage } from '@chakra-ui/core'

const Image = ({
    src,
    ...props
}) => (
    <ChakraImage
        src={`${process.env.REACT_APP_FRONTEND_URL}/${src}`}
        {...props}
    />
)

export default Image
