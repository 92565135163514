import { gql } from 'apollo-boost'

const CREATE_VECTOR_MUTATION = gql`
    mutation createVector($input: VectorStateInput) {
        createVectorState(input: $input) {
            id
        }
    }
`

export default async function createVectorState(apolloClient, input, options) {
    const mutation = CREATE_VECTOR_MUTATION
    const variables = { input }
    const { data } = await apolloClient.mutate({ mutation, variables, ...options })

    return data.createVectorState
}
