import { where, whereIsNot } from './index'

export const whereAssetId = where('assetId')

export const whereIsNotRenderId = whereIsNot('renderId')

export const getStateFromLocation = (location) => {
    if (typeof location !== 'undefined') {
        const { state } = location
        if (typeof state !== 'undefined') {
            return state[0]
        }
    }

    return {}
}

export const downloadImage = async (dataURL, imageName) => {
    const response = await fetch(dataURL)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = imageName
    a.click()
}
