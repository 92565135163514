import parse from 'date-fns/parse'

export const degreesToRadians = (degrees) => {
    /* eslint-disable no-mixed-operators */
    const radians = degrees * Math.PI / 180
    return radians
}

export const prefixDateTimeValue = (value) => {
    if (value < 10) {
        return `0${value}`
    }

    return value
}

export const dateStringToEuroDateString = (dateString) => {
    const date = new Date(dateString)
    return `${prefixDateTimeValue(date.getDate())}/${prefixDateTimeValue(date.getMonth() + 1)}/${date.getFullYear()}`
}

export const dateTimeStringToEuroDateTimeString = (dateString) => {
    const date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date())
    const dateTag = dateStringToEuroDateString(date)
    return `${dateTag} ${prefixDateTimeValue(date.getHours())}:${prefixDateTimeValue(date.getMinutes())}`
}
