import React from 'react'
import {
    Flex,
    Box,
    Slider,
    SliderTrack,
    SliderThumb,
    SliderFilledTrack,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import {
    SCALE_STEP,
    ASSET_MIN_SCALE,
    ASSET_TYPE_COMMENT,
    ASSET_CONTROL_MODE_ROTATE,
    ASSET_CONTROL_MODE_TRANSLATE,
} from 'editor/util/constants'
import AssetControlButton from 'components/util/buttons/ButtonWithTooltip'

const AssetControls = ({
    asset,
    assetControlMode,
    onChangeControlMode,
    onEditComment,
    onRemoveAsset,
    onDeselectAsset,
    onScaleAsset,
    isScalingAsset,
    onToggleScaleAsset,
    isAssetEditor,
}) => (
    <Flex
        pos="fixed"
        bottom={32}
        justify="center"
        w="full"
    >
        <Box
            bg="colorWhite"
            borderRadius="lg"
        >
            <Flex>

                <AssetControlButton
                    type="translate"
                    tooltip={<Trans>Move</Trans>}
                    onClick={() => onChangeControlMode(ASSET_CONTROL_MODE_TRANSLATE)}
                    isSelected={assetControlMode === ASSET_CONTROL_MODE_TRANSLATE}
                />
                <AssetControlButton
                    type="rotate"
                    tooltip={<Trans>Rotate</Trans>}
                    onClick={() => onChangeControlMode(ASSET_CONTROL_MODE_ROTATE)}
                    isSelected={assetControlMode === ASSET_CONTROL_MODE_ROTATE}
                />
                {(!isAssetEditor || (isAssetEditor && !asset.isReference)) && (
                    <AssetControlButton
                        type="scale"
                        tooltip={<Trans>Scale</Trans>}
                        onClick={onToggleScaleAsset}
                        isSelected={isScalingAsset}
                    />
                )}
                {!isAssetEditor && (
                    <>
                        {asset.assetType === ASSET_TYPE_COMMENT && (
                            <AssetControlButton
                                type="edit"
                                tooltip={<Trans>Edit Comment</Trans>}
                                onClick={() => onEditComment(asset)}
                            />
                        )}
                        <AssetControlButton
                            type="trash"
                            tooltip={<Trans>Delete item</Trans>}
                            onClick={() => onRemoveAsset(asset)}
                        />
                        <AssetControlButton
                            type="remove"
                            tooltip={<Trans>Deselect item</Trans>}
                            onClick={onDeselectAsset}
                        />
                    </>
                )}
            </Flex>
            {isScalingAsset && (
                <Slider
                    min={ASSET_MIN_SCALE}
                    max={10}
                    defaultValue={asset.scale.x}
                    step={SCALE_STEP}
                    onChange={onScaleAsset}
                >
                    <SliderTrack />
                    <SliderFilledTrack bg="primaryColor" />
                    <SliderThumb />
                </Slider>
            )}
        </Box>
    </Flex>
)

export default AssetControls
