import React from 'react'
import PageLoading from 'components/layout/PageLoading'
import PageError from 'components/layout/PageError'
import usePushHistory from 'components/hooks/usePushHistory'
import { editor } from 'routes/index'
import useAppState from 'components/hooks/useAppState'
import DesignsPage from './DesignsPage'
import useFetchDesignsPageData from './useFetchDesignsPageData'

const DesignsPageWithState = () => {
    const { slug } = useAppState()
    const pushRouteWithSlug = usePushHistory()
    const {
        error,
        isFetching,
        trajectory,
        updateQuery,
    } = useFetchDesignsPageData(slug)

    if (isFetching) {
        return <PageLoading />
    }
    if (typeof error !== 'undefined') {
        return <PageError error={error} />
    }

    return (
        <DesignsPage
            trajectory={trajectory}
            onViewDesign={(designId) => pushRouteWithSlug(editor, { designId })}
            onLikeDesignComplete={(updatedDesign) => {
                updateQuery(({ trajectory: cachedTrajectory }) => {
                    const phasesClone = cachedTrajectory.phases.map((phase) => {
                        if (phase.id === updatedDesign.phase.id) {
                            const designsClone = phase.designs.map((design) => {
                                if (design.id === updatedDesign.id) {
                                    return {
                                        ...design,
                                        ...updatedDesign,
                                    }
                                }
                                return design
                            })
                            return {
                                ...phase,
                                designs: designsClone,
                            }
                        }
                        return phase
                    })

                    return {
                        trajectory: {
                            ...cachedTrajectory,
                            phases: phasesClone,
                        },
                    }
                })
            }}
        />
    )
}

export default DesignsPageWithState
