import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import { isValidEmail, isValidLoginPassword } from 'validation/user'

const initialLoginFormValues = {
    email: '',
    password: '',
}

const validation = createFormValidation([{
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: <Trans key="email">Please fill in a valid email.</Trans>,
}, {
    path: 'password',
    validate: (password) => isValidLoginPassword(password),
    message: <Trans key="password">Please fill in your password</Trans>,
}])

const useLoginForm = (options) => (
    useFormState(
        initialLoginFormValues,
        {
            validation,
            ...options,
        },
    )
)

export default useLoginForm
