import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'

const EditorDesignInfoModalContent = ({
    trajectory,
    design,
    isReadOnly,
}) => {
    const {
        user,
        name,
        description,
    } = design

    return (
        <>
            <Box my={6}>
                <Heading fontSize="md">
                    <Trans>Idea</Trans>
                </Heading>
                <Text mt={1} color="secondaryTextColor">{name}</Text>
            </Box>
            <Box my={6}>
                <Heading fontSize="md">
                    <Trans>What is this idea about?</Trans>
                </Heading>
                <Text
                    mt={1}
                    color="secondaryTextColor"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </Box>
            <Box my={6}>
                <Heading fontSize="md">
                    <Trans>Trajectory</Trans>
                </Heading>
                <Text mt={1} color="secondaryTextColor">
                    {trajectory.title}
                </Text>
            </Box>
            <Box my={6}>
                <Heading fontSize="md">
                    <Trans>What is this trajectory about?</Trans>
                </Heading>
                <Text
                    mt={1}
                    color="secondaryTextColor"
                    dangerouslySetInnerHTML={{ __html: trajectory.description }}
                />
            </Box>
            {isReadOnly && (
                <Text
                    my={6}
                    color="primaryColor"
                >
                    <Trans>Created by</Trans>
                    {' '}
                    {user.isAnonymous ? <Trans>Anonymous</Trans> : user.username}
                </Text>
            )}
        </>
    )
}

export default EditorDesignInfoModalContent
