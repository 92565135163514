import React from 'react'
import { Trans } from '@lingui/macro'
import {
    Heading, Text, Flex, Box,
} from '@chakra-ui/core'
import Modal from 'components/util/Modal'
import MainButton from 'components/util/buttons/MainButton'

const AssetEditorInfoModal = ({
    onClose,
    assetName,
}) => (
    <Modal
        onClose={onClose}
        modalTitle={<Trans>Edit asset</Trans>}
        modalContent={(
            <>
                <Text color="secondaryTextColor">
                    <Trans>
                        Use this editor to correctly scale and position the
                        {' '}
                        {assetName}
                        {' '}
                        asset
                    </Trans>
                </Text>
                <Heading fontSize="md" mt={6}>
                    <Trans>Scale</Trans>
                </Heading>
                <Text
                    mt={1}
                    color="secondaryTextColor"
                >
                    <Trans>Scale the asset to the correct size using the controls at the bottom of the screen. Use the reference asset as a guideline.</Trans>
                </Text>
                <Heading fontSize="md" mt={6}>
                    <Trans>Position</Trans>
                </Heading>
                <Text
                    mt={1}
                    color="secondaryTextColor"
                >
                    <Trans>Position the asset exactly where the x, y and z axis intersect using the controls on the asset or by dragging the asset. Rotate the asset so its front side matches the blue arrow.</Trans>
                </Text>
                <Flex justify="flex-end">
                    <Box>
                        <MainButton
                            onClick={onClose}
                            mt={6}
                        >
                            <Trans>Got it!</Trans>
                        </MainButton>
                    </Box>
                </Flex>
            </>
        )}
    />
)

export default AssetEditorInfoModal
