import { gql } from 'apollo-boost'

const DEFAULT_TRAJECTORY_QUERY = gql`
    query defaultTrajectory {
        defaultTrajectory {
            slug
        }
    }
`

export default async function getDefaultTrajectorySlug(apolloClient) {
    try {
        const query = DEFAULT_TRAJECTORY_QUERY
        const { data } = await apolloClient.query({ query })

        return data.defaultTrajectory
    } catch (error) {
        return null
    }
}
