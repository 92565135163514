import React from 'react'
import {
    Flex,
    Box,
    Heading,
    Text,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import PageContainer from 'components/layout/PageContainer'
import { isNotEmpty } from 'validation/index'
import SectionBox from 'components/util/home/SectionBox'
import MainButton from 'components/util/buttons/MainButton'
import HomeHeader from './HomeHeader'
import HomeNews from './HomeNews'
import HomePhases from './HomePhases'
import HomePictures from './HomePictures'
import HomeMilestones from './HomeMilestones'
import HomeDesigns from './HomeDesigns'
import Footer from '../../layout/Footer'

const HomePage = ({
    onParticipate,
    isAuthenticated,
    isOnboardedForTrajectory,
    onShowAllDesigns,
    trajectory,
}) => {
    const {
        title,
        featured,
        description,
        news,
        phases,
        photos,
        milestones,
        designs,
    } = trajectory

    return (
        <>
            <HomeHeader
                onParticipate={onParticipate}
                onShowAllDesigns={onShowAllDesigns}
                isAuthenticated={isAuthenticated}
                isOnboardedForTrajectory={isOnboardedForTrajectory}
                title={title}
                featured={featured}
                description={description}
            />
            <PageContainer>
                <Flex
                    w="full"
                    justify="center"
                    flexWrap="wrap"
                >
                    {isNotEmpty(photos) && (
                        <SectionBox id="photos">
                            <HomePictures photos={photos} />
                        </SectionBox>
                    )}
                    {isNotEmpty(phases) && (
                        <SectionBox id="phases">
                            <HomePhases phases={phases} />
                        </SectionBox>
                    )}
                    {isNotEmpty(milestones) && (
                        <SectionBox id="milestones">
                            <HomeMilestones milestones={milestones} />
                        </SectionBox>
                    )}
                    {isNotEmpty(news) && (
                        <SectionBox id="news">
                            <HomeNews news={news} />
                        </SectionBox>
                    )}
                </Flex>
                <Box
                    mt={20}
                    textAlign="center"
                >
                    <Heading
                        as="h2"
                        fontSize="xl"
                    >
                        <Trans>Latest ideas</Trans>
                    </Heading>
                    {isNotEmpty(designs)
                        ? <HomeDesigns designs={designs} />
                        : (
                            <>
                                <Text my={8}>
                                    <Trans>There are no designs for this trajectory yet</Trans>
                                </Text>
                                <MainButton
                                    px={6}
                                    py={4}
                                    onClick={onParticipate}
                                >
                                    {isAuthenticated && isOnboardedForTrajectory
                                        ? <Trans>Create a design</Trans>
                                        : <Trans>Partake in this trajectory</Trans>}
                                </MainButton>
                            </>
                        )}
                </Box>
                <Footer />
            </PageContainer>
        </>
    )
}

export default HomePage
