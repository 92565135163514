import React from 'react'
import {
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Button,
} from '@chakra-ui/core'
import { withI18n } from '@lingui/react'

const EditorContentMenuSearch = withI18n()(({
    i18n,
    searchTerm,
    onChangeSearchTerm,
}) => (
    <InputGroup>
        <InputLeftElement>
            <Icon
                name="search"
                size="24px"
                mt={3}
                ml={3}
                stroke="primaryIconColor"
                fill="colorGrayWhite"
            />
        </InputLeftElement>
        <Input
            bg="colorGrayWhite"
            focusBorderColor="primaryColor"
            placeholder={i18n._('Search')}
            value={searchTerm}
            onChange={({ target }) => onChangeSearchTerm(target.value)}
        />
        {searchTerm !== '' && (
            <InputRightElement>
                <Button
                    variant="unstyled"
                    onClick={() => onChangeSearchTerm('')}
                >
                    <Icon
                        name="remove"
                        size="24px"
                        stroke="primaryIconColor"
                        fill="colorGrayWhite"
                    />
                </Button>
            </InputRightElement>
        )}
    </InputGroup>
))

export default EditorContentMenuSearch
