import React from 'react'
import {
    Box,
    Flex,
    Text,
    Icon,
    Image,
    Button,
    Tooltip,
} from '@chakra-ui/core'
import useEditorState from 'components/hooks/useEditorState'
import { Trans } from '@lingui/macro'

const rowCount = 2
const totalDesignWidth = 90
const thumbnailWidth = totalDesignWidth / rowCount
const thumbnailMargin = (100 - totalDesignWidth) / rowCount

const AssetList = ({ assets }) => {
    const {
        addAsset,
        setEditorIsWaiting,
    } = useEditorState()

    return (
        <Flex
            maxH={64}
            overflowY="auto"
            flexWrap="wrap"
            w="full"
        >
            {assets.map((asset, index) => {
                const listAsset = {
                    ...asset,
                    assetId: asset.id,
                }
                const hasPreview = asset.preview.urls.thumb !== null

                return (
                    <Box
                        key={asset.id}
                        w={`${thumbnailWidth}%`}
                        my={2}
                        ml={index % rowCount === 0
                            ? `${thumbnailMargin / rowCount}%`
                            : `${thumbnailMargin}%`}
                    >
                        <Tooltip
                            label={(
                                <Trans>
                                    Click to add
                                    {' '}
                                    {asset.name}
                                </Trans>
                            )}
                            placement="top"
                        >
                            <Button
                                variant="outline"
                                borderColor="primaryColor"
                                w="100%"
                                h="125px"
                                px={0}
                                onClick={async () => {
                                    setEditorIsWaiting(true)
                                    await addAsset(listAsset, true)
                                    setEditorIsWaiting(false)
                                }}
                            >
                                {hasPreview
                                    ? (
                                        <Image
                                            rounded="md"
                                            size="100%"
                                            src={asset.preview.urls.thumb}
                                        />
                                    )
                                    : (
                                        <Icon
                                            name="photo"
                                            size="24px"
                                            stroke="primaryColor"
                                            fill="none"
                                            m="auto"
                                        />
                                    )}
                            </Button>
                        </Tooltip>
                        <Text
                            textAlign="center"
                            fontWeight="medium"
                        >
                            {asset.name}
                        </Text>
                    </Box>
                )
            })}
        </Flex>
    )
}

export default AssetList
