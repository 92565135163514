import React from 'react'
import useQuery from 'components/hooks/useQuery'
import useAppState from 'components/hooks/useAppState'
import useUserStatus from 'components/hooks/useUserStatus'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import PageLoading from 'components/layout/PageLoading'
import AssetEditor from 'editor/AssetEditor'
import AdminEditorLoginModalWithState from '../admin-editor/AdminEditorLoginModalWithState'
import AssetEditorPageWithState from './AssetEditorPageWithState'

const AssetEditorAuthPage = () => {
    const query = useQuery()
    const assetId = query.get('assetId')
    const {
        isAuthenticated,
        hasAdminEditorAccess,
    } = useUserStatus()
    const { logout } = useAppState()

    useAsyncEffect(async () => {
        if (isAuthenticated && !hasAdminEditorAccess) {
            await logout(false)
        }
    }, [isAuthenticated, hasAdminEditorAccess])

    return (
        <>
            {(!isAuthenticated || !hasAdminEditorAccess) ? (
                <PageLoading />
            ) : (
                <AssetEditor>
                    <AssetEditorPageWithState assetId={assetId} />
                </AssetEditor>
            )}
            {!hasAdminEditorAccess && (
                <AdminEditorLoginModalWithState
                    isAuthenticated={isAuthenticated}
                />
            )}
        </>
    )
}

export default AssetEditorAuthPage
