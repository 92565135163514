import React, { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/core'
import { useApolloClient } from '@apollo/react-hooks'
import { getStateFromLocation } from 'util/entities'
import useModalState from 'components/hooks/useModalState'
import Editor3DWithProvider from 'editor/Editor3DWithProvider'
import Editor2DWithProvider from 'editor/Editor2DWithProvider'
import PageLoading from 'components/layout/PageLoading'
import PageError from 'components/layout/PageError'
import useUserStatus from 'components/hooks/useUserStatus'
import PageNotFound from 'components/layout/PageNotFound'
import useQuery from 'components/hooks/useQuery'
import { DESIGN_STATUS_DRAFT } from 'util/constants'
import EditDesignModalWithState from './editor-edit-design/EditDesignModalWithState'
import EditorShotsModalWithState from './editor-screenshots/EditorShotsModalWithState'
import EditorShareModalWithState from './editor-share/EditorShareModalWithState'
import EditorScreenshotModalWithState from './EditorScreenshotModalWithState'
import CommentModalWithState from './CommentModalWithState'
import useFetchEditorPageData from './useFetchEditorPageData'
import EditorPage from './EditorPage'
import EditorDesignInfoModal from './editor-design-info/EditorDesignInfoModal'

const EditorPageWithState = ({
    isAdminEditor,
    editorDesignId,
    match,
    location,
}) => {
    const toast = useToast()
    const query = useQuery()
    let slug
    let designId
    if (isAdminEditor) {
        slug = null
        designId = editorDesignId
    } else {
        ({ slug } = match.params);
        ({ designId } = getStateFromLocation(location))
        if (typeof designId === 'undefined') {
            designId = query.get('designId')
        }
    }
    const shotsModalState = useModalState()
    const screenshotModalState = useModalState()
    const commentModalState = useModalState()
    const editModalState = useModalState()
    const apolloClient = useApolloClient()
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    const [isEditorDesignInfoModalOpen, setIsEditorDesignInfoModalOpen] = useState(false)
    const [editModalHasBeenOpened, setEditModalHasBeenOpened] = useState(false)
    const { currentUser, isAuthenticated } = useUserStatus()
    const [fetchEditorPageData, {
        error,
        loading: isFetching,
        data,
        updateQuery,
    }] = useFetchEditorPageData(isAdminEditor)

    useEffect(() => {
        if (typeof designId !== 'undefined') {
            fetchEditorPageData({ variables: { id: designId, slug } })
        }
    }, [fetchEditorPageData, designId, slug])

    useEffect(() => {
        const dataIsFetched = !isFetching && typeof data !== 'undefined'
        if (dataIsFetched) {
            const designIsDraft = data.design.status === DESIGN_STATUS_DRAFT && !editModalHasBeenOpened
            if (!isAdminEditor && designIsDraft) {
                setIsEditorDesignInfoModalOpen(true)
                setEditModalHasBeenOpened(true)
            }
        }
    }, [isFetching, data, editModalHasBeenOpened, isAdminEditor])

    if (typeof designId === 'undefined') {
        return <PageNotFound />
    }
    if (isFetching || typeof data === 'undefined') {
        return <PageLoading />
    }
    if (typeof error !== 'undefined') {
        return <PageError error={error} />
    }

    const { trajectory, design } = data
    design.trajectory = trajectory
    let isReadOnly = true
    if ((isAdminEditor && isAuthenticated) || (currentUser !== null && design.user.id === currentUser.id)) {
        isReadOnly = false
    }
    const EditorWithProvider = design.is2D ? Editor2DWithProvider : Editor3DWithProvider

    return (
        <EditorWithProvider
            onStartComment={commentModalState.open}
            design={design}
            apolloClient={apolloClient}
            isReadOnly={isReadOnly}
            toast={toast}
        >
            <EditorPage
                design={design}
                isAdminEditor={isAdminEditor}
                isReadOnly={isReadOnly}
                onEditComment={(asset) => commentModalState.open({ asset })}
                onOpenEditModal={(state) => editModalState.open(state)}
                onOpenShotsModal={() => shotsModalState.open()}
                onOpenShareModal={() => setIsShareModalOpen(true)}
                onTakeScreenshot={(dataURL) => screenshotModalState.open({ dataURL, isNewScreenshot: true })}
                onOpenEditorDesignInfoModal={() => setIsEditorDesignInfoModalOpen(true)}
            />
            {editModalState.isOpen && (
                <EditDesignModalWithState
                    designId={design.id}
                    isAdminEditor={isAdminEditor}
                    onClose={editModalState.close}
                    onChangeFeaturedScreenshot={(setScreenshot) => shotsModalState.open({ setScreenshot })}
                    onUpdateDesignComplete={(updatedDesign) => {
                        updateQuery(({ design: cachedDesign }) => {
                            const designClone = {
                                ...cachedDesign,
                                ...updatedDesign,
                            }

                            return { design: designClone }
                        })
                    }}
                    {...editModalState.initialState}
                />
            )}
            {shotsModalState.isOpen && (
                <EditorShotsModalWithState
                    onClose={shotsModalState.close}
                    onOpenShot={(dataURL, shotId, isNewScreenshot) => screenshotModalState.open({ dataURL, shotId, isNewScreenshot })}
                    designId={design.id}
                    {...shotsModalState.initialState}
                />
            )}
            {isShareModalOpen && (
                <EditorShareModalWithState
                    onClose={() => setIsShareModalOpen(false)}
                    isReadOnly={isReadOnly}
                    onChangeSharedImage={(setScreenshot) => shotsModalState.open({ setScreenshot })}
                    designId={design.id}
                    slug={slug}
                />
            )}
            {screenshotModalState.isOpen && (
                <EditorScreenshotModalWithState
                    onClose={screenshotModalState.close}
                    designId={design.id}
                    {...screenshotModalState.initialState}
                />
            )}
            {commentModalState.isOpen && (
                <CommentModalWithState
                    onClose={commentModalState.close}
                    {...commentModalState.initialState}
                />
            )}
            {isEditorDesignInfoModalOpen && (
                <EditorDesignInfoModal
                    onClose={() => setIsEditorDesignInfoModalOpen(false)}
                    designId={design.id}
                    slug={slug}
                    isReadOnly={isReadOnly}
                />
            )}
        </EditorWithProvider>
    )
}

export default EditorPageWithState
