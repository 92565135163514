import React from 'react'
import { Heading } from '@chakra-ui/core'
import AssetList from '../asset-menu/AssetList'
import EditorMenuLoading from '../EditorMenuLoading'
import CategoryList from '../category-menu/CategoryList'
import EditorContentMenuPageContainer from '../EditorContentMenuPageContainer'

const SubcategoryMenu = ({
    category,
    onChangeSubcategory,
    isFetching,
    subcategories,
    assets,
    goBackToCategoryMenu,
}) => (
    <EditorContentMenuPageContainer goBack={goBackToCategoryMenu}>
        <Heading
            as="h2"
            fontSize="lg"
            mb={6}
        >
            {category.name}
        </Heading>
        {isFetching && <EditorMenuLoading />}
        <CategoryList
            categories={subcategories}
            onChangeCategory={onChangeSubcategory}
        />
        <AssetList assets={assets} />
    </EditorContentMenuPageContainer>
)

export default SubcategoryMenu
