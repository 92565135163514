import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const ONBOARD_USER_MUTATION = gql`
    mutation onboardUser($slug: String!, $input: OnboardUserInput!) {
        onboardUser(input: $input) {
            id
            username
            email
            age
            sex
            isAnonymous
            isOnboardedForTrajectory(slug: $slug)
        }
    }
`

const useOnboardUserMutation = () => useMutation(ONBOARD_USER_MUTATION)

export default useOnboardUserMutation
