export default function initializeUsersnap({ pathname }) {
    const { REACT_APP_ENV } = process.env

    if (REACT_APP_ENV === 'staging' && !pathname.includes('asset-preview')) {
        const s = document.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = '//api.usersnap.com/load/0c175d6c-1145-4b67-93c1-ac79c15adf4d.js'
        const x = document.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
    }
}
