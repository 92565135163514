import React, { useState } from 'react'
import useEditorState from 'components/hooks/useEditorState'
import AssetMenuWithState from './asset-menu/AssetMenuWithState'
import CategoryMenuWithState from './category-menu/CategoryMenuWithState'
import SubCategoryMenuWithState from './sub-category-menu/SubcategoryMenuWithState'

const EditorContentMenuWithState = () => {
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSubcategory, setSelectedSubcategory] = useState(null)
    const { design } = useEditorState()

    if (selectedCategory === null) {
        return (
            <CategoryMenuWithState onChangeCategory={(category) => setSelectedCategory(category)} />
        )
    }
    if (selectedSubcategory === null) {
        return (
            <SubCategoryMenuWithState
                category={selectedCategory}
                onChangeSubcategory={(subcategory) => setSelectedSubcategory(subcategory)}
                goBackToCategoryMenu={() => setSelectedCategory(null)}
                is2D={design.is2D}
            />
        )
    }

    return (
        <AssetMenuWithState
            category={selectedSubcategory}
            goBackToSubcategoryMenu={() => setSelectedSubcategory(null)}
            is2D={design.is2D}
        />
    )
}

export default EditorContentMenuWithState
