import React from 'react'
import {
    Box,
    Heading,
    Stack,
    Flex,
    Icon,
    Text,
    Button,
    PseudoBox,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Confetti from 'react-dom-confetti'
import Link from 'components/util/Link'
import UserIcon from 'components/util/UserIcon'
import { getDesignCardImageUrlFromDesign } from 'util/design'

const DesignCard = ({
    design,
    canLike,
    onViewDesign,
    onToggleLike,
}) => {
    const {
        name,
        user,
        id,
        likes,
        likedByCurrentUser,
    } = design

    return (
        <Box w="xs">
            <PseudoBox
                w="full"
                h="xs"
                bgImage={`url(${getDesignCardImageUrlFromDesign(design)})`}
                bgSize="cover"
                bgPos="center"
                rounded="lg"
                onClick={() => onViewDesign(id)}
                _hover={{
                    cursor: 'pointer',
                }}
            />
            <Flex w="full" mt={4} justify="space-between">
                <Flex>
                    <Stack
                        h="full"
                        justify="center"
                    >
                        <UserIcon />
                    </Stack>
                    <Box ml={4}>
                        <Link color="primaryTextColor" linkTo={() => onViewDesign(id)}>
                            <Heading as="h3" fontSize="lg">{name}</Heading>
                        </Link>
                        <Box mt={1}>
                            <Link>
                                <Trans>By</Trans>
                                {' '}
                                {user.username === null
                                    ? <Trans>Anonymous</Trans>
                                    : user.username}
                            </Link>
                        </Box>
                    </Box>
                </Flex>
                <Stack justify="flex-end">
                    <Flex>
                        <Button
                            minW={0}
                            w="24px"
                            h="24px"
                            variant="unstyled"
                            onClick={onToggleLike}
                            disabled={!canLike}
                        >
                            <Icon name="heart" size="24px" stroke={likedByCurrentUser ? 'primaryColor' : 'secondaryTextColor'} fill={likedByCurrentUser ? 'primaryColor' : 'none'} />
                            <Confetti active={likedByCurrentUser} />
                        </Button>
                        <Text fontWeight="bold" color="secondaryTextColor">{likes}</Text>
                    </Flex>
                </Stack>
            </Flex>
        </Box>
    )
}

export default DesignCard
