import React from 'react'
import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import GetStartedButton from 'components/util/buttons/GetStartedButton'
import Link from 'components/util/Link'
import UserMenu from 'components/util/UserMenu'
import Image from 'components/util/Image'

const HomeHeader = ({
    onParticipate,
    onShowAllDesigns,
    isAuthenticated,
    isOnboardedForTrajectory,
    title,
    featured,
    description,
}) => (
    <>
        <Box
            id="home"
            bgImage={featured === null
                ? 'url(/home/heading-fallback.png)'
                : `url(${featured.urls.featured})`}
            bgSize="cover"
            h={['25%', '30%', '35%', '40%']}
        />
        <Flex
            px="5%"
            justify="center"
            flexWrap="wrap"
            mt="-7rem"
        >
            <Stack
                w="2xl"
                minH="sm"
                bg="colorWhite"
                p={10}
                rounded="lg"
                shadow="lg"
                justify="space-between"
            >
                <Box>
                    <Heading
                        as="h1"
                        fontSize="2xl"
                        mb={8}
                    >
                        {title}
                    </Heading>
                    <Text
                        color="colorGrayDark"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </Box>
                <Flex
                    flexWrap="wrap"
                    mt={1}
                >
                    <Box
                        textAlign="center"
                        flex="1"
                    >
                        <GetStartedButton
                            px={12}
                            py={4}
                            onClick={onParticipate}
                            isAuthenticated={isAuthenticated}
                            isOnboardedForTrajectory={isOnboardedForTrajectory}
                        />
                    </Box>
                    <Stack
                        flex="1"
                        align="center"
                        justify="center"
                    >
                        <Link
                            whiteSpace="nowrap"
                            linkTo={onShowAllDesigns}
                        >
                            <Trans>Show all ideas</Trans>
                        </Link>
                    </Stack>
                </Flex>
            </Stack>
            <Stack
                w="md"
                minH={[
                    isAuthenticated ? 'sm' : 'xs',
                    isAuthenticated ? 'sm' : 'xs',
                    isAuthenticated ? 'sm' : 'xs',
                    'sm',
                ]}
                alignItems="center"
                justify={isAuthenticated ? 'space-between' : 'flex-end'}
            >
                {isAuthenticated && (
                    <UserMenu
                        onStart={onParticipate}
                        shadow={['lg', 'lg', 'lg', 'none']}
                        mt={[8, 8, 8, 0]}
                        ml={[0, 0, 0, 24]}
                    />
                )}
                <Box>
                    <Text
                        color="secondaryTextColor"
                        fontWeight="medium"
                    >
                        <Trans>Participate too thanks to</Trans>
                        :
                    </Text>
                    <Image src="logos/logo.png" />
                    <Image
                        w="265px"
                        mt={4}
                        src="home/sub-logo.png"
                    />
                </Box>
            </Stack>
        </Flex>
    </>
)

export default HomeHeader
