import React from 'react'
import { Box, Spinner } from '@chakra-ui/core'

const EditorMenuLoading = () => (
    <Box textAlign="center">
        <Spinner
            size="xl"
            my={8}
            color="colorGray"
        />
    </Box>
)

export default EditorMenuLoading
