import React from 'react'
import {
    Icon, Flex, Button, Stack,
} from '@chakra-ui/core'

const ImageSliderControls = ({
    onPrev,
    onNext,
}) => (
    <Flex
        w="full"
        h="full"
        justify="space-between"
        alignItems="center"
    >
        <Button
            minW="24px"
            h="24px"
            ml={4}
            variant="unstyled"
            bg="colorWhite"
            rounded="full"
            onClick={onPrev}
        >
            <Stack>
                <Icon
                    name="arrowLeft"
                    size="24px"
                    stroke="colorBlack"
                    fill="colorWhite"
                />
            </Stack>
        </Button>
        <Button
            minW="24px"
            h="24px"
            mr={4}
            variant="unstyled"
            bg="colorWhite"
            rounded="full"
            onClick={onNext}

        >
            <Stack>
                <Icon
                    name="arrowRight"
                    size="24px"
                    stroke="colorBlack"
                    fill="colorWhite"
                />
            </Stack>
        </Button>
    </Flex>
)

export default ImageSliderControls
