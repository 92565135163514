import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import DEFAULT_ASSET_FRAGMENT from 'apollo/fragments/defaultAsset'

export const SUB_CATEGORY_MENU_QUERY = gql`
    query subcategoryMenuQuery($slug: String!, $where: AssetsWhereWhereConditions) {
        category(slug: $slug) {
            id
            children {
                id
                name
                slug
            }
            assets(where: $where) {
                ...defaultAsset
            }
        }
    }
    ${DEFAULT_ASSET_FRAGMENT}
`

const useFetchSubcategoryMenuData = (slug, is2D) => {
    const variables = {
        slug,
        where: {
            AND: [{
                column: is2D ? 'IS2D' : 'IS3D',
                value: true,
            }, {
                column: 'TYPE',
                value: null,
            }],
        },
    }
    const {
        loading,
        data,
    } = useQuery(SUB_CATEGORY_MENU_QUERY, { variables })

    if (loading) {
        return ({
            isFetching: true,
            subcategories: [],
            assets: [],
        })
    }

    const { category: { children, assets } } = data

    return ({
        isFetching: false,
        subcategories: children,
        assets,
    })
}

export default useFetchSubcategoryMenuData
