import React from 'react'

/* eslint-disable */
export const customIcons = {
    edit: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M5.586 15.323L15.628 5.28a1 1 0 011.414 0l1.677 1.677a1 1 0 010 1.414L8.677 18.414A2 2 0 017.263 19H5v-2.263a2 2 0 01.586-1.414v0zM13.759 7.149l2.915 2.915" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    screenshot: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 3H5.25A2.25 2.25 0 003 5.25V7.5M3 16.5v2.25A2.25 2.25 0 005.25 21H7.5M16.5 21h2.25A2.25 2.25 0 0021 18.75V16.5M21 7.5V5.25A2.25 2.25 0 0018.75 3H16.5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.038 14.454a1.837 1.837 0 100-3.674 1.837 1.837 0 000 3.674z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.625 10.125v5.625c0 .69-.56 1.25-1.25 1.25h-8.75c-.69 0-1.25-.56-1.25-1.25v-5.625c0-.69.56-1.25 1.25-1.25h1.25l.914-1.565a.624.624 0 01.54-.31h3.315c.219 0 .422.115.536.303l.946 1.572h1.25c.689 0 1.249.56 1.249 1.25v0z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    share: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M6.667 14.667a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334zM17.333 9.334a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334zM14.667 8l-5.334 2.667M17.333 20a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334zM14.667 16l-5.334-2.667" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    remove: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M19 5L5 19M19 19L5 5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    translate: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2v7M22 12h-7M12 22v-7M2 12h7M15 5l-3-3-3 3M19 15l3-3-3-3M9 19l3 3 3-3M5 9l-3 3 3 3" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    rotate: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M17 15.241c2.411-.807 4-2.181 4-3.741C21 9.015 16.971 7 12 7s-9 2.015-9 4.5S7.029 16 12 16" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 15.241c2.411-.807 4-2.181 4-3.741C21 9.015 16.971 7 12 7s-9 2.015-9 4.5S7.029 16 12 16M13 16l-3-3M10 19l3-3M12 16h1" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    scale: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M10.224 21.03H4.806A1.807 1.807 0 013 19.224v-5.418C3 12.809 3.809 12 4.806 12h5.418c.997 0 1.806.809 1.806 1.806v5.418c0 .997-.809 1.806-1.806 1.806v0zM11.501 12.529L16.03 8M16.03 12V8M12.03 8h4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.108 21.456a1 1 0 100-2 1 1 0 000 2zM21.459 21.456a1 1 0 100-2 1 1 0 000 2zM21.459 17.051a1 1 0 100-2 1 1 0 000 2zM21.459 12.701a1 1 0 100-2 1 1 0 000 2zM21.459 8.35a1 1 0 100-2 1 1 0 000 2zM21.459 4a1 1 0 100-2 1 1 0 000 2zM17.108 4a1 1 0 100-2 1 1 0 000 2zM12.758 4a1 1 0 100-2 1 1 0 000 2zM8.408 4.032a1 1 0 100-2 1 1 0 000 2zM4.057 4.032a1 1 0 100-2 1 1 0 000 2zM4.057 8.383a1 1 0 100-2 1 1 0 000 2z" fill="#CBD5E0" />
            </svg>
        ),
    },
    trash: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M16.138 21h-8.28a2 2 0 01-1.995-1.853L4.966 7H19l-.867 12.142A2 2 0 0116.138 21v0zM12 11v6M4 7h16M13.764 3h-3.528a2 2 0 00-1.789 1.106L7 7h10l-1.447-2.894A2 2 0 0013.764 3v0zM15.429 11L15 17M8.571 11L9 17" stroke="#FA5252" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    collapseRight: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12h19M2 20h9M2 4h9M16 17l5-5-5-5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    collapseLeft: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M22 12H3M22 20h-9M22 4h-9M8 17l-5-5 5-5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    search: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M12.667 12.667L8.966 8.966M5.667 10.333a4.667 4.667 0 100-9.333 4.667 4.667 0 000 9.333z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
            </svg>
        ),
    },
    arrowLeft: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8l-4 4 4 4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    arrowRight: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M10 16l4-4-4-4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    arrowDown: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M8 10l4 4 4-4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    arrowUp: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M16 14l-4-4-4 4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    arrowUndo: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5L5 8L8 11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 8H14C17.314 8 20 10.462 20 13.5C20 16.538 17.314 19 14 19H6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        ),
    },
    grid: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M19.535 17.407l-14 3.889A2 2 0 013 19.369V4.631a2 2 0 012.535-1.927l14 3.889A2 2 0 0121 8.52v6.96a2 2 0 01-1.465 1.927zM9 4.548v14.904M15 6.097v11.776M3.195 12H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    email: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M3 6.782c0 .591.293 1.144.782 1.475l5.981 4.053a4 4 0 004.488 0l5.966-4.044c.49-.33.783-.883.783-1.474v-.01C21 5.798 20.202 5 19.218 5H4.782C3.798 5 3 5.798 3 6.782z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V7M3.586 18.414l6.14-6.14M14.281 12.281l6.13 6.13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    heart: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M13.696 1C16.871 1 19 3.98 19 6.755 19 12.388 10.161 17 10 17c-.161 0-9-4.612-9-10.245C1 3.98 3.129 1 6.304 1 8.119 1 9.311 1.905 10 2.711 10.689 1.905 11.881 1 13.696 1z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    power: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4v8M17.657 6.343a8 8 0 11-11.314 0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    drawCurved: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M10.631 17.437l8.489-8.489a1 1 0 011.414 0l1.078 1.078a1 1 0 010 1.414l-8.491 8.491a2 2 0 01-.955.532l-1.648.389a.67.67 0 01-.806-.805l.386-1.651c.085-.363.27-.695.533-.959v0zM20.427 12.626l-2.492-2.492M7.094 20.837h-.151a4.85 4.85 0 01-1.969-9.28l4.019-1.786A3.538 3.538 0 007.556 3h-.462" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    drawStraight: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M10.631 17.437l8.489-8.489a1 1 0 011.414 0l1.078 1.078a.998.998 0 010 1.414l-8.491 8.491a2 2 0 01-.955.532l-1.648.389a.67.67 0 01-.806-.805l.386-1.651c.085-.363.27-.695.533-.959v0zM17.53 10.53l2.5 2.5M7 21l-5.5-5.5L12 8 5 3.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    info: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21a9 9 0 110-18 9 9 0 010 18v0zM12 12v5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 9a1 1 0 100-2 1 1 0 000 2z" fill="#A0AEC0" />
            </svg>
        ),
    },
    message: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M21 3v15M3 3h18M3 18V3M7 8h10M7 12h7M15 18v3.513M21 18h-6M15 21.513L10 18M10 18H3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),
    },
    home: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M2 10l10-7 10 7M20 21V8.6M4 21h16M4 8.6V21M13 13h-2a2 2 0 00-2 2v6h6v-6a2 2 0 00-2-2z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    profile: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M12 10.492a3.492 3.492 0 100-6.984 3.492 3.492 0 000 6.984zM20 18.492v1a1 1 0 01-1 1H5a1 1 0 01-1-1v-1M4 18.492c0-3.026 3.952-4.992 8-4.992M12 13.5c4.048 0 8 1.967 8 4.992" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    facebook: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M18.333 0H1.667C.746 0 0 .746 0 1.667v16.666C0 19.254.746 20 1.667 20h9.166v-7.5h-2.5V9.167h2.5V7.01c0-2.583 1.578-3.99 3.883-3.99 1.104 0 2.052.082 2.329.119v2.7h-1.598c-1.254 0-1.496.597-1.496 1.47v1.857h3.7l-.834 3.333h-2.866V20h4.382c.921 0 1.667-.746 1.667-1.667V1.667C20 .746 19.254 0 18.333 0z"/>
            </svg>
        ),
    },
    download: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M6.324 9.068C3.885 9.401 2 11.47 2 14a5 5 0 005 5h11a4 4 0 100-8A5.997 5.997 0 106.324 9.068v0z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.167 13.333L12 15.5l-2.167-2.167M12 10.5v5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    zoomIn: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M14.193 5.582a5.972 5.972 0 11-8.378 8.513 5.972 5.972 0 018.378-8.513M14.15 14.06L20 19.99M8 10h4M10 12V8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    zoomOut: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M14.16 5.715a5.972 5.972 0 11-8.379 8.513 5.972 5.972 0 018.378-8.513M14.15 14.06L20 19.99M8 10h4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    plus: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8v8M16 12H8M12 21a9 9 0 110-18 9 9 0 010 18v0z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    minus: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M16 12H8M12 21a9 9 0 110-18 9 9 0 010 18v0z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    photo: {
        path: (
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M19.997 21H4.003a2 2 0 01-2-2V5a2 2 0 012-2h15.994a2 2 0 012 2v14a2 2 0 01-2 2z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.414 7.586a2 2 0 11-2.779 2.877 2 2 0 012.779-2.877M22 16l-4.219-3.375a1 1 0 00-1.406.156l-3.336 4.17a1 1 0 01-1.406.156l-1.868-1.495a1 1 0 00-1.393.141L4 21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
}
