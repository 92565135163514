import React from 'react'
import {
    List,
    PseudoBox,
    useTheme,
    Divider,
    Icon,
    Text,
    Flex,
    Box,
} from '@chakra-ui/core'

const CategoryList = ({
    categories,
    onChangeCategory,
}) => {
    const theme = useTheme()

    return (
        <Box
            maxH={64}
            overflowY="auto"
        >
            <List>
                {categories.map((category) => (
                    <Box key={category.id}>
                        <Divider mb={4} />
                        <PseudoBox
                            onClick={() => onChangeCategory(category)}
                            color="colorGray"
                            stroke="colorGrayLight"
                            px={4}
                            _hover={{
                                color: 'primaryColor',
                                stroke: theme.colors.primaryColor,
                                cursor: 'pointer',
                            }}
                        >
                            <Flex justify="space-between">
                                <Text fontWeight="bold">
                                    {category.name}
                                </Text>
                                <Icon
                                    name="arrowRight"
                                    size="24px"
                                    fill="colorWhite"
                                />
                            </Flex>
                        </PseudoBox>
                    </Box>
                ))}
            </List>
        </Box>
    )
}

export default CategoryList
