import { gql } from 'apollo-boost'

const DELETE_DESIGN_ASSET_MUTATION = gql`
    mutation deleteDesignAsset($id: Int!) {
        deleteDesignAssetState(id: $id) {
            id
        }
    }
`

export default async function deleteDesignAssetState(apolloClient, id, options) {
    const mutation = DELETE_DESIGN_ASSET_MUTATION
    const variables = { id }
    await apolloClient.mutate({ mutation, variables, ...options })
}
