import React from 'react'
import PageHeader from 'components/layout/PageHeader'
import PageContainer from 'components/layout/PageContainer'
import { LOGGED_IN_HEADER_PROFILE } from 'util/constants'
import ProfileDesignsList from './ProfileDesignsList'
import Footer from '../../layout/Footer'
import ProfilePageHeader from './ProfilePageHeader'

const ProfilePage = ({
    user,
    designs,
    onUpdateUser,
    onContinueWithDesign,
}) => (
    <PageContainer>
        <PageHeader page={LOGGED_IN_HEADER_PROFILE} />
        <ProfilePageHeader
            user={user}
            onUpdateUser={onUpdateUser}
        />
        <ProfileDesignsList
            designs={designs}
            onContinueWithDesign={onContinueWithDesign}
        />
        <Footer />
    </PageContainer>
)

export default ProfilePage
