export const attachHoverListeners = (canvas, callback) => {
    canvas.addEventListener('mousemove', callback, false)
}

export const detachHoverListeners = (canvas, callback) => {
    canvas.removeEventListener('mousemove', callback, false)
}

export const attachPlaceCommentListeners = (canvas, callback) => {
    canvas.addEventListener('click', callback, false)
    canvas.addEventListener('touchstart', callback, false)
}

export const detachPlaceCommentListeners = (canvas, callback) => {
    canvas.removeEventListener('click', callback, false)
    canvas.removeEventListener('touchstart', callback, false)
}
