import React from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader'
import { DragControls } from 'three/examples/jsm/controls/DragControls'
import deleteDesignAssetState from 'api/deleteDesignAssetState'
import deleteVectorState from 'api/deleteVectorState'
import EditorStateProvider from 'components/providers/EditorStateProvider'
import { whereAssetId, whereIsNotRenderId } from 'util/entities'
import CurvedLineDrawer from './CurvedLineDrawer'
import StraightLineDrawer from './StraightLineDrawer'
import {
    calculateIntersects,
    getClickedPoint,
    getClickedAssetFromIntersects,
    createSurface,
    createDrawingPlane,
    createLights,
    showCommentTooltip,
    hideCommentTooltip,
    createLine,
    isImage,
    createImagePlane,
} from './util/three'
import {
    DRAW_MODE_CURVED,
    EDITOR_CONTROL_MODE_ORBIT,
    ASSET_CONTROL_MODE_TRANSLATE,
    DRAW_MODE_NONE,
    ASSET_TYPE_COMMENT,
    ASSET_TYPE_VECTOR,
    ASSET_TYPE_GLTF,
    ASSET_TYPE_COLLADA,
    ZOOM_STEP,
    ZOOM_IN,
    ASSET_CONTROL_MODE_ROTATE,
} from './util/constants'
import {
    detachHoverListeners,
    attachHoverListeners,
    attachPlaceCommentListeners,
    detachPlaceCommentListeners,
} from './util/listeners'
import {
    createAssetState,
    updateAssetState,
    updateVectorAssetState,
} from './util/api'

class EditorWithProvider extends React.Component {
    constructor(props) {
        super(props)

        // ThreeJS
        this.scene = new THREE.Scene()
        this.camera = null
        this.renderer = new THREE.WebGLRenderer({ antialias: true })
        this.grid = null
        this.drawingPlane = null
        this.drawer = null
        this.editorControls = null
        this.assetControls = null
        this.dragControls = null
        this.requestId = null
        this.gltfLoader = new GLTFLoader()
        this.colladaLoader = new ColladaLoader()
        this.textureLoader = new THREE.TextureLoader()
        this.commentAsset = null
        this.commentTooltip = null

        // Props
        this.onStartComment = props.onStartComment
        this.design = props.design || null
        this.apolloClient = props.apolloClient
        this.isReadOnly = props.isReadOnly
        this.toast = props.toast

        // General
        this.initializeEditor = this.initializeEditor.bind(this)
        this.cleanUpEditor = this.cleanUpEditor.bind(this)
        this.findHoveredAsset = this.findHoveredAsset.bind(this)
        this.startComment = this.startComment.bind(this)
        this.placeComment = this.placeComment.bind(this)
        this.updateComment = this.updateComment.bind(this)
        this.addAsset = this.addAsset.bind(this)
        this.changeScaleModifier = this.changeScaleModifier.bind(this)
        this.getCameraPosition = this.getCameraPosition.bind(this)
        this.loadCommentAsset = this.loadCommentAsset.bind(this)
        this.addVectorToScene = this.addVectorToScene.bind(this)
        this.updateDesignAssetStates = this.updateDesignAssetStates.bind(this)
        this.loadDesign = this.loadDesign.bind(this)
        this.setEditorIsLoading = this.setEditorIsLoading.bind(this)
        this.setEditorIsWaiting = this.setEditorIsWaiting.bind(this)

        // EditorControls
        this.toggleGrid = this.toggleGrid.bind(this)
        this.toggleDraw = this.toggleDraw.bind(this)
        this.togglePlacingComments = this.togglePlacingComments.bind(this)
        this.takeScreenshot = this.takeScreenshot.bind(this)
        this.zoomEditor = this.zoomEditor.bind(this)

        // AssetControls
        this.removeAsset = this.removeAsset.bind(this)
        this.deselectAsset = this.deselectAsset.bind(this)

        /* eslint-disable react/no-unused-state */
        this.state = {
            // General
            initializeEditor: this.initializeEditor,
            cleanUpEditor: this.cleanUpEditor,
            addAsset: this.addAsset,
            placeComment: this.placeComment,
            updateComment: this.updateComment,
            design: this.design,
            changeScaleModifier: this.changeScaleModifier,
            getCameraPosition: this.getCameraPosition,
            loadCommentAsset: this.loadCommentAsset,
            updateDesignAssetStates: this.updateDesignAssetStates,
            loadDesign: this.loadDesign,
            editorIsLoading: false,
            setEditorIsLoading: this.setEditorIsLoading,
            editorIsWaiting: false,
            setEditorIsWaiting: this.setEditorIsWaiting,

            // EditorControls
            editorHasGrid: false,
            editorDrawMode: DRAW_MODE_NONE,
            isPlacingComments: false,
            editorControlMode: EDITOR_CONTROL_MODE_ORBIT,
            toggleGrid: this.toggleGrid,
            toggleDraw: this.toggleDraw,
            togglePlacingComments: this.togglePlacingComments,
            takeScreenshot: this.takeScreenshot,
            zoomEditor: this.zoomEditor,

            // AssetControls
            assetControlMode: ASSET_CONTROL_MODE_TRANSLATE,
            removeAsset: this.removeAsset,
            deselectAsset: this.deselectAsset,

            // Cache
            loadedAssets: [],
            loadedTextures: [],
            displayedAssets: [],
            selectedAsset: null,
        }
    }

    onWindowResize() {
        const width = this.renderer.domElement.parentElement.clientWidth
        const height = this.renderer.domElement.parentElement.clientHeight

        this.renderer.setSize(width, height)
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
    }

    setEditorIsLoading(editorIsLoading) {
        this.setState({ editorIsLoading })
    }

    setEditorIsWaiting(editorIsWaiting) {
        this.setState({ editorIsWaiting })
    }

    setEventListeners() {
        window.addEventListener('resize', () => this.onWindowResize())
        attachHoverListeners(this.renderer.domElement, this.findHoveredAsset)
    }

    getCameraPosition() {
        return ({
            position: {
                x: this.camera.position.x,
                y: this.camera.position.y,
                z: this.camera.position.z,
            },
            target: {
                x: this.editorControls.target.x,
                y: this.editorControls.target.y,
                z: this.editorControls.target.z,
            },
        })
    }

    setAssetControlMode(mode) {
        if (mode === ASSET_CONTROL_MODE_ROTATE) {
            this.assetControls.showX = false
            this.assetControls.showY = true
            this.assetControls.showZ = false
        }
        this.assetControls.mode = mode

        this.setState({
            assetControlMode: mode,
        })
    }

    /* eslint-disable consistent-return */
    getLoader(fileType) {
        if (fileType === ASSET_TYPE_GLTF) {
            return this.gltfLoader
        }
        if (fileType === ASSET_TYPE_COLLADA) {
            return this.colladaLoader
        }
    }

    setupDragControls(asset) {
        let assetIsBeingDragged = false
        if (this.dragControls !== null) {
            const [currentDraggedObject] = this.dragControls.getObjects()
            if (asset.renderId === currentDraggedObject.renderId) {
                assetIsBeingDragged = true
            } else {
                this.dragControls.dispose()
            }
        }

        if (this.dragControls === null || !assetIsBeingDragged) {
            this.dragControls = new DragControls([asset], this.camera, this.renderer.domElement)
            this.dragControls.transformGroup = true
            this.dragControls.addEventListener('dragstart', async (event) => {
                this.editorControls.enabled = false
                const { object } = event
                object.initialY = object.position.y
                object.isDragging = true
                await this.deselectAsset()
            })
            this.dragControls.addEventListener('drag', (event) => {
                const { object } = event
                object.position.set(object.position.x, object.initialY, object.position.z)
                this.render()
            })
            this.dragControls.addEventListener('dragend', (event) => {
                this.editorControls.enabled = true
                const { object } = event
                if (object.isDragging) {
                    object.isDragging = false
                    object.onSelect(event)
                    this.setState({ selectedAsset: object })
                }
            })
        }
    }

    zoomEditor(zoomDirection) {
        const zoomStep = zoomDirection === ZOOM_IN ? -1 * ZOOM_STEP : ZOOM_STEP
        const { position } = this.camera
        this.camera.position.set(position.x, position.y + zoomStep, position.z)
        this.camera.updateProjectionMatrix()
        this.editorControls.update()
    }

    findHoveredAsset(event) {
        const { displayedAssets } = this.state

        const intersects = calculateIntersects(event, this.renderer.domElement, this.camera, displayedAssets)
        if (intersects.length > 0) {
            const asset = getClickedAssetFromIntersects(intersects)
            if (asset !== null) {
                if (!this.isReadOnly) {
                    this.setupDragControls(asset)
                }

                if (asset.assetType === ASSET_TYPE_COMMENT) {
                    showCommentTooltip(event, this.commentTooltip, asset.commentContent)
                }
            }
        } else {
            hideCommentTooltip(this.commentTooltip)
        }
    }

    async loadAsset(asset) {
        const fileType = asset.url.split('.').pop().toUpperCase()
        let loadedAsset
        if (isImage(fileType)) {
            loadedAsset = await createImagePlane(asset.url)
        } else {
            const loader = this.getLoader(fileType)
            loadedAsset = await new Promise((resolve, reject) => loader.load(asset.url, resolve, null, reject))
        }
        loadedAsset.assetId = asset.id
        loadedAsset.isReference = asset.isReference
        loadedAsset.fileType = fileType

        return loadedAsset
    }

    async loadCommentAsset(commentAsset) {
        const loadedCommentAsset = await new Promise((resolve, reject) => this.gltfLoader.load(commentAsset.url, resolve, null, reject))
        loadedCommentAsset.assetId = commentAsset.id
        loadedCommentAsset.scaleModifier = commentAsset.scaleModifier
        this.commentAsset = loadedCommentAsset
        this.commentTooltip = document.getElementById('commentTooltip')
    }

    async loadDesign() {
        const {
            surface,
            assets,
            designAssets,
            vectors,
        } = this.design

        const {
            threeSurface,
            surfaceWidth,
            surfaceHeight,
        } = await createSurface(surface.urls.full)
        this.drawingPlane = createDrawingPlane(surfaceWidth, surfaceHeight)
        const lights = createLights(surfaceWidth, surfaceHeight)
        this.grid = new THREE.GridHelper(surfaceWidth, surfaceWidth / 10)
        this.grid.visible = false

        this.scene.add(threeSurface)
        this.scene.add(this.drawingPlane)
        lights.forEach((light) => this.scene.add(light))
        this.scene.add(this.grid)

        vectors.map(this.addVectorToScene)

        const loadedAssets = await Promise.all(assets.map((asset) => this.loadAsset(asset)))
        this.setState({ loadedAssets })

        await Promise.all(designAssets.map(async (designAsset) => {
            const { asset: { id } } = designAsset
            await this.addAsset({ ...designAsset, assetId: id }, false)
        }))
    }

    createNewAssetForScene(loadedAsset) {
        const { displayedAssets } = this.state

        let assetClone
        if (isImage(loadedAsset.fileType)) {
            assetClone = loadedAsset.clone()
        } else {
            assetClone = loadedAsset.scene.clone()
        }
        assetClone.renderId = displayedAssets.length + 1
        assetClone.assetId = loadedAsset.assetId
        assetClone.isReference = loadedAsset.isReference
        assetClone.fileType = loadedAsset.fileType
        assetClone.onSelect = (event, selectedAsset) => {
            this.assetControls.attach(assetClone)
            this.setAssetControlMode(ASSET_CONTROL_MODE_TRANSLATE)
            this.assetControls.position.set(0, 0, 0)
            this.scene.add(this.assetControls)
            if (typeof event !== 'undefined' && typeof selectedAsset !== 'undefined' && selectedAsset.assetType === ASSET_TYPE_COMMENT) {
                detachHoverListeners(this.renderer.domElement, this.findHoveredAsset)
                showCommentTooltip(event, this.commentTooltip, selectedAsset.commentContent)
            }
        }

        return assetClone
    }

    async addAsset(asset, isNewAsset) {
        const { loadedAssets, displayedAssets } = this.state
        const designScaleModifier = this.design !== null && this.design.scaleModifier !== null
            ? this.design.scaleModifier
            : 1
        const { scaleModifier: assetScaleModifier = 1 } = asset
        const scaleModifier = designScaleModifier * assetScaleModifier

        let newAsset = loadedAssets.find(whereAssetId(asset.assetId))
        const isLoadedAsset = typeof newAsset !== 'undefined'
        if (!isLoadedAsset) {
            newAsset = await this.loadAsset(asset)
        }

        const assetToAdd = this.createNewAssetForScene(newAsset)
        await this.deselectAsset()

        if (isNewAsset) {
            const position = {
                x: this.editorControls.target.x + asset.position.x,
                y: asset.position.y,
                z: this.editorControls.target.z + asset.position.z,
            }
            assetToAdd.position.copy(position)
            assetToAdd.rotation.set(asset.rotation.x, asset.rotation.y, asset.rotation.z)
            assetToAdd.scale.set(scaleModifier, scaleModifier, scaleModifier)
            assetToAdd.onSelect()
            if (this.design !== null) {
                const designAssetId = await createAssetState(assetToAdd, this.design.id, this.apolloClient, this.toast)
                assetToAdd.designAssetId = designAssetId
            }

            this.setState({ selectedAsset: assetToAdd })
        } else {
            const {
                position,
                rotation,
                scale,
                id,
            } = asset
            assetToAdd.designAssetId = id
            assetToAdd.position.copy(position)
            assetToAdd.rotation.set(rotation.x, rotation.y, rotation.z)
            assetToAdd.scale.copy(scale)
        }

        if (typeof asset.comment !== 'undefined' && asset.comment !== null) {
            assetToAdd.assetType = ASSET_TYPE_COMMENT
            assetToAdd.commentContent = asset.comment
        }

        this.scene.add(assetToAdd)

        this.setState({ displayedAssets: [...displayedAssets, assetToAdd] })

        return assetToAdd
    }

    startComment(event) {
        const commentPoint = getClickedPoint(event, this.renderer.domElement, this.camera, this.drawingPlane)

        this.onStartComment({ commentPoint })
    }

    async placeComment(commentPoint, commentContent) {
        const { displayedAssets } = this.state
        const { scaleModifier: designScaleModifier } = this.design
        const { scaleModifier: assetScaleModifier } = this.commentAsset
        const scaleModifier = designScaleModifier * assetScaleModifier

        const assetToAdd = this.createNewAssetForScene(this.commentAsset)
        assetToAdd.assetType = ASSET_TYPE_COMMENT
        assetToAdd.commentContent = commentContent
        assetToAdd.position.set(commentPoint.x, 0, commentPoint.z)
        assetToAdd.scale.set(scaleModifier, scaleModifier, scaleModifier)
        const designAssetId = await createAssetState(assetToAdd, this.design.id, this.apolloClient, this.toast)
        assetToAdd.designAssetId = designAssetId

        this.scene.add(assetToAdd)

        this.setState({
            displayedAssets: [...displayedAssets, assetToAdd],
        })

        this.togglePlacingComments()
    }

    async updateComment(commentDesignAsset, commentContent) {
        const { displayedAssets } = this.state

        /* eslint-disable no-param-reassign */
        commentDesignAsset.commentContent = commentContent
        await updateAssetState(commentDesignAsset, this.design.id, this.apolloClient, this.toast)

        this.setState({
            displayedAssets: [...displayedAssets.filter(whereIsNotRenderId(commentDesignAsset.renderId)), commentDesignAsset],
        })
    }

    async togglePlacingComments() {
        this.setEditorIsWaiting(true)
        await this.deselectAsset()

        const { isPlacingComments } = this.state
        if (isPlacingComments) {
            detachPlaceCommentListeners(this.renderer.domElement, this.startComment)
        } else {
            attachPlaceCommentListeners(this.renderer.domElement, this.startComment)
        }

        this.setState({
            isPlacingComments: !isPlacingComments,
            editorIsWaiting: false,
        })
    }

    toggleGrid() {
        const { editorHasGrid } = this.state
        this.grid.visible = !this.grid.visible
        this.setState({
            editorHasGrid: !editorHasGrid,
        })
    }

    async startDrawing(drawMode) {
        this.setEditorIsWaiting(true)
        await this.deselectAsset()
        let drawer
        const drawerProps = {
            camera: this.camera,
            scene: this.scene,
            container: this.renderer.domElement,
            drawingPlane: this.drawingPlane,
            apolloClient: this.apolloClient,
            designId: this.design.id,
            toast: this.toast,
        }
        if (drawMode === DRAW_MODE_CURVED) {
            drawer = new CurvedLineDrawer(drawerProps)
        } else {
            drawer = new StraightLineDrawer(drawerProps)
        }

        this.editorControls.enabled = false
        this.drawer = drawer
        this.drawer.setupDrawer()
        this.setEditorIsWaiting(false)
    }

    stopDrawing() {
        this.editorControls.enabled = true
        const drawedLines = this.drawer.cleanupDrawer()
        this.drawer = null

        const linesToSave = drawedLines.map((drawedLine) => {
            const lineForScene = this.createLineForScene(drawedLine)

            return lineForScene
        })

        return linesToSave
    }

    addVectorToScene(vectorState) {
        const { displayedAssets } = this.state

        const line = createLine(vectorState.points, new THREE.LineBasicMaterial({ color: 0x0000ff }))
        const lineForScene = this.createLineForScene(line)
        lineForScene.vectorId = vectorState.id
        this.scene.add(lineForScene)

        this.setState({ displayedAssets: [...displayedAssets, lineForScene] })
    }

    createLineForScene(line) {
        const { displayedAssets } = this.state

        /* eslint-disable no-param-reassign */
        line.renderId = displayedAssets.length + 1
        line.assetType = ASSET_TYPE_VECTOR
        line.onSelect = () => {
            const { center } = line.geometry.boundingSphere
            this.assetControls.attach(line)
            this.assetControls.mode = 'translate'
            this.assetControls.position.set(center.x, center.y, center.z)
            this.scene.add(this.assetControls)
        }

        return line
    }

    toggleDraw(drawMode) {
        const {
            editorDrawMode,
            displayedAssets,
        } = this.state

        let newDrawMode
        let drawedLines = []

        const wasDrawing = editorDrawMode !== DRAW_MODE_NONE
        if (wasDrawing) {
            drawedLines = this.stopDrawing()
            const stopDrawing = drawMode === DRAW_MODE_NONE || drawMode === editorDrawMode
            if (stopDrawing) {
                newDrawMode = DRAW_MODE_NONE
            } else {
                this.startDrawing(drawMode)
                newDrawMode = drawMode
            }
        } else if (drawMode !== DRAW_MODE_NONE) {
            this.startDrawing(drawMode)
            newDrawMode = drawMode
        } else {
            newDrawMode = DRAW_MODE_NONE
        }

        this.setState({
            editorDrawMode: newDrawMode,
            displayedAssets: [...displayedAssets, ...drawedLines],
        })
    }

    changeScaleModifier(inputModifier) {
        const { displayedAssets } = this.state

        /* eslint-disable no-mixed-operators */
        const previousModifier = this.design.scaleModifier
        displayedAssets.map((displayedAsset) => (
            displayedAsset.scale.set(
                displayedAsset.scale.x / previousModifier * inputModifier,
                displayedAsset.scale.y / previousModifier * inputModifier,
                displayedAsset.scale.z / previousModifier * inputModifier,
            )
        ))

        this.design.scaleModifier = inputModifier
        this.setState({ displayedAssets })
    }

    takeScreenshot() {
        this.camera.updateProjectionMatrix()
        this.renderEditor()
        const dataURL = this.renderer.domElement.toDataURL()

        return dataURL
    }

    async removeAsset(assetToRemove) {
        this.setEditorIsWaiting(true)
        const { displayedAssets } = this.state

        await this.deselectAsset(false)
        try {
            if (assetToRemove.assetType === ASSET_TYPE_VECTOR) {
                await deleteVectorState(this.apolloClient, assetToRemove.vectorId)
            } else {
                await deleteDesignAssetState(this.apolloClient, assetToRemove.designAssetId)
            }
            this.scene.remove(assetToRemove)

            this.setState({
                displayedAssets: displayedAssets.filter(whereIsNotRenderId(assetToRemove.renderId)),
                selectedAsset: null,
            })
        } catch (error) {
            this.toast({
                title: 'Er liep iets mis...',
                description: 'Dit voorwerp kon niet verwijderd worden. Herlaad de pagina en probeer het opnieuw.',
                status: 'error',
                position: 'top',
            })
        }
        this.setEditorIsWaiting(false)
    }

    async deselectAsset(doUpdate = true) {
        const { selectedAsset } = this.state

        if (selectedAsset !== null) {
            if (doUpdate && this.design !== null) {
                if (selectedAsset.assetType === ASSET_TYPE_COMMENT) {
                    hideCommentTooltip(this.commentTooltip)
                    attachHoverListeners(this.renderer.domElement, this.findHoveredAsset)
                } else if (selectedAsset.assetType === ASSET_TYPE_VECTOR) {
                    await updateVectorAssetState(selectedAsset, this.design.id, this.apolloClient, this.toast)
                } else {
                    await updateAssetState(selectedAsset, this.design.id, this.apolloClient, this.toast)
                }
            }
            this.assetControls.detach()
            this.scene.remove(this.assetControls)

            this.setState({
                selectedAsset: null,
            })
        }
    }

    async updateDesignAssetStates() {
        const { displayedAssets } = this.state
        await Promise.all(displayedAssets.map(async (displayedAsset) => {
            await updateAssetState(displayedAsset, this.design.id, this.apolloClient, this.toast)
        }))
    }

    removeEventListeners() {
        window.removeEventListener('resize', () => this.onWindowResize())
        detachHoverListeners(this.renderer.domElement, this.findHoveredAsset)
    }

    startAnimationLoop() {
        this.renderEditor()
        this.requestID = window.requestAnimationFrame(() => this.startAnimationLoop())
    }

    async initializeEditor(container, backgroundColor) {
        await this.setupScene(container, backgroundColor)
        this.startAnimationLoop()
        this.setEventListeners()
    }

    cleanUpEditor() {
        window.cancelAnimationFrame(this.requestID)
        this.scene.dispose()
        this.editorControls.dispose()
        this.assetControls.dispose()
        this.dragControls.dispose()
        this.removeEventListeners()
    }

    renderEditor() {
        this.renderer.render(this.scene, this.camera)
    }

    render() {
        const { children } = this.props
        return (
            <EditorStateProvider value={this.state}>
                {children}
            </EditorStateProvider>
        )
    }
}

export default EditorWithProvider
