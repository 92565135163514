import React, { useRef } from 'react'
import { Image, Flex } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import MainButton from 'components/util/buttons/MainButton'
import SecondaryButton from 'components/util/buttons/SecondaryButton'

const AssetEditorPreviewModal = ({
    onClose,
    dataURL,
    isUploadingPreview,
    onUploadPreview,
}) => {
    const focusRef = useRef()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Preview picture</Trans>}
            px={2}
            py={6}
            size="5xl"
            modalContent={(
                <>
                    <Image w="full" src={dataURL} />
                    <Flex
                        justify="space-between"
                        mt={8}
                    >
                        <SecondaryButton
                            disabled={isUploadingPreview}
                            onClick={onClose}
                        >
                            <Trans>Discard</Trans>
                        </SecondaryButton>
                        <MainButton
                            ref={focusRef}
                            isLoading={isUploadingPreview}
                            onClick={() => onUploadPreview(dataURL)}
                        >
                            <Trans>Save</Trans>
                        </MainButton>
                    </Flex>
                </>
            )}
        />
    )
}

export default AssetEditorPreviewModal
