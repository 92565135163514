import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory as createHistory } from 'history'
import initializeSentry from 'components/sentry/sentry'
import initializeUsersnap from 'components/usersnap/usersnap'
import * as serviceWorker from './serviceWorker'
import './index.css'
import getApolloClient from './apollo/getApolloClient'
import AppWithState from './components/app/AppWithState'

const apolloClient = getApolloClient()
const history = createHistory()

initializeSentry()
initializeUsersnap(history.location)

const Root = (
    <AppWithState
        apolloClient={apolloClient}
        history={history}
    />
)

ReactDOM.render(Root, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
