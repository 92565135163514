import React from 'react'
import { Box } from '@chakra-ui/core'
import { HOME_PAGE_SECTION_WIDTH as SECTION_WIDTH } from 'util/constants'

const SectionBox = ({ children, ...props }) => (
    <Box
        w={SECTION_WIDTH}
        mt={20}
        mx="auto"
        {...props}
    >
        {children}
    </Box>
)

export default SectionBox
