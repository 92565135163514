import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const CATEGORY_MENU_QUERY = gql`
    query categoryMenuQuery {
        categories {
            id
            name
            slug
        }
    }
`

const useFetchCategoryMenuData = () => {
    const {
        loading,
        data,
    } = useQuery(CATEGORY_MENU_QUERY)

    if (loading) {
        return ({
            isFetching: true,
            categories: [],
        })
    }

    const { categories } = data

    return ({
        isFetching: false,
        categories,
    })
}

export default useFetchCategoryMenuData
