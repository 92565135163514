import React from 'react'
import { Stack } from '@chakra-ui/core'
import Design2DControlButtons from './Design2DControlButtons'
import Design3DControlButtons from './Design3DControlButtons'

const DesignControls = ({
    is2D,
    isReadOnly,
    editorHasGrid,
    editorDrawMode,
    isPlacingComments,
    editorControlMode,
    toggleGrid,
    toggleDraw,
    togglePlacingComments,
    onTakeScreenshot,
    setEditorControlMode,
    onZoomEditor,
}) => (
    <Stack
        pos="fixed"
        top={40}
        left={0}
        bg="colorWhite"
        roundedRight="lg"
    >
        {is2D
            ? (
                <Design2DControlButtons
                    isReadOnly={isReadOnly}
                    editorHasGrid={editorHasGrid}
                    editorDrawMode={editorDrawMode}
                    isPlacingComments={isPlacingComments}
                    toggleGrid={toggleGrid}
                    toggleDraw={toggleDraw}
                    togglePlacingComments={togglePlacingComments}
                    onTakeScreenshot={onTakeScreenshot}
                    onZoomEditor={onZoomEditor}
                />
            )
            : (
                <Design3DControlButtons
                    isReadOnly={isReadOnly}
                    editorHasGrid={editorHasGrid}
                    editorDrawMode={editorDrawMode}
                    isPlacingComments={isPlacingComments}
                    editorControlMode={editorControlMode}
                    toggleGrid={toggleGrid}
                    toggleDraw={toggleDraw}
                    togglePlacingComments={togglePlacingComments}
                    onTakeScreenshot={onTakeScreenshot}
                    setEditorControlMode={setEditorControlMode}
                    onZoomEditor={onZoomEditor}
                />
            )}
    </Stack>
)

export default DesignControls
