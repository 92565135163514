import React from 'react'
import { home } from 'routes/index'
import usePushHistory from 'components/hooks/usePushHistory'
import VerifyEmailModalWithState from './VerifyEmailModalWithState'
import HomePageWithState from '../home/HomePageWithState'

const OnboardingPageWithState = ({ match }) => {
    const {
        slug,
        verificationCode,
    } = match.params
    const pushRouteWithSlug = usePushHistory()

    return (
        <>
            <HomePageWithState slug={slug} />
            <VerifyEmailModalWithState
                onClose={() => pushRouteWithSlug(home)}
                verificationCode={verificationCode}
                slug={slug}
            />
        </>
    )
}

export default OnboardingPageWithState
