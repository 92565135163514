import React, { useState } from 'react'
import { useToast } from '@chakra-ui/core'
import useQuery from 'components/hooks/useQuery'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import useAppState from 'components/hooks/useAppState'
import { useHistory } from 'react-router-dom'
import InfoToast from 'components/util/InfoToast'
import useLoginFacebookMutation from './useLoginFacebookMutation'
import HomePageWithState from '../home/HomePageWithState'
import LoggingInModal from './LoggingInModal'

const LoginFacebookPageWithState = () => {
    const toast = useToast()
    const query = useQuery()
    const { login, updateSlug } = useAppState()
    const history = useHistory()
    const [isLoggingIn, setIsLoggingIn] = useState(true)
    const code = query.get('code')
    const slug = query.get('state')
    const [loginWithFacebook, { loading: isGettingTokens }] = useLoginFacebookMutation()

    useAsyncEffect(async () => {
        try {
            setIsLoggingIn(true)
            const input = { code, trajectorySlug: slug }
            const { data } = await loginWithFacebook({ variables: { slug, input } })
            const {
                accessToken,
                refreshToken,
                me,
            } = data.facebookLoginUser
            updateSlug(slug)
            await login(accessToken, refreshToken, me)
            history.push(`/${slug}`)
            setIsLoggingIn(false)
            toast({
                position: 'top',
                render: () => (
                    <InfoToast
                        content={(
                            <>
                                Welkom terug
                                {' '}
                                {me.isAnonymous ? 'Anoniem' : me.username}
                                !
                            </>
                        )}
                    />
                ),
            })
        } catch (e) {
            toast({
                title: 'Verinding met Facebook mislukt',
                description: 'We konden geen verbinding maken met Facebook. Probeer het later opnieuw.',
                status: 'error',
                position: 'top',
            })
        }
    }, [code, slug])

    return (
        <>
            <HomePageWithState trajectorySlug={slug} />
            {(isLoggingIn || isGettingTokens) && (
                <LoggingInModal />
            )}
        </>
    )
}

export default LoginFacebookPageWithState
