import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const UPDATE_DESIGN_MUTATION = gql`
    mutation UpdateDesign($id: Int!, $input: UpdateDesignInput) {
        updateDesign(id: $id, input: $input) {
            id
            name
            description
            status
            is2D
            scaleModifier
            updatedAt
        }
    }
`

const useUpdateDesignMutation = () => useMutation(UPDATE_DESIGN_MUTATION)

export default useUpdateDesignMutation
