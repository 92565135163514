export const calculateDaysRemaining = (startDate, endDate) => {
    const today = Date.parse(new Date())
    const parsedStartDate = Date.parse(startDate)
    const parsedEndDate = Date.parse(endDate)

    const totalDuration = parsedEndDate - parsedStartDate
    const timePassed = today - parsedStartDate
    const timeRemaining = totalDuration - timePassed

    /* eslint-disable no-mixed-operators */
    const daysRemaining = Math.round(timeRemaining / 1000 / 60 / 60 / 24)
    const progress = Math.round(timePassed / totalDuration * 100)

    return ({
        daysRemaining,
        progress,
    })
}

export const getPhaseStatus = (startDate, endDate) => {
    const today = new Date()
    const parsedStartDate = new Date(startDate)
    const parsedEndDate = new Date(endDate)

    const isCurrent = parsedStartDate < today && today < parsedEndDate
    const hasEnded = parsedEndDate < today

    return ({
        isCurrent,
        hasEnded,
    })
}
