import React, { useRef } from 'react'
import {
    Spinner,
    Text,
    Flex,
    Box,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import useAppState from 'components/hooks/useAppState'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import OnboardingForm from 'components/pages/onboarding/OnboardingForm'
import useOnboardingForm from 'components/pages/onboarding/useOnboardingForm'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import useUserStatus from 'components/hooks/useUserStatus'
import MainButton from 'components/util/buttons/MainButton'
import useVerifyEmailMutation from './useVerifyEmailMutation'
import useOnboardUserMutation from './useOnboardUserMutation'

const VerifyEmailModalWithState = ({
    onClose,
    verificationCode,
    slug,
}) => {
    const focusRef = useRef()
    const formState = useOnboardingForm({ slug })
    const {
        login,
        updateCurrentUser,
    } = useAppState()
    const { isOnboardedForTrajectory } = useUserStatus()
    const [verifyEmail, { error, loading: isVerifying }] = useVerifyEmailMutation()
    const [onboardUser, { loading: isOnboardingUser }] = useOnboardUserMutation()

    useAsyncEffect(async () => {
        try {
            const input = { code: verificationCode }
            const { data } = await verifyEmail({ variables: { slug, input } })
            const {
                accessToken,
                refreshToken,
                me,
            } = data.verifyEmail
            await login(accessToken, refreshToken, me)
        } catch (e) {
            // Error handled below
        }
    }, [])

    if (typeof error !== 'undefined') {
        const { message } = error
        const messageToShow = message.split(':')[1]

        return (
            <Modal
                onClose={onClose}
                initialFocusRef={focusRef}
                modalTitle={messageToShow}
            />
        )
    }

    return (
        <Modal
            isCloseable={false}
            initialFocusRef={focusRef}
            modalTitle={!isVerifying && isOnboardedForTrajectory
                ? <Trans>Email verified!</Trans>
                : <Trans>Complete registration</Trans>}
            modalContent={(() => {
                if (isVerifying) {
                    return (
                        <Flex
                            justify="center"
                            py={12}
                        >
                            <Spinner />
                            <Text ml={2}>
                                <Trans>Verifying email...</Trans>
                            </Text>
                        </Flex>
                    )
                }
                if (isOnboardedForTrajectory) {
                    return (
                        <Box textAlign="center">
                            <MainButton onClick={onClose}>
                                <Trans>Get started</Trans>
                                !
                            </MainButton>
                        </Box>
                    )
                }
                return (
                    <OnboardingForm
                        initialFocusRef={focusRef}
                        formState={formState}
                        isOnboarding={isOnboardingUser}
                        onSubmit={async () => {
                            if (formState.validate()) {
                                try {
                                    const input = formState.values
                                    const { data } = await onboardUser({ variables: { slug, input } })
                                    updateCurrentUser(data.onboardUser)
                                    onClose()
                                } catch (e) {
                                    formState.updateErrors(parseFormError(e, 'onboardUser'))
                                }
                            }
                        }}
                    />
                )
            })()}
        />
    )
}

export default VerifyEmailModalWithState
