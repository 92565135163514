import React from 'react'
import {
    Flex,
    Button,
    Tooltip,
    Icon,
    useTheme,
} from '@chakra-ui/core'

const ButtonWithTooltip = ({
    type,
    tooltip,
    tooltipDirection = 'top',
    onClick,
    isSelected = false,
    disabled = false,
}) => {
    const theme = useTheme()
    const iconColor = isSelected === true ? 'primaryColor' : 'primaryIconColor'

    return (
        <Tooltip label={tooltip} placement={tooltipDirection}>
            <Button
                variant="unstyled"
                onClick={onClick}
                disabled={disabled}
                mx={1}
                _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
            >
                <Flex justify="center">
                    <Icon name={type} size="24px" fill="colorWhite" stroke={iconColor} />
                </Flex>
            </Button>
        </Tooltip>
    )
}

export default ButtonWithTooltip
