import React from 'react'
import { Link as ChakraLink, useTheme } from '@chakra-ui/core'

const Link = ({
    linkTo,
    href,
    children,
    ...props
}) => {
    const theme = useTheme()

    return (
        <ChakraLink
            color="primaryColor"
            href={href}
            fontWeight="medium"
            onClick={linkTo}
            _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
            {...props}
        >
            {children}
        </ChakraLink>
    )
}

export default Link
