import * as THREE from 'three'
import Editor3DWithState from './Editor3DWithProvider'
import { createLights, threeRotationDesignAssetRotationInput } from './util/three'

class AssetEditor extends Editor3DWithState {
    constructor(props) {
        super(props)

        this.asset = null
        this.referenceAsset = null

        this.addAssets = this.addAssets.bind(this)
        this.getAssetState = this.getAssetState.bind(this)

        this.state = {
            ...this.state,
            addAssets: this.addAssets,
            getAssetState: this.getAssetState,
        }
    }

    async setupScene(container, backgroundColor) {
        super.setupScene(container, backgroundColor)
        const width = container.clientWidth
        const height = container.clientHeight

        this.grid = new THREE.GridHelper(width, 20)
        this.scene.add(this.grid)
        this.axesHelper = new THREE.AxesHelper(width)
        this.scene.add(this.axesHelper)

        const lights = createLights(width, height)
        lights.forEach((light) => this.scene.add(light))
    }

    async addAssets(asset, referenceAsset) {
        const addedReferenceAsset = await this.addAsset(referenceAsset, true)
        const addedAsset = await this.addAsset(asset, true)
        const addedAssetSize = new THREE.Box3().setFromObject(addedAsset).getSize()
        addedReferenceAsset.position.x -= addedAssetSize.x

        this.asset = addedAsset
        this.referenceAsset = addedReferenceAsset
    }

    takeScreenshot() {
        this.referenceAsset.visible = false
        this.grid.visible = false
        this.axesHelper.visible = false
        this.assetControls.visible = false

        const dataURL = super.takeScreenshot()

        this.referenceAsset.visible = true
        this.grid.visible = true
        this.axesHelper.visible = true
        this.assetControls.visible = true

        return dataURL
    }

    getAssetState() {
        return ({
            position: this.asset.position,
            rotation: threeRotationDesignAssetRotationInput(this.asset.rotation),
            scaleModifier: this.asset.scale.x,
        })
    }
}

export default AssetEditor
