/* eslint-disable react/no-unused-state */
import React from 'react'
import App from 'components/App'
import * as routes from 'routes/index'
import pushRouteWithSlug from 'routes/pushRouteWithSlug'
import EnvironmentBar from 'components/layout/EnvironmentBar'
import { scopeSentryUser, removeScopedSentryUser } from '../sentry/scopeUser'
import * as storage from '../../util/storage'
import { loadAndValidateTokens } from '../../util/auth'
import { containsUnauthenticatedError } from '../../util/graphql'
import getInitialData from '../../api/getInitialData'
import getDefaultTrajectorySlug from '../../api/getDefaultTrajectorySlug'
import AppStateProvider from '../providers/AppStateProvider'
import AppProviders from './AppProviders'
import AppErrorBoundary from './AppErrorBoundary'

class AppWithState extends React.Component {
    constructor(props) {
        super(props)

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.refresh = this.refresh.bind(this)
        this.updateCurrentUser = this.updateCurrentUser.bind(this)
        this.updateSlug = this.updateSlug.bind(this)
        this.fetchInitialData = this.fetchInitialData.bind(this)

        this.state = {
            slug: null,
            currentUser: null,
            login: this.login,
            logout: this.logout,
            refresh: this.refresh,
            updateCurrentUser: this.updateCurrentUser,
            updateSlug: this.updateSlug,
            isLoadingTokens: true,
            isAuthenticated: false,
            isCheckingSlug: true,
            isLoadingInitialData: false,
            fetchInitialData: this.fetchInitialData,
        }
    }

    async componentDidMount() {
        this.setSlug()
        const { apolloClient } = this.props
        await apolloClient.resetStore()
        await this.loadTokens()
        await this.fetchDefaultTrajectory()
        await this.fetchInitialData()
        const { currentUser } = this.state
        if (currentUser !== null) {
            this.setUserContext()
        }
    }

    setSlug() {
        const { history } = this.props

        let slug = history.location.pathname.split('/')[1]
        const isAdminEditor = `/${slug}/`.includes(routes.adminEditor())
        const isLoginFacebook = `/${slug}`.includes(routes.loginFacebook())
        const isAssetViewer = `/${slug}/:id`.includes(routes.assetViewer())
        const isAssetEditor = `/${slug}/:id`.includes(routes.assetEditor())
        if (isAdminEditor || isLoginFacebook || isAssetViewer || isAssetEditor) {
            slug = null
        }

        this.setState({ slug })
    }

    setUserContext() {
        const { currentUser } = this.state
        scopeSentryUser(currentUser)
    }

    /* eslint-disable class-methods-use-this */
    removeUserContext() {
        removeScopedSentryUser()
    }

    async loadTokens() {
        const { accessToken } = await loadAndValidateTokens()
        const isAuthenticated = accessToken !== null
        this.setState({
            isLoadingTokens: false,
            isAuthenticated,
            isLoadingInitialData: isAuthenticated,
        })
    }

    updateCurrentUser(updatedCurrentUser) {
        this.setState({
            currentUser: updatedCurrentUser,
        })
    }

    updateSlug(slug) {
        this.setState({
            slug,
        })
    }

    async fetchDefaultTrajectory() {
        const { slug } = this.state
        const { apolloClient } = this.props

        let defaultSlug = slug
        if (slug === '') {
            ({ slug: defaultSlug } = await getDefaultTrajectorySlug(apolloClient))
        }

        this.setState({
            slug: defaultSlug,
            isCheckingSlug: false,
        })
    }

    async fetchInitialData() {
        const {
            apolloClient,
            history,
        } = this.props
        const {
            isAuthenticated,
            slug,
        } = this.state

        if (isAuthenticated) {
            try {
                const { me: currentUser } = await getInitialData(apolloClient, slug)
                // EDGE CASE:
                // Refresh token has expired between 'loadTokens' and 'fetchInitialData' functions.
                // Therefore no auth headers were set by 'setAuthContext'
                // and current user will be null.
                if (currentUser === null) {
                    this.setState({
                        isAuthenticated: false,
                        isLoadingInitialData: false,
                    })
                } else {
                    this.setState({
                        currentUser,
                        currentOrganisation: currentUser.organisation,
                        isLoadingInitialData: false,
                    })
                }
            } catch (error) {
                if (containsUnauthenticatedError(error)) {
                    await storage.removeAccessToken()
                    this.setState({
                        isAuthenticated: false,
                        isLoadingInitialData: false,
                    })
                }
                // TODO server error make app unavailable
            }
        }

        if (history.location.pathname.split('/')[1] === '') {
            history.push(`${routes.root()}${slug}`)
        }
    }

    async login(accessToken, refreshToken, currentUser) {
        await storage.setAccessToken(accessToken)
        await storage.setRefreshToken(refreshToken)
        const isAuthenticated = accessToken !== null
        this.setState({
            isAuthenticated,
            currentUser,
        })
        this.setUserContext()
    }

    async logout(goHome = true) {
        if (goHome) {
            const { history } = this.props
            pushRouteWithSlug(history, routes.home)
        }
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        this.setState({
            isAuthenticated: false,
            currentUser: null,
        })
        this.removeUserContext()
    }

    async refresh() {
        const { apolloClient } = this.props
        this.setState({
            isLoadingTokens: true,
            isAuthenticated: false,
            currentUser: null,
            isLoadingInitialData: false,
        })
        await this.loadTokens()
        await apolloClient.resetStore()
        await this.fetchInitialData()
        this.setUserContext()
    }

    render() {
        const {
            apolloClient,
            history,
        } = this.props

        return (
            <AppStateProvider value={this.state}>
                <AppProviders apolloClient={apolloClient}>
                    {!history.location.pathname.includes('asset-preview') && (
                        <EnvironmentBar />
                    )}
                    <AppErrorBoundary>
                        <App history={history} />
                    </AppErrorBoundary>
                </AppProviders>
            </AppStateProvider>
        )
    }
}

export default AppWithState
