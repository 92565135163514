import React from 'react'
import {
    AccordionItem,
    AccordionHeader,
    Flex,
    Stack,
    AccordionPanel,
    Heading,
    Box,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'
import PhaseSummary from 'components/util/home/PhaseSummary'
import { isEmpty } from 'validation/index'
import CollapseableDescription from 'components/util/CollapseableDescription'
import CurrentPhaseAccordionHeader from './CurrentPhaseAccordionHeader'
import DesignCardWithState from './DesignCardWithState'

const PhaseAccordionItem = ({
    scrollRef,
    phaseIndex,
    phase,
    isCurrent,
    hasEnded,
    onViewDesign,
    onLikeDesignComplete,
}) => {
    const { designs } = phase

    return (
        <AccordionItem
            ref={isCurrent ? scrollRef : null}
            defaultIsOpen={isCurrent}
            isDisabled={!(hasEnded || isCurrent)}
            borderTopWidth={phaseIndex === 0 ? '0px' : '1px'}
            borderBottom="none"
        >
            {({ isExpanded }) => (
                <>
                    <AccordionHeader py={8}>
                        <Flex w="full" justify="space-between">
                            {isCurrent
                                ? <CurrentPhaseAccordionHeader phase={phase} />
                                : <PhaseSummary phase={phase} hasEnded={hasEnded} />}
                            {(hasEnded || isCurrent) && (
                                <Stack justify="center">
                                    <Link>
                                        {isExpanded
                                            ? <Trans>Hide ideas</Trans>
                                            : <Trans>Show ideas</Trans>}
                                    </Link>
                                </Stack>
                            )}
                        </Flex>
                    </AccordionHeader>
                    <AccordionPanel>
                        <CollapseableDescription
                            description={phase.description}
                            length={500}
                            isMarkup
                            pl={20}
                            my={4}
                        />
                        {isEmpty(designs)
                            ? (
                                <Flex w="full" justify="center">
                                    <Heading
                                        py={8}
                                        fontSize="lg"
                                        color="secondaryTextColor"
                                    >
                                        <Trans>There are no ideas for this phase.</Trans>
                                    </Heading>
                                </Flex>
                            )
                            : (
                                <Flex w="full" pl={20} wrap="wrap">
                                    {designs.map((design) => (
                                        <Box
                                            key={design.id}
                                            w="21.5rem"
                                            h="md"
                                        >
                                            <DesignCardWithState
                                                key={design.id}
                                                design={design}
                                                onViewDesign={onViewDesign}
                                                onLikeDesignComplete={onLikeDesignComplete}
                                            />
                                        </Box>
                                    ))}
                                </Flex>
                            )}
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
    )
}

export default PhaseAccordionItem
