import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import {
    isValidEmail,
    isValidRegisterPassword,
    isValidConfirmPassword,
    isValidUsername,
} from 'validation/user'

const initialRegisterFormValues = (trajectorySlug) => ({
    username: '',
    email: '',
    trajectorySlug,
    password: '',
    passwordConfirmation: '',
    agreedConditions: false,
})

const validation = createFormValidation([{
    path: 'username',
    validate: (username) => isValidUsername(username),
    message: <Trans key="username">Please fill in a valid username.</Trans>,
}, {
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: <Trans key="email">Please fill in a valid email.</Trans>,
}, {
    path: 'password',
    validate: (password) => isValidRegisterPassword(password),
    message: <Trans key="password">Please fill in a password of at least 8 characters</Trans>,
}, {
    path: 'passwordConfirmation',
    validate: (passwordConfirmation, { password }) => isValidConfirmPassword(password, passwordConfirmation),
    message: <Trans key="passwordConfirmation">Please fill in two matching paswords</Trans>,
}, {
    path: 'agreedConditions',
    validate: (agreedConditions) => agreedConditions,
    message: <Trans key="agreedConditions">Please read and accept the conditions</Trans>,
}])

const useRegisterForm = (trajectorySlug, options) => (
    useFormState(
        initialRegisterFormValues(trajectorySlug),
        {
            validation,
            ...options,
        },
    )
)

export default useRegisterForm
