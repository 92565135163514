import React from 'react'
import { Trans } from '@lingui/macro'
import { Flex, Heading } from '@chakra-ui/core'
import MainButton from 'components/util/buttons/MainButton'
import EditorTopbarButton from '../editor/editor-topbar/EditorTopbarButton'

const AssetEditorTopbar = ({
    asset,
    onOpenInfoModal,
    onTakePreviewPicture,
    isUpdatingAsset,
    onSaveAndExit,
}) => (
    <Flex
        w="full"
        h={16}
        bg="colorWhite"
        justify="space-between"
        align="center"
        px={8}
        py={2}
    >
        <Heading fontSize="lg">
            {asset.name}
        </Heading>
        <Flex align="center">
            <EditorTopbarButton
                text={<Trans>Instructions</Trans>}
                icon="info"
                onClick={onOpenInfoModal}
            />
            <EditorTopbarButton
                text={<Trans>Take preview picture</Trans>}
                icon="screenshot"
                onClick={onTakePreviewPicture}
            />
            <MainButton
                loading={isUpdatingAsset}
                onClick={onSaveAndExit}
                ml={5}
            >
                <Trans>Save &amp; exit</Trans>
            </MainButton>
        </Flex>
    </Flex>
)

export default AssetEditorTopbar
