import React, { useState } from 'react'
import {
    Button,
    Flex,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useTheme,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import ButtonWithTooltip from 'components/util/buttons/ButtonWithTooltip'
import {
    DRAW_MODE_NONE,
    DRAW_MODE_CURVED,
    DRAW_MODE_STRAIGHT,
} from 'editor/util/constants'

const EditorControlsDrawMenu = ({
    editorDrawMode,
    onDraw,
    disabled,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const theme = useTheme()

    if (disabled) {
        return (
            <Button
                variant="unstyled"
                mx={1}
                disabled
                _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
            >
                <Flex justify="center">
                    <Icon
                        name="edit"
                        size="24px"
                        fill="colorWhite"
                        stroke="primaryIconColor"
                    />
                </Flex>
            </Button>
        )
    }

    return (
        <Popover
            trigger="hover"
            placement="right"
            isOpen={isOpen}
        >
            <PopoverTrigger>
                <Button
                    variant="unstyled"
                    mx={1}
                    onClick={() => {
                        onDraw(DRAW_MODE_NONE)
                        setIsOpen(!isOpen)
                    }}
                    _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
                >
                    <Flex justify="center">
                        <Icon
                            name="edit"
                            size="24px"
                            fill="colorWhite"
                            stroke={editorDrawMode === DRAW_MODE_NONE
                                ? 'primaryIconColor'
                                : 'primaryColor'}
                        />
                    </Flex>
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <ButtonWithTooltip
                    type="drawCurved"
                    tooltip={<Trans>Draw curved lines</Trans>}
                    tooltipDirection="right"
                    onClick={() => onDraw(DRAW_MODE_CURVED)}
                    isSelected={editorDrawMode === DRAW_MODE_CURVED}
                />
                <ButtonWithTooltip
                    type="drawStraight"
                    tooltip={<Trans>Draw straight lines</Trans>}
                    tooltipDirection="right"
                    onClick={() => onDraw(DRAW_MODE_STRAIGHT)}
                    isSelected={editorDrawMode === DRAW_MODE_STRAIGHT}
                />
            </PopoverContent>
        </Popover>
    )
}

export default EditorControlsDrawMenu
