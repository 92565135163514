import React from 'react'
import {
    Box,
    Button,
    Icon,
    useTheme,
} from '@chakra-ui/core'
import { motion } from 'framer-motion'

const variants = {
    open: { x: 0 },
    closed: { x: '100%' },
}

const CategoryMenuContainer = ({
    isCollapsed,
    onToggleCollapse,
    children,
}) => {
    const theme = useTheme()

    return (
        <Box
            w="xs"
            pos="fixed"
            top={40}
            right={0}
        >
            <motion.nav
                initial={false}
                animate={isCollapsed ? 'closed' : 'open'}
            >
                <Box
                    bg="colorWhite"
                    p={5}
                    right={0}
                    roundedLeft="lg"
                    pos="absolute"
                    zIndex={25}
                >
                    <Button
                        size="xs"
                        variant="unstyled"
                        onClick={onToggleCollapse}
                        _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
                    >
                        <Icon
                            name={isCollapsed ? 'collapseLeft' : 'collapseRight'}
                            size="24px"
                            stroke="primaryIconColor"
                            fill="colorWhite"
                        />
                    </Button>
                </Box>
                <motion.div variants={variants}>
                    <Box
                        bg="colorWhite"
                        p={5}
                        roundedLeft="lg"
                    >
                        {children}
                    </Box>
                </motion.div>
            </motion.nav>
        </Box>
    )
}

export default CategoryMenuContainer
