import { gql } from 'apollo-boost'

const DEFAULT_COORDINATE_FRAGMENT = gql`
    fragment defaultCoordinate on Coordinate {
        x
        y
        z
    }
`

export default DEFAULT_COORDINATE_FRAGMENT
