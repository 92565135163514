import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import { isValidPostalCode, isValidAge } from 'validation/user'

const initialOnboardingFormValues = (trajectorySlug, user) => ({
    trajectorySlug,
    postalCode: user.postalCode || '',
    sex: user.sex || 'OTHER',
    age: user.age || '',
    subscribeToUpdates: false,
})

const validation = createFormValidation([{
    path: 'postalCode',
    validate: (postalCode) => isValidPostalCode(postalCode),
    message: <Trans key="postalCode">Please fill in a postal code.</Trans>,
}, {
    path: 'age',
    validate: (age) => isValidAge(age),
    message: <Trans key="age">The minimum age to use this application is 3 years.</Trans>,
}])

const useOnboardingForm = ({ slug, currentUser = {} }, options) => (
    useFormState(
        initialOnboardingFormValues(slug, currentUser),
        {
            validation,
            ...options,
        },
    )
)

export default useOnboardingForm
