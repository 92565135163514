import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import DEFAULT_COORDINATE_FRAGMENT from 'apollo/fragments/defaultCoordinate'

export const ASSET_VIEWER_QUERY = gql`
    query assetViewerQuery($id: Int) {
        asset(id: $id) {
            id
            url
            scaleModifier
            position {
                ...defaultCoordinate
            }
            rotation {
                ...defaultCoordinate
            }
        }
    }
    ${DEFAULT_COORDINATE_FRAGMENT}
`

const useFetchAssetViewerPageData = (id) => {
    const variables = { id }
    const {
        loading,
        data,
    } = useQuery(ASSET_VIEWER_QUERY, { variables })

    if (loading) {
        return ({
            isFetching: true,
            asset: {},
        })
    }

    const { asset } = data

    return ({
        isFetching: false,
        asset,
    })
}

export default useFetchAssetViewerPageData
