import React from 'react'
import { Divider } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'
import FormInput from 'components/util/forms/FormInput'
import Link from 'components/util/Link'
import ErrorField from 'components/util/forms/ErrorField'
import Toggle from 'components/util/forms/Toggle'
import { withI18n } from '@lingui/react'

const RegisterForm = withI18n()(({
    i18n,
    focusRef,
    formState,
    isRegistering,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <>
            <FormInput
                {...getInputProps('username')}
                error={getErrorMessages('username')}
                id="username"
                label={<Trans>Username</Trans>}
                placeholder={i18n._('Username')}
                focusRef={focusRef}
            />
            <FormInput
                {...getInputProps('email')}
                error={getErrorMessages('email')}
                id="email"
                label={<Trans>Email</Trans>}
                type="email"
                placeholder={i18n._('Email')}
            />
            <FormInput
                {...getInputProps('password')}
                error={getErrorMessages('password')}
                id="password"
                label={<Trans>Password</Trans>}
                type="password"
                placeholder={i18n._('Password')}
            />
            <FormInput
                {...getInputProps('passwordConfirmation')}
                error={getErrorMessages('passwordConfirmation')}
                id="passwordConfirmation"
                label={<Trans>Confirm password</Trans>}
                type="password"
                placeholder={i18n._('Confirm password')}
            />
            <Toggle
                {...getInputProps('agreedConditions')}
                error={getErrorMessages('agreedConditions')}
                id="agreedConditions"
                label={(
                    <>
                        <Trans>Do you agree with the</Trans>
                        {' '}
                        <Link>
                            <Trans>general terms and conditions</Trans>
                        </Link>
                        ?
                    </>
                )}
                options={[{
                    value: true,
                    label: <Trans>Yes</Trans>,
                }, {
                    value: false,
                    label: <Trans>No</Trans>,
                }]}
                mt={4}
            />
            <ErrorField
                mt={2}
                textAlign="center"
                errorMessage={getErrorMessages('register')}
            />
            <Divider w="full" />
            <MainButton
                loading={isRegistering}
                onClick={onSubmit}
                mt={8}
                w="full"
            >
                <Trans>Register</Trans>
            </MainButton>
        </>
    )
})

export default RegisterForm
