import React, { useRef } from 'react'
import { useToast } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import useAppState from 'components/hooks/useAppState'
import useUpdateUserForm from './useUpdateUserForm'
import useUpdateUserMutation from './useUpdateUserMutation'
import UpdateUserForm from './UpdateUserForm'

const UpdateUserModalWithState = ({
    onClose,
    onUpdatePassword,
    trajectorySlug,
    user,
}) => {
    const toast = useToast()
    const focusRef = useRef()
    const { updateCurrentUser } = useAppState()
    const formState = useUpdateUserForm(user)
    const [updateUser, { loading: isUpdatingUser }] = useUpdateUserMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>My profile</Trans>}
            modalContent={(
                <UpdateUserForm
                    focusRef={focusRef}
                    formState={formState}
                    onUpdatePassword={onUpdatePassword}
                    isUpdatingUser={isUpdatingUser}
                    onSubmit={async () => {
                        if (formState.validate()) {
                            try {
                                const input = formState.values
                                const { data } = await updateUser({ variables: { slug: trajectorySlug, input } })
                                updateCurrentUser(data.updateUser)
                                onClose()
                                toast({
                                    title: 'Gelukt!',
                                    description: 'Je info werd geüdate.',
                                    status: 'success',
                                    position: 'top',
                                })
                            } catch (e) {
                                formState.updateErrors(parseFormError(e, 'updateUser'))
                            }
                        }
                    }}
                />
            )}
        />
    )
}

export default UpdateUserModalWithState
