import React, { useRef } from 'react'
import { Box } from '@chakra-ui/core'
import useAsyncEffect from 'components/hooks/useAsyncEffect'

const AssetViewerPage = ({
    initializePreview,
    cleanupPreview,
}) => {
    const container = useRef()

    useAsyncEffect(async () => {
        await initializePreview(container.current)

        return cleanupPreview
    }, [])

    return (
        <Box
            ref={container}
            width="100%"
            height="100%"
        />
    )
}

export default AssetViewerPage
