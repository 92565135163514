const UNAUTHENTICATED = 'UNAUTHENTICATED'

export const isUnauthenticatedError = (error) => (
    error.extensions.code === UNAUTHENTICATED
)

export const containsUnauthenticatedError = ({ networkError }) => (
    typeof networkError !== 'undefined'
    && typeof networkError.response !== 'undefined'
    && typeof networkError.response.status !== 'undefined'
    && networkError.response.status === 400
    && !!networkError.result.errors.find((error) => isUnauthenticatedError(error))
)

export const connectionToCollection = (connection = { edges: [] }) => (
    connection.edges.map((edge) => edge.node)
)
