import React, { useRef, useState } from 'react'
import { useTheme } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import useEditorState from 'components/hooks/useEditorState'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import { isEmpty } from 'validation/index'
import ModalLoading from 'components/util/ModalLoading'
import { downloadImage } from 'util/entities'
import useUploadScreenshotMutation from '../useUploadScreenshotMutation'
import EditorShareModalContent from './EditorShareModalContent'
import useFetchEditorShareModalData from './useFetchEditorShareModalData'
import useUpdateDesignMutation from '../editor-edit-design/useUpdateDesignMutation'

const EditorShareModalWithState = ({
    onClose,
    isReadOnly,
    onChangeSharedImage,
    designId,
    slug,
}) => {
    const focusRef = useRef()
    const theme = useTheme()
    const { takeScreenshot } = useEditorState()
    const [uploadScreenshot, { loading: isUploadingScreenshot }] = useUploadScreenshotMutation()
    const [updateDesign, { loading: isUpdatingDesign }] = useUpdateDesignMutation()
    const {
        isFetching,
        design,
    } = useFetchEditorShareModalData(designId)
    const [shareImage, setShareImage] = useState(design.featuredScreenshot)

    useAsyncEffect(async () => {
        if (design.featuredScreenshot === null) {
            if (isEmpty(design.screenshots)) {
                const dataURL = takeScreenshot()
                const input = { design: design.id, screenshot: dataURL }
                const { data } = await uploadScreenshot({ variables: { input } })
                const featuredScreenshot = data.uploadScreenshotForDesign

                const designInput = { featuredScreenshot: featuredScreenshot.id }
                await updateDesign({ variables: { id: design.id, input: designInput } })

                featuredScreenshot.urls = { thumb: dataURL }
                setShareImage(featuredScreenshot)
            } else {
                setShareImage(design.screenshots[0])
            }
        } else {
            setShareImage(design.featuredScreenshot)
        }
    }, [design])

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Share design</Trans>}
            px={2}
            py={6}
            modalContent={isFetching
                ? <ModalLoading />
                : (
                    <EditorShareModalContent
                        focusRef={focusRef}
                        isReadOnly={isReadOnly}
                        isUploadingScreenshot={isUploadingScreenshot || isUpdatingDesign}
                        shareImage={shareImage}
                        onChangeSharedImage={() => onChangeSharedImage(async (image) => {
                            setShareImage(image)
                            const designInput = { featuredScreenshot: shareImage.id }
                            await updateDesign({ variables: { id: design.id, input: designInput } })
                        })}
                        onShare={() => {
                            const { REACT_APP_BACKEND_URL } = process.env
                            window.open(
                                `https://www.facebook.com/sharer/sharer.php?u=${REACT_APP_BACKEND_URL}/share/trajectory/${slug}/design/${designId}`,
                                'targetWindow',
                                `
                                    toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=${theme.sizes['4xl']},
                                    height=${theme.sizes['4xl']}
                                `,
                            )
                        }}
                        onDownloadImage={async () => {
                            await downloadImage(shareImage.urls.medium, `ontwerp${designId}-screenshot${shareImage.id}.jpg`)
                        }}
                    />
                )}
        />
    )
}

export default EditorShareModalWithState
