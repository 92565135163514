import { getClickedPoint, createLine } from './util/three'
import Drawer from './Drawer'
import { createVectorAssetState } from './util/api'

export default class StraightLineDrawer extends Drawer {
    constructor(props) {
        super(props)

        this.startDraw = this.startDraw.bind(this)
        this.stopDraw = this.stopDraw.bind(this)
        this.doDraw = this.doDraw.bind(this)
        this.constructLine = this.constructLine.bind(this)
    }

    startDraw(event) {
        this.firstPoint = getClickedPoint(event, this.container, this.camera, this.drawingPlane)
    }

    doDraw(event) {
        const point = getClickedPoint(event, this.container, this.camera, this.drawingPlane)
        if (point !== null) {
            this.lastPoint = point
        }
    }

    async stopDraw() {
        await this.constructLine()
    }

    async constructLine() {
        const vertices = [
            this.firstPoint.x, this.firstPoint.y, this.firstPoint.z,
            this.lastPoint.x, this.lastPoint.y, this.lastPoint.z,
        ]

        const fullLine = createLine(vertices, this.lineMaterial)
        const vectorId = await createVectorAssetState(fullLine, this.designId, this.apolloClient)
        fullLine.vectorId = vectorId
        this.scene.add(fullLine)
        this.lines.push(fullLine)
        this.firstPoint = null
        this.lastPoint = null
    }
}
