import React from 'react'
import { Flex, Textarea } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import SecondaryButton from 'components/util/buttons/SecondaryButton'
import MainButton from 'components/util/buttons/MainButton'

export const INITIAL_COMMENT_CONTENT = ''

const CommentForm = ({
    comment,
    onChangeComment,
    focusRef,
    onSubmit,
    onCancel,
}) => (
    <>
        <Textarea
            ref={focusRef}
            resize="none"
            value={comment}
            onChange={(e) => onChangeComment(e.target.value)}
        />
        <Flex
            justify="space-between"
            mt={8}
        >
            <SecondaryButton
                onClick={onCancel}
            >
                <Trans>Cancel</Trans>
            </SecondaryButton>
            <MainButton
                disabled={comment === INITIAL_COMMENT_CONTENT}
                onClick={onSubmit}
            >
                <Trans>Save</Trans>
            </MainButton>
        </Flex>
    </>
)

export default CommentForm
