import { gql } from 'apollo-boost'

const DEFAULT_MEDIA_ITEM_FRAGMENT = gql`
    fragment defaultMediaItem on MediaItem {
        id
        urls
    }
`

export default DEFAULT_MEDIA_ITEM_FRAGMENT
