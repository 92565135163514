import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const UPLOAD_SCREENSHOT_MUTATION = gql`
    mutation uploadScreenshot($input: UploadScreenshotInput) {
        uploadScreenshotForDesign(input: $input) {
            id
            urls
        }
    }
`

const useUploadScreenshotMutation = () => useMutation(UPLOAD_SCREENSHOT_MUTATION)

export default useUploadScreenshotMutation
