import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const UPDATE_USER_MUTATION = gql`
    mutation updateUser($slug: String!, $input: UpdateUserInput) {
        updateUser(input: $input) {
            id
            username
            email
            age
            sex
            isAnonymous
            isOnboardedForTrajectory(slug: $slug)
        }
    }
`

const useUpdateUserMutation = () => useMutation(UPDATE_USER_MUTATION)

export default useUpdateUserMutation
