import * as validation from 'validation/index'

export const isValidUsername = (username) => (
    validation.isValue(username)
)

export const isValidEmail = (email) => (
    validation.isValue(email)
    && validation.isEmail(email)
)

export const isValidPassword = (password) => (
    validation.isValue(password)
)

export const isPasswordLongEnough = (password) => (
    validation.isLongerOrEqualThan(8)(password)
)

export const isValidConfirmPassword = (password, confirmPassword) => (
    validation.isEqual(password, confirmPassword)
)

export const isValidLoginPassword = (password) => (
    isValidPassword(password)
)

export const isValidRegisterPassword = (password) => (
    isValidPassword(password)
    && isPasswordLongEnough(password)
)

export const isValidPostalCode = (postalCode) => (
    validation.isValue(postalCode)
    && validation.hasLengthOf(4)(postalCode.toString())
)

export const isValidAge = (age) => (
    validation.isNumber(age)
    && validation.isGreaterOrEqualThan(3)(age)
)
