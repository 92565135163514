import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const PROFILE_PAGE_QUERY = gql`
    query profilePageQuery {
        me {
            id
            username
            email
            age
            sex
            postalCode
            designs {
                id
                name
                description
                status
                surface {
                    id
                    urls
                }
                featuredScreenshot {
                    id
                    urls
                }
            }
        }
    }
`

const useFetchProfilePageData = () => {
    const {
        error,
        loading,
        data,
    } = useQuery(PROFILE_PAGE_QUERY, { fetchPolicy: 'cache-and-network' })

    if (loading) {
        return ({
            isFetching: true,
            me: {},
            designs: [],
        })
    }

    const { me } = data
    const { designs } = me

    return ({
        error,
        isFetching: false,
        me,
        designs,
    })
}

export default useFetchProfilePageData
