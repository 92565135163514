import React from 'react'
import {
    Flex,
    Heading,
    Box,
    Text,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import PhaseOrderTag from 'components/util/home/PhaseOrderTag'
import { calculateDaysRemaining } from 'util/phase'

const CurrentPhaseAccordionHeader = ({ phase }) => {
    const { startDate, endDate } = phase
    const { daysRemaining } = calculateDaysRemaining(startDate, endDate)

    return (
        <Flex>
            <PhaseOrderTag phaseOrder={phase.order} isCurrent />
            <Box mt={2} ml={4} textAlign="left">
                <Heading as="h3" fontSize="lg">{phase.title}</Heading>
                <Text mt={1} color="primaryColor">
                    {`${daysRemaining} `}
                    <Trans>days left</Trans>
                </Text>
            </Box>
        </Flex>
    )
}

export default CurrentPhaseAccordionHeader
