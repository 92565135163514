import React from 'react'
import {
    Flex,
    Box,
    Text,
    Heading,
    Stack,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'

const ProfilePageHeader = ({
    user,
    onUpdateUser,
}) => (
    <Box mt={16}>
        <Text color="secondaryTextColor">
            <Trans>My profile</Trans>
        </Text>
        <Flex
            w="full"
            justify="space-between"
        >
            <Heading
                as="h2"
                fontSize="xl"
            >
                {user.username}
            </Heading>
            <Stack alignItems="center">
                <MainButton
                    px={10}
                    py={3}
                    onClick={onUpdateUser}
                >
                    <Trans>Edit my profile</Trans>
                </MainButton>
            </Stack>
        </Flex>
    </Box>
)

export default ProfilePageHeader
