import React from 'react'
import { Trans } from '@lingui/macro'
import { motion, AnimatePresence } from 'framer-motion'
import { Flex, Text } from '@chakra-ui/core'
import { DRAW_MODE_STRAIGHT, DRAW_MODE_CURVED } from 'editor/util/constants'

const EditorInstructions = ({
    editorDrawMode,
    editorIsWaiting,
    isPlacingComments,
}) => {
    let instruction = null
    if (isPlacingComments) {
        instruction = <Trans>Click where you want to place a comment.</Trans>
    }
    if (editorDrawMode === DRAW_MODE_STRAIGHT) {
        instruction = <Trans>Click where you want the line to start. Release where you want it to end.</Trans>
    }
    if (editorDrawMode === DRAW_MODE_CURVED) {
        instruction = <Trans>Draw a line with your mouse.</Trans>
    }
    if (editorIsWaiting) {
        instruction = <Trans>Working...</Trans>
    }

    return (
        <AnimatePresence>
            {instruction !== null && (
                <Flex
                    pos="fixed"
                    justify="center"
                    w="full"
                    mt={32}
                >
                    <motion.div
                        positionTransition
                        initial={{ opacity: 0, scale: 0.3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                    >
                        <Text
                            bg="colorWhite"
                            px={4}
                            py={1}
                            rounded="lg"
                        >
                            {instruction}
                        </Text>
                    </motion.div>
                </Flex>
            )}
        </AnimatePresence>
    )
}

export default EditorInstructions
