/* eslint-disable import/prefer-default-export */

export const getDesignCardImageUrlFromDesign = (design) => {
    const { featuredScreenshot, surface } = design

    if (featuredScreenshot === null) {
        const { urls } = surface
        if (urls.medium === null) {
            return urls.full
        }

        return urls.medium
    }

    const { urls } = featuredScreenshot
    if (urls.medium === null) {
        return urls.full
    }

    return urls.medium
}
