import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import { isValidRegisterPassword, isValidConfirmPassword } from 'validation/user'
import { isValue } from 'validation/index'

const initialUpdatePasswordFormValues = () => ({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
})

const validation = createFormValidation([{
    path: 'oldPassword',
    validate: (oldPassword) => isValue(oldPassword),
    message: <Trans key="password">Please fill in your current password</Trans>,
}, {
    path: 'password',
    validate: (password) => isValidRegisterPassword(password),
    message: <Trans key="password">Please fill in a password of at least 8 characters</Trans>,
}, {
    path: 'passwordConfirmation',
    validate: (passwordConfirmation, { password }) => isValidConfirmPassword(password, passwordConfirmation),
    message: <Trans key="passwordConfirmation">Please fill in two matching paswords</Trans>,
}])

const useUpdatePasswordForm = (options) => (
    useFormState(
        initialUpdatePasswordFormValues(),
        {
            validation,
            ...options,
        },
    )
)

export default useUpdatePasswordForm
