import React from 'react'
import {
    Flex,
    Text,
    Icon,
    PseudoBox,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { profile } from 'routes/index'
import useAppState from 'components/hooks/useAppState'
import usePushHistory from 'components/hooks/usePushHistory'
import useUserStatus from 'components/hooks/useUserStatus'
import UserIcon from './UserIcon'

const UserMenu = ({
    isFocussed = false,
    onStart,
    ...props
}) => {
    const { logout } = useAppState()
    const {
        currentUser,
        isAnonymous,
        isOnboardedForTrajectory,
    } = useUserStatus()
    const pushRouteWithSlug = usePushHistory()

    if (currentUser === null) {
        return null
    }

    const UserMenuToggle = () => (
        <PseudoBox
            bg="colorWhite"
            px={4}
            py={2}
            rounded="lg"
            {...props}
        >
            <Flex alignItems="center">
                <UserIcon />
                <Text
                    fontSize="md"
                    mx={4}
                    color={isFocussed ? 'primaryTextColor' : 'secondaryTextColor'}
                    fontWeight="bold"
                >
                    {isAnonymous
                        ? <Trans>Anonymous</Trans>
                        : currentUser.username}
                </Text>
                <Icon
                    name="arrowDown"
                    size="32px"
                    stroke="primaryColor"
                    fill="none"
                />
            </Flex>
        </PseudoBox>
    )

    if (isOnboardedForTrajectory) {
        return (
            <Menu>
                <MenuButton>
                    <UserMenuToggle />
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={() => pushRouteWithSlug(profile)}>
                        <Trans>My profile</Trans>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                        <Trans>Logout</Trans>
                    </MenuItem>
                </MenuList>
            </Menu>
        )
    }

    return (
        <Button
            variant="unstyled"
            onClick={onStart}
        >
            <UserMenuToggle />
        </Button>
    )
}

export default UserMenu
