import React from 'react'
import { Box } from '@chakra-ui/core'

const InfoToast = ({ content }) => (
    <Box
        mt={8}
        px={12}
        py={2}
        bg="primaryColor"
        color="colorWhite"
        rounded="lg"
        fontWeight="bold"
    >
        {content}
    </Box>

)

export default InfoToast
