import React, { useRef } from 'react'
import { Box, Text } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import useAppState from 'components/hooks/useAppState'
import { parseFormError } from 'util/errors'
import useLoginForm from 'components/pages/auth/login/useLoginForm'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import { useHistory } from 'react-router-dom'
import ModalLoading from 'components/util/ModalLoading'
import useAdminEditorLoginMutation from './useAdminEditorLoginMutation'
import AdminEditorLoginForm from './AdminEditorLoginForm'
import useFetchAdminEditorLoginData from './useFetchAdminLoginData'

const AdminEditorLoginModalWithState = ({
    onClose,
    isAuthenticated,
    designId = null,
}) => {
    const focusRef = useRef()
    const history = useHistory()
    const formState = useLoginForm()
    const { login, refresh, updateSlug } = useAppState()
    const [loginWithEmail, { loading: isLoggingIn }] = useAdminEditorLoginMutation(formState.values)
    const [fetchLoginData, { data, loading: isFetching }] = useFetchAdminEditorLoginData()

    useAsyncEffect(async () => {
        if (isAuthenticated) {
            setTimeout(() => {
                if (designId === null) {
                    history.push('/')
                } else {
                    fetchLoginData({ variables: { id: designId } })
                    if (!isFetching && typeof data !== 'undefined') {
                        const { design: { phase: { trajectory: { slug } } } } = data
                        updateSlug(slug)
                        history.push(`/${slug}`)
                        refresh()
                    }
                }
            }, 2000)
        }
    }, [isAuthenticated, designId, data])

    return (
        <Modal
            isCloseable={false}
            initialFocusRef={focusRef}
            modalTitle={isAuthenticated
                ? <Trans>Unauthorized</Trans>
                : <Trans>Login</Trans>}
            modalContent={isAuthenticated
                ? (
                    <Box color="secondaryTextColor">
                        <Text>
                            <Trans>You don&apos;t have access to this application.</Trans>
                        </Text>
                        <Text my={4}>
                            <Trans>You will be redirected to our homepage shortly...</Trans>
                        </Text>
                        <ModalLoading />
                    </Box>
                )
                : (
                    <AdminEditorLoginForm
                        focusRef={focusRef}
                        formState={formState}
                        isLoggingIn={isLoggingIn}
                        onSubmit={async () => {
                            if (formState.validate()) {
                                try {
                                    const input = formState.values
                                    const { data: loginData } = await loginWithEmail({ variables: { input } })
                                    const {
                                        accessToken,
                                        refreshToken,
                                        me,
                                    } = loginData.login
                                    await login(accessToken, refreshToken, me)
                                    onClose()
                                } catch (error) {
                                    formState.updateErrors(parseFormError(error, 'login'))
                                }
                            }
                        }}
                    />
                )}
        />
    )
}

export default AdminEditorLoginModalWithState
