import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const CREATE_DESIGN_FROM_TEMPLATE_MUTATION = gql`
    mutation createDesignFromTemplate($input: CreateDesignFromTemplateInput) {
        createDesignFromTemplate(input: $input) {
            id
        }
    }
`

const useCreateDesignFromTemplateMutation = () => useMutation(CREATE_DESIGN_FROM_TEMPLATE_MUTATION)

export default useCreateDesignFromTemplateMutation
