import { gql } from 'apollo-boost'

const DELETE_VECTOR_MUTATION = gql`
    mutation deleteVector($id: Int!) {
        deleteVectorState(id: $id) {
            id
        }
    }
`

export default async function deleteVectorState(apolloClient, id, options) {
    const mutation = DELETE_VECTOR_MUTATION
    const variables = { id }
    await apolloClient.mutate({ mutation, variables, ...options })
}
