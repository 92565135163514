import React from 'react'
import { Box, Heading } from '@chakra-ui/core'
import DateTimeTag from 'components/util/DateTimeTag'
import CollapseableDescription from '../CollapseableDescription'

const NewsItemSummary = ({ newsItem }) => (
    <Box
        mt={12}
        textAlign="left"
    >
        <DateTimeTag dateString={newsItem.publishedAt} />
        <Heading
            as="h3"
            fontSize="lg"
            mt={1}
            mb={4}
        >
            {newsItem.title}
        </Heading>
        <CollapseableDescription description={newsItem.article} isMarkup />
    </Box>
)

export default NewsItemSummary
