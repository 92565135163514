import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import theme from 'styles/theme'
import LanguageProvider from '../providers/LanguageProvider'

const AppProviders = ({
    apolloClient,
    children,
}) => (
    <ThemeProvider theme={theme}>
        <CSSReset />
        <ApolloProvider client={apolloClient}>
            <LanguageProvider languageCode={LanguageProvider.NL}>
                {children}
            </LanguageProvider>
        </ApolloProvider>
    </ThemeProvider>
)

export default AppProviders
