import React from 'react'
import { Stack, Image, Spinner } from '@chakra-ui/core'

const PageLoading = () => (
    <Stack
        w="full"
        h="full"
        alignItems="center"
        justify="center"
    >
        <Image src="logos/logo.png" />
        <Spinner
            color="primaryColor"
            size="xl"
            thickness="4px"
            mt={8}
        />
    </Stack>
)

export default PageLoading
