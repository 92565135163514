import React from 'react'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'

const GetStartedButton = ({
    onParticipate,
    isAuthenticated,
    isOnboardedForTrajectory,
    ...props
}) => (
    <MainButton
        onClick={onParticipate}
        {...props}
    >
        {isAuthenticated && isOnboardedForTrajectory
            ? <Trans>Get started</Trans>
            : <Trans>Partake in this trajectory</Trans>}
    </MainButton>
)

export default GetStartedButton
