import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const LOGIN_FACEBOOK_MUTATION = gql`
    mutation loginFacebook($slug: String!, $input: FacebookLoginInput!) {
        facebookLoginUser(input: $input) {
            accessToken
            refreshToken
            me {
                id
                username
                email
                age
                sex
                isAnonymous
                isOnboardedForTrajectory(slug: $slug)
            }
        }
    }
`

const useLoginFacebookMutation = () => useMutation(LOGIN_FACEBOOK_MUTATION)

export default useLoginFacebookMutation
