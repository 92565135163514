import React from 'react'
import { Trans } from '@lingui/macro'
import EditorTopbarButton from './EditorTopbarButton'

const EditorReadOnlyTopbarButtons = ({
    onOpenShareModal,
    onOpenEditorDesignInfoModal,
}) => (
    <>
        <EditorTopbarButton
            text={<Trans>Info</Trans>}
            icon="info"
            onClick={onOpenEditorDesignInfoModal}
        />
        <EditorTopbarButton
            text={<Trans>Share</Trans>}
            icon="share"
            onClick={onOpenShareModal}
        />
    </>
)

export default EditorReadOnlyTopbarButtons
