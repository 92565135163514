import React from 'react'
import { Box, Button } from '@chakra-ui/core'

const MainButton = ({
    loading,
    onClick,
    children,
    focusRef,
    ...props
}) => (
    <Box>
        <Button
            h="full"
            ref={focusRef}
            onClick={onClick}
            isLoading={loading}
            fontWeight="bold"
            rounded="lg"
            bg="primaryColor"
            color="colorWhite"
            px={6}
            py={3}
            _hover="none"
            _focus="none"
            {...props}
        >
            {children}
        </Button>
    </Box>
)

export default MainButton
