import React from 'react'
import {
    Flex,
    Text,
    Image,
    PseudoBox,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { isEmpty } from 'validation/index'

const EditorShotsModalContent = ({
    screenshots,
    onClickShot,
}) => {
    if (isEmpty(screenshots)) {
        return (
            <Text color="secondaryTextColor">
                <Trans>You haven&apos;t taken any screenshots yet</Trans>
            </Text>
        )
    }

    return (
        <Flex flexWrap="wrap" justify="space-between">
            {screenshots.map((shot) => (
                <PseudoBox
                    key={shot.id}
                    w="45%"
                    mt={6}
                    onClick={() => onClickShot(shot)}
                    _hover={{
                        cursor: 'pointer',
                    }}
                >
                    <Image
                        src={shot.urls.thumb}
                    />
                </PseudoBox>
            ))}
        </Flex>
    )
}

export default EditorShotsModalContent
