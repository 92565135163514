import React from 'react'
import usePushHistory from 'components/hooks/usePushHistory'
import { designs } from 'routes/index'
import { DESIGN_STATUS_PUBLISHED } from 'util/constants'
import EditorTopbar from './EditorTopbar'

const EditorTopbarWithState = ({
    design,
    isReadonly,
    onOpenEditModal,
    onOpenShotsModal,
    onOpenShareModal,
    onOpenEditorDesignInfoModal,
}) => {
    const pushRouteWithSlug = usePushHistory()
    const {
        trajectory,
        updatedAt,
        status,
    } = design
    const isDesignPublished = status === DESIGN_STATUS_PUBLISHED

    return (
        <EditorTopbar
            isReadonly={isReadonly}
            trajectory={trajectory}
            designLastUpdate={updatedAt}
            isDesignPublished={isDesignPublished}
            onOpenEditModal={() => onOpenEditModal({ designShouldBePublished: false })}
            onOpenShotsModal={onOpenShotsModal}
            onOpenShareModal={onOpenShareModal}
            onOpenEditorDesignInfoModal={onOpenEditorDesignInfoModal}
            onCloseEditor={() => pushRouteWithSlug(designs)}
            onPublishDesign={() => onOpenEditModal({ designShouldBePublished: true })}
        />
    )
}

export default EditorTopbarWithState
