import React, { useState, useEffect } from 'react'
import useDebounce from 'components/hooks/useDebounce'
import useFetchCategoryMenuData from './useFetchCategoryMenuData'
import CategoryMenu from './CategoryMenu'
import useSearchAssetsLazyQuery from '../useSearchAssetsLazyQuery'

const CategoryMenuWithState = ({ onChangeCategory }) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [results, setResults] = useState([])
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const {
        isFetching,
        categories,
    } = useFetchCategoryMenuData()
    const [searchAssets, { data, loading: isSearching }] = useSearchAssetsLazyQuery()

    useEffect(() => {
        if (debouncedSearchTerm) {
            searchAssets({ variables: { query: debouncedSearchTerm } })
        } else {
            setResults([])
        }
    }, [debouncedSearchTerm, searchAssets])

    useEffect(() => {
        if (!isSearching && typeof data !== 'undefined') {
            setResults(data.searchAssetsByTag)
        }
    }, [isSearching, data])

    return (
        <CategoryMenu
            isCollapsed={isCollapsed}
            onToggleCollapse={() => setIsCollapsed(!isCollapsed)}
            isFetching={isFetching}
            categories={categories}
            onChangeCategory={onChangeCategory}
            searchTerm={searchTerm}
            debouncedSearchTerm={debouncedSearchTerm}
            onChangeSearchTerm={setSearchTerm}
            searchResult={results}
            isSearching={isSearching}
        />
    )
}

export default CategoryMenuWithState
