import React from 'react'
import {
    Box,
    Flex,
    Text,
    Stack,
    Heading,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'
import GetStartedButton from 'components/util/buttons/GetStartedButton'
import * as routes from 'routes/index'
import Image from 'components/util/Image'
import AuthModalWrapperWithState from 'components/pages/auth/AuthModalWrapperWithState'
import { COMPANY_NAME, COMPANY_ADDRESS } from 'util/constants'
import usePushHistory from 'components/hooks/usePushHistory'

const Footer = () => {
    const pushRouteWithSlug = usePushHistory()

    return (
        <AuthModalWrapperWithState>
            {({
                onParticipate,
                isAuthenticated,
                isOnboardedForTrajectory,
            }) => (
                <Box
                    mt={40}
                    mb={8}
                >
                    <Flex
                        justify="space-between"
                        flexWrap="wrap"
                        mt={8}
                    >
                        <Flex
                            mt={8}
                            w={['100%', '100%', '100', '30%']}
                            justify={['center', 'center', 'center', 'left']}
                        >
                            <Box>
                                <Image src="logos/logo.png" />
                                <Flex my={8}>
                                    <Image src="logos/antwerpen.png" />
                                    <Image ml={8} src="logos/rurant-logo.png" />
                                </Flex>
                                <Image src="logos/platteland-antwerpen-logo.png" />
                            </Box>
                        </Flex>
                        <Flex
                            mt={8}
                            w={['100%', '100%', '100', '30%']}
                            justify={['center', 'center', 'center', 'left']}
                        >
                            <Flex justify="space-between">
                                <Box w="45%">
                                    <Heading size="sm">Website</Heading>
                                    <Stack mt={2}>
                                        {routes.userLinks.map((link) => (
                                            <Box
                                                key={link.section}
                                                my={2}
                                            >
                                                <Link
                                                    linkTo={() => pushRouteWithSlug(link.path, { section: link.section })}
                                                    color="secondaryTextColor"
                                                >
                                                    {link.name}
                                                </Link>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                                <Box w="45%">
                                    <Heading size="sm">Links</Heading>
                                    <Stack mt={2}>
                                        {routes.externalLinks.map((link) => (
                                            <Box
                                                key={link.href}
                                                my={2}
                                            >
                                                <Link
                                                    href={link.href}
                                                    color="secondaryTextColor"
                                                    isExternal
                                                >
                                                    {link.name}
                                                </Link>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                            </Flex>
                        </Flex>
                        <Flex
                            mt={8}
                            w={['100%', '100%', '100', '30%']}
                            justify={['center', 'center', 'center', 'left']}
                        >
                            <Box>
                                <Box>
                                    <Heading size="sm">
                                        <Trans>Participate</Trans>
                                    </Heading>
                                    <Text mt={2} color="colorGrayDark">
                                        <Trans>Partake in this trajectory and give your ideas form</Trans>
                                    </Text>
                                </Box>
                                <GetStartedButton
                                    size="sm"
                                    mt={4}
                                    py={3}
                                    onClick={onParticipate}
                                    isAuthenticated={isAuthenticated}
                                    isOnboardedForTrajectory={isOnboardedForTrajectory}
                                />
                            </Box>
                        </Flex>
                    </Flex>
                    <Flex
                        justify="space-between"
                        mt={8}
                    >
                        <Stack justify="flex-end">
                            <Box color="colorGrayDark">
                                <Text>{COMPANY_NAME}</Text>
                                <Text>{COMPANY_ADDRESS}</Text>
                                <Text>
                                    <Link
                                        href="https://www.innovatiesteunpunt.be"
                                        isExternal
                                    >
                                        innovatiesteunpunt.be
                                    </Link>
                                </Text>
                            </Box>
                        </Stack>
                        <Image src="home/footer.png" />
                    </Flex>
                </Box>
            )}
        </AuthModalWrapperWithState>
    )
}

export default Footer
