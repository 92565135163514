import React, { useRef } from 'react'
import {
    Box,
    Text,
    Stack,
    Divider,
    useToast,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import MainButton from 'components/util/buttons/MainButton'
import FacebookButton from 'components/util/buttons/FacebookButton'
import Link from 'components/util/Link'
import useRedirectToFacebookMutation from 'components/pages/login-facebook/useRedirectToFacebookMutation'

const GetStartedModalWithState = ({
    onClose,
    onRegister,
    onRegisterAnonymous,
    onLogin,
    trajectorySlug,
}) => {
    const toast = useToast()
    const focusRef = useRef()
    const [getFacebookRedirectLink, { loading: isRegisteringWithFacebook }] = useRedirectToFacebookMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Get started</Trans>}
            modalContent={(
                <Box>
                    <Text my={8}>
                        <Trans>
                            Would you like to make an anonymous (without your first and last name)
                            contribution or do you want to create an account?
                        </Trans>
                    </Text>
                    <MainButton
                        w="full"
                        disabled={isRegisteringWithFacebook}
                        onClick={() => {
                            onRegisterAnonymous()
                            onClose()
                        }}
                    >
                        <Trans>Get started anonymously</Trans>
                    </MainButton>
                    <Stack isInline w="full" my={4} fontSize="sm" alignItems="center">
                        <Divider w="full" />
                        <Text>
                            <Trans>OR</Trans>
                        </Text>
                        <Divider w="full" />
                    </Stack>
                    <MainButton
                        w="full"
                        focusRef={focusRef}
                        disabled={isRegisteringWithFacebook}
                        onClick={() => {
                            onRegister()
                            onClose()
                        }}
                    >
                        <Trans>Register with e-mail</Trans>
                    </MainButton>
                    <FacebookButton
                        w="full"
                        my={8}
                        loading={isRegisteringWithFacebook}
                        onClick={async () => {
                            try {
                                const { data } = await getFacebookRedirectLink({ variables: { trajectorySlug } })
                                window.location = data.facebookRedirectLink
                            } catch (e) {
                                toast({
                                    title: 'Verinding met Facebook mislukt',
                                    description: 'We konden geen verbinding maken met Facebook. Probeer het later opnieuw.',
                                    status: 'error',
                                    position: 'top',
                                })
                            }
                        }}
                    >
                        <Trans>Register with Facebook</Trans>
                    </FacebookButton>
                    <Box textAlign="center">
                        <Link
                            linkTo={() => {
                                onLogin()
                                onClose()
                            }}
                        >
                            <Trans>Already have an account? Login</Trans>
                        </Link>
                    </Box>
                </Box>
            )}
        />
    )
}

export default GetStartedModalWithState
