import React from 'react'
import { Stack, Icon } from '@chakra-ui/core'

const UserIcon = () => (
    <Stack
        w="50px"
        h="50px"
        rounded="full"
        bg="colorGrayLighter"
        align="center"
        justify="center"
    >
        <Icon
            name="profile"
            size="24px"
            fill="none"
            stroke="colorGrayDark"
        />
    </Stack>
)

export default UserIcon
