import React, { useState } from 'react'
import OnboardingModalWithState from 'components/pages/onboarding/OnboardingModalWithState'
import useUserStatus from 'components/hooks/useUserStatus'
import useAppState from 'components/hooks/useAppState'
import GetStartedModalWithState from './GetStartedModalWithState'
import ChooseTemplateModalWithState from './choose-template/ChooseTemplateModalWithState'
import RegisterAnonymousModalWithState from './register-anonymous/RegisterAnonymousModalWithState'
import RegisterModalWithState from './register/RegisterModalWithState'
import LoginModalWithState from './login/LoginModalWithState'
import RequestPasswordResetModalWithState from './request-password-reset/RequestPasswordResetModalWithState'

const AuthModalWrapperWithState = ({ children }) => {
    const { slug } = useAppState()
    const {
        isAuthenticated,
        isOnboardedForTrajectory,
    } = useUserStatus()

    const [isGetStartedModalOpen, setIsGetStartedModalOpen] = useState(false)
    const [isregisterAnonymousModalOpen, setIsregisterAnonymousModalOpen] = useState(false)
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)
    const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false)
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
    const [isChooseTemplateModalOpen, setIsChooseTemplateModalOpen] = useState(false)
    const [isRequestPasswordResetModalOpen, setIsRequestPasswordResetModalOpen] = useState(false)

    const onParticipate = () => {
        if (isAuthenticated) {
            if (isOnboardedForTrajectory) {
                setIsChooseTemplateModalOpen(true)
            } else {
                setIsOnboardingModalOpen(true)
            }
        } else {
            setIsGetStartedModalOpen(true)
        }
    }

    return (
        <>
            {(() => children({
                onParticipate,
                isAuthenticated,
                isOnboardedForTrajectory,
            }))()}
            {isGetStartedModalOpen && (
                <GetStartedModalWithState
                    onClose={() => setIsGetStartedModalOpen(false)}
                    onRegister={() => setIsRegisterModalOpen(true)}
                    onRegisterAnonymous={() => setIsregisterAnonymousModalOpen(true)}
                    onLogin={() => setIsLoginModalOpen(true)}
                    trajectorySlug={slug}
                />
            )}
            {isregisterAnonymousModalOpen && (
                <RegisterAnonymousModalWithState
                    onClose={() => setIsregisterAnonymousModalOpen(false)}
                    onCompleteRegisterAnonymous={() => setIsChooseTemplateModalOpen(true)}
                />
            )}
            {isRegisterModalOpen && (
                <RegisterModalWithState
                    onClose={() => setIsRegisterModalOpen(false)}
                    trajectorySlug={slug}
                />
            )}
            {isOnboardingModalOpen && (
                <OnboardingModalWithState
                    onClose={() => setIsOnboardingModalOpen(false)}
                    trajectorySlug={slug}
                />
            )}
            {isLoginModalOpen && (
                <LoginModalWithState
                    onClose={() => setIsLoginModalOpen(false)}
                    onRequestPasswordReset={() => {
                        setIsLoginModalOpen(false)
                        setIsRequestPasswordResetModalOpen(true)
                    }}
                    onRegister={() => {
                        setIsLoginModalOpen(false)
                        setIsGetStartedModalOpen(true)
                    }}
                />
            )}
            {isChooseTemplateModalOpen && (
                <ChooseTemplateModalWithState
                    onClose={() => setIsChooseTemplateModalOpen(false)}
                    slug={slug}
                />
            )}
            {isRequestPasswordResetModalOpen && (
                <RequestPasswordResetModalWithState
                    onClose={() => setIsRequestPasswordResetModalOpen(false)}
                    slug={slug}
                />
            )}
        </>
    )
}

export default AuthModalWrapperWithState
