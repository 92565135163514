import * as THREE from 'three'
import { getClickedPoint, createLine } from './util/three'
import Drawer from './Drawer'
import { createVectorAssetState } from './util/api'

export default class CurvedLineDrawer extends Drawer {
    constructor(props) {
        super(props)

        this.startDraw = this.startDraw.bind(this)
        this.doDraw = this.doDraw.bind(this)
        this.stopDraw = this.stopDraw.bind(this)
        this.constructLine = this.constructLine.bind(this)
    }

    startDraw(event) {
        this.lastPoint = getClickedPoint(event, this.container, this.camera, this.drawingPlane)
    }

    doDraw(event) {
        if (this.lastPoint !== null) {
            const point = getClickedPoint(event, this.container, this.camera, this.drawingPlane)
            const geometry = new THREE.Geometry()

            if (Math.abs(this.lastPoint.x - point.x) < 500 && Math.abs(this.lastPoint.y - point.y) < 500 && Math.abs(this.lastPoint.z - point.z) < 500) {
                geometry.vertices.push(this.lastPoint)
                geometry.vertices.push(point)

                const linePiece = new THREE.Line(geometry, this.lineMaterial)
                this.linePieces.push(linePiece)
                this.scene.add(linePiece)
                this.lastPoint = point
            } else {
                console.debug(`${this.lastPoint.x.toString()}:${this.lastPoint.y.toString()}:${this.lastPoint.z.toString()}:${point.x.toString()}:${point.y.toString()}:${point.z.toString()}`)
            }
        }
    }

    stopDraw() {
        this.lastPoint = null
        this.constructLine()
    }

    async constructLine() {
        const vertices = this.linePieces.reduce((previousVertices, currentLinePiece) => {
            const lineVertices = currentLinePiece.geometry.vertices.reduce((previousLineVertices, currentLineVertex) => {
                const { x, y, z } = currentLineVertex

                return previousLineVertices.concat([x, y, z])
            }, [])
            currentLinePiece.geometry.dispose()
            currentLinePiece.material.dispose()
            this.scene.remove(currentLinePiece)

            return previousVertices.concat(lineVertices)
        }, [])

        const fullLine = createLine(vertices, this.lineMaterial)
        const vectorId = await createVectorAssetState(fullLine, this.designId, this.apolloClient)
        fullLine.vectorId = vectorId
        this.scene.add(fullLine)
        this.lines.push(fullLine)
        this.linePieces = []
    }
}
