import React from 'react'
import { Heading, Box } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import PhaseSummary from 'components/util/home/PhaseSummary'
import CurrentPhaseSummary from 'components/util/home/CurrentPhaseSummary'
import { getPhaseStatus } from 'util/phase'
import CollapseableDescription from 'components/util/CollapseableDescription'

const HomePhases = ({ phases }) => (
    <>
        <Heading
            as="h2"
            fontSize="xl"
        >
            <Trans>Timeline</Trans>
        </Heading>
        {phases.map((phase, index) => {
            const { startDate, endDate } = phase
            const {
                isCurrent,
                hasEnded,
            } = getPhaseStatus(startDate, endDate)

            if (isCurrent) {
                return (
                    <CurrentPhaseSummary
                        key={phase.id}
                        phase={{
                            ...phase,
                            order: index + 1,
                        }}
                        mt={8}
                    />
                )
            }

            return (
                <Box key={phase.id}>
                    <PhaseSummary
                        phase={{
                            ...phase,
                            order: index + 1,
                        }}
                        hasEnded={hasEnded}
                        mt={8}
                    />
                    <CollapseableDescription
                        description={phase.description}
                        length={0}
                        ml={20}
                        isMarkup
                    />
                </Box>
            )
        })}
    </>
)

export default HomePhases
