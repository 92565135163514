import decodeJwtToken from 'jwt-decode'
import * as storage from './storage'
import refreshAccessToken from '../api/refreshAccessToken'

export const isTokenExpired = (token) => {
    const decodedToken = decodeJwtToken(token)
    const tokenExpiresAt = decodedToken.exp * 1000
    return tokenExpiresAt < new Date().getTime()
}

export const loadAndValidateTokens = async () => {
    let accessToken = await storage.getAccessToken()
    let refreshToken = await storage.getRefreshToken()
    const tokensAreSet = accessToken !== null && refreshToken !== null
    if (tokensAreSet) {
        if (isTokenExpired(refreshToken)) {
            await storage.removeAccessToken()
            await storage.removeRefreshToken()
            accessToken = null
            refreshToken = null
        } else if (isTokenExpired(accessToken)) {
            const tokens = await refreshAccessToken(refreshToken)
            if (tokens !== null) {
                ({ accessToken, refreshToken } = tokens)
                await storage.setAccessToken(accessToken)
                await storage.setRefreshToken(refreshToken)
            } else {
                await storage.removeRefreshToken
            }
        }
    }
    return {
        refreshToken,
        accessToken,
    }
}
