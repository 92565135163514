import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import { isValidEmail } from 'validation/user'

const initialRequestPasswordResetFormValues = (trajectorySlug) => ({
    email: '',
    trajectorySlug,
})

const validation = createFormValidation([{
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: <Trans key="email">Please fill in a valid email.</Trans>,
}])

const useRequestPasswordResetForm = (trajectorySlug, options) => (
    useFormState(
        initialRequestPasswordResetFormValues(trajectorySlug),
        {
            validation,
            ...options,
        },
    )
)

export default useRequestPasswordResetForm
