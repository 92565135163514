import React from 'react'
import {
    Button,
    Flex,
    FormLabel,
    FormControl,
    useTheme,
} from '@chakra-ui/core'
import { getOptionProps } from 'util/forms/toggle'
import ErrorField from './ErrorField'

const Toggle = ({
    options,
    value,
    id,
    label,
    onChange,
    error,
    ...props
}) => {
    const theme = useTheme()

    return (
        <FormControl
            mt={2}
        >
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <Flex {...props}>
                {options.map((option, index) => (
                    <Button
                        key={option.value}
                        onClick={() => onChange(option.value)}
                        ml={index === 0 ? 0 : -2}
                        _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
                        {...getOptionProps(index, option, options.length, value)}
                    >
                        {option.label}
                    </Button>
                ))}

            </Flex>
            <ErrorField mt={2} errorMessage={error} />
        </FormControl>
    )
}

export default Toggle
