import { gql, ApolloClient } from 'apollo-boost'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'


const REFRESH_ACCESS_TOKEN_MUTATION = gql`
    mutation refreshToken($input: RefreshTokenInput) {
        refreshToken(input: $input) {
            accessToken
            refreshToken
        }
    }
`

const createUnauthorizedApolloClient = () => {
    const { REACT_APP_GRAPHQL_URI } = process.env

    return (
        new ApolloClient({
            cache: new InMemoryCache(),
            link: new HttpLink({
                uri: REACT_APP_GRAPHQL_URI,
            }),
        })
    )
}

export default async function refreshAccessToken(refreshToken) {
    const apolloClient = createUnauthorizedApolloClient()
    const mutation = REFRESH_ACCESS_TOKEN_MUTATION
    const variables = { input: { refreshToken } }
    try {
        const response = await apolloClient.mutate({ mutation, variables })
        const { accessToken, refreshToken: newRefreshToken } = response.data.refreshToken
        return ({ accessToken, refreshToken: newRefreshToken })
    } catch (e) {
        return null
    }
}
