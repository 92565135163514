import React, { useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import ConfirmEmailBox from 'components/util/ConfirmEmailBox'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import RegisterForm from './RegisterForm'
import useRegisterForm from './useRegisterForm'
import useRegisterMutation from './useRegisterMutation'

const RegisterModalWithState = ({
    onClose,
    trajectorySlug,
}) => {
    const focusRef = useRef()
    const formState = useRegisterForm(trajectorySlug)
    const [registeredEmail, setRegisteredEmail] = useState(null)
    const [register, { loading: isRegistering }] = useRegisterMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={registeredEmail === null
                ? <Trans>Register</Trans>
                : <Trans>Confirm your email address</Trans>}
            modalContent={registeredEmail === null
                ? (
                    <RegisterForm
                        focusRef={focusRef}
                        formState={formState}
                        isRegistering={isRegistering}
                        onSubmit={async () => {
                            if (formState.validate()) {
                                try {
                                    const input = formState.values
                                    await register({ variables: { input } })
                                    setRegisteredEmail(input.email)
                                } catch (error) {
                                    formState.updateErrors(parseFormError(error, 'register'))
                                }
                            }
                        }}
                    />
                )
                : (<ConfirmEmailBox email={registeredEmail} />)}
        />
    )
}

export default RegisterModalWithState
