import React from 'react'
import {
    Flex,
    Stack,
    Box,
    Heading,
    Text,
} from '@chakra-ui/core'
import PageHeader from 'components/layout/PageHeader'
import PageContainer from 'components/layout/PageContainer'
import { LOGGED_IN_HEADER_PROFILE } from 'util/constants'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'
import ProfileDesignsList from './ProfileDesignsList'
import Footer from '../../layout/Footer'

const ProfilePageAnonymous = ({
    designs,
    onConvertAnonymousUser,
    onContinueWithDesign,
}) => (
    <PageContainer>
        <PageHeader page={LOGGED_IN_HEADER_PROFILE} />
        <ProfileDesignsList
            designs={designs}
            onContinueWithDesign={onContinueWithDesign}
            mt={24}
        />
        <Flex
            w="full"
            justify="center"
            mt={16}
        >
            <Stack
                w="2xl"
                justify="center"
                textAlign="center"
            >
                <Heading>
                    <Trans>Keep seeing your ideas in the future?</Trans>
                </Heading>
                <Heading fontSize="lg">
                    <Trans>Quickly create your account</Trans>
                </Heading>
                <Text
                    mt={8}
                    color="secondaryTextColor"
                >
                    <Trans>
                        Do you want to be able to consult your ideas again in the future?
                        Then register via the button below so that we can link your ideas
                        to your account. Without an account, you lose access to editing the
                        ideas that you have contributed anonymously to this process after 30 days.
                    </Trans>
                </Text>
                <Box mt={8}>
                    <MainButton
                        mt={8}
                        onClick={onConvertAnonymousUser}
                    >
                        <Trans>Create an account</Trans>
                    </MainButton>
                </Box>
            </Stack>
        </Flex>
        <Footer />
    </PageContainer>
)

export default ProfilePageAnonymous
