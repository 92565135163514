import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const ADMIN_EDITOR_LOGIN_MUTATION = gql`
    mutation loginWithEmail($input: LoginInput!) {
        login(input: $input) {
            accessToken
            refreshToken
            me {
                id
                username
                email
                age
                sex
                isAnonymous
                hasAdminEditorAccess
            }
        }
    }
`

const useAdminEditorLoginMutation = () => useMutation(ADMIN_EDITOR_LOGIN_MUTATION)

export default useAdminEditorLoginMutation
