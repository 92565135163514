import React, { useRef } from 'react'
import { useToast } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { editor } from 'routes/index'
import Modal from 'components/util/Modal'
import usePushHistory from 'components/hooks/usePushHistory'
import { hasLengthOf } from 'validation/index'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import useCreateDesignMutation from './useCreateDesignFromTemplateMutation'
import useFetchTemplateModalData from './useFetchTemplateModalData'
import ChooseTemplateModal from './ChooseTemplateModal'

const ChooseTemplateModalWithState = ({
    onClose,
    slug,
}) => {
    const toast = useToast()
    const focusRef = useRef()
    const pushRouteWithSlug = usePushHistory()
    const [createDesign, { loading: isCreatingDesign }] = useCreateDesignMutation()
    const {
        isFetching,
        templates,
    } = useFetchTemplateModalData(slug)

    useAsyncEffect(async () => {
        if (!isFetching && hasLengthOf(1)(templates)) {
            const input = { templateId: templates[0].id }
            const { data } = await createDesign({ variables: { input } })
            const { id: designId } = data.createDesignFromTemplate
            pushRouteWithSlug(editor, { designId })
        }
    }, [isFetching, templates])

    let modalTitle
    if (isFetching) {
        modalTitle = <Trans>Fetching ground plans...</Trans>
    } else if (isCreatingDesign) {
        modalTitle = <Trans>Creating your idea...</Trans>
    } else {
        modalTitle = <Trans>Choose your start</Trans>
    }

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={modalTitle}
            size="2xl"
            modalContent={(
                <ChooseTemplateModal
                    focusRef={focusRef}
                    isLoading={isFetching || isCreatingDesign}
                    templates={templates}
                    onChoose={async (templateId) => {
                        try {
                            const input = { templateId }
                            const { data } = await createDesign({ variables: { input } })
                            const { id: designId } = data.createDesignFromTemplate
                            pushRouteWithSlug(editor, { designId })
                        } catch (e) {
                            toast({
                                title: 'Design aanmaken mislukt',
                                description: 'Er ging iets mis bij het aanmaken van je design. Probeer het later opnieuw.',
                                status: 'error',
                                position: 'top',
                            })
                        }
                    }}
                />
            )}
        />
    )
}

export default ChooseTemplateModalWithState
