import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const CHOOSE_TEMPLATE_MODAL_QUERY = gql`
    query chooseTemplateModalQuery($trajectory: String!, $isTemplate: Boolean) {
        currentPhaseForTrajectory(trajectory: $trajectory){
            id
            designs(isTemplate: $isTemplate) {
                id
                name
                description
                featuredScreenshot {
                    id
                    urls
                }
                surface {
                    id
                    urls
                }
            }
        }
    }
`

const useFetchTemplateModalData = (slug) => {
    const variables = {
        trajectory: slug,
        isTemplate: true,
    }
    const {
        error,
        loading,
        data,
    } = useQuery(CHOOSE_TEMPLATE_MODAL_QUERY, { variables })

    if (loading) {
        return ({
            isFetching: true,
            templates: [],
        })
    }

    const { currentPhaseForTrajectory } = data
    let designs = []
    if (currentPhaseForTrajectory !== null) {
        ({ designs } = currentPhaseForTrajectory)
    }

    return ({
        error,
        isFetching: false,
        templates: designs,
    })
}

export default useFetchTemplateModalData
