import React from 'react'
import {
    Heading,
    Flex,
    Text,
    Box,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { calculateDaysRemaining } from 'util/phase'
import ProgressBar from '../ProgressBar'
import PhaseOrderTag from './PhaseOrderTag'
import CollapseableDescription from '../CollapseableDescription'

const CurrentPhaseSummary = ({
    phase,
    ...props
}) => {
    const { startDate, endDate } = phase
    const {
        daysRemaining,
        progress,
    } = calculateDaysRemaining(startDate, endDate)

    return (
        <Flex {...props}>
            <Box>
                <PhaseOrderTag
                    phaseOrder={phase.order}
                    isCurrent
                />
            </Box>
            <Box
                mt={2}
                ml={4}
                textAlign="left"
            >
                <Heading
                    as="h3"
                    fontSize="lg"
                >
                    {phase.title}
                </Heading>
                <Box textAlign="right">
                    <Text
                        fontSize="sm"
                        color="primaryColor"
                    >
                        {`${daysRemaining} `}
                        <Trans>Days remaning</Trans>
                    </Text>
                    <ProgressBar
                        height={1}
                        progress={progress}
                        color="primaryColor"
                    />
                </Box>
                <CollapseableDescription description={phase.description} isMarkup />
            </Box>
        </Flex>
    )
}

export default CurrentPhaseSummary
