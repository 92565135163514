import React, { useState, useEffect } from 'react'
import { Box } from '@chakra-ui/core'
import { motion, AnimatePresence } from 'framer-motion'
import ImageSliderControls from './ImageSliderControls'

const variants = {
    enter: () => ({
        opacity: 0,
    }),
    center: {
        zIndex: 1,
        opacity: 1,
    },
    exit: () => ({
        zIndex: 0,
    }),
}

const ImageSlider = ({
    urls,
    ...props
}) => {
    const [[page, direction], setPage] = useState([0, 0])

    const paginate = (newDirection) => {
        let newPage = page + newDirection
        if (newPage < 0) {
            newPage = urls.length - 1
        }
        if (newPage > urls.length - 1) {
            newPage = 0
        }
        setPage([newPage, newDirection])
    }

    useEffect(() => {
        // Preload images to avoid flickering
        urls.map((url) => {
            const image = new Image()
            image.src = url
        })
    }, [urls])

    return (
        <Box
            position="relative"
            {...props}
        >
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    key={page}
                    src={urls[page]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    style={{ borderRadius: '0.5rem' }}
                    transition={{ opacity: { duration: 1 } }}
                />
            </AnimatePresence>
            <Box
                position="absolute"
                w="full"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex="2"
            >
                <ImageSliderControls
                    onPrev={() => paginate(-1)}
                    onNext={() => paginate(1)}
                />
            </Box>
        </Box>
    )
}

export default ImageSlider
