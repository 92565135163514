import React, { useRef } from 'react'
import { Box, useToast } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import useAppState from 'components/hooks/useAppState'
import { parseFormError } from 'util/errors'
import Link from 'components/util/Link'
import useRedirectToFacebookMutation from 'components/pages/login-facebook/useRedirectToFacebookMutation'
import InfoToast from 'components/util/InfoToast'
import useLoginWithEmailMutation from './useLoginMutation'
import LoginForm from './LoginForm'
import useLoginForm from './useLoginForm'

const LoginModalWithState = ({
    onClose,
    onRequestPasswordReset,
    onRegister,
}) => {
    const toast = useToast()
    const focusRef = useRef()
    const formState = useLoginForm()
    const { login, slug } = useAppState()
    const [loginWithEmail, { loading: isLoggingIn }] = useLoginWithEmailMutation(formState.values)
    const [getFacebookRedirectLink, { loading: isLoggingInWithFacebook }] = useRedirectToFacebookMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Login</Trans>}
            modalContent={(
                <Box>
                    <LoginForm
                        focusRef={focusRef}
                        formState={formState}
                        isLoggingIn={isLoggingIn}
                        isLoggingInWithFacebook={isLoggingInWithFacebook}
                        onLoginWithFacebook={async () => {
                            try {
                                const { data } = await getFacebookRedirectLink({ variables: { trajectorySlug: slug } })
                                window.location = data.facebookRedirectLink
                            } catch (e) {
                                toast({
                                    title: 'Verbinding met Facebook mislukt',
                                    description: 'We konden geen verbinding maken met Facebook. Probeer het later opnieuw.',
                                    status: 'error',
                                    position: 'top',
                                })
                            }
                        }}
                        onRequestPasswordReset={onRequestPasswordReset}
                        onSubmit={async () => {
                            if (formState.validate()) {
                                try {
                                    const input = formState.values
                                    const { data } = await loginWithEmail({ variables: { slug, input } })
                                    const {
                                        accessToken,
                                        refreshToken,
                                        me,
                                    } = data.login
                                    await login(accessToken, refreshToken, me)
                                    onClose()
                                    toast({
                                        position: 'top',
                                        render: () => (
                                            <InfoToast
                                                content={(
                                                    <>
                                                        Welkom terug
                                                        {' '}
                                                        {me.isAnonymous ? 'Anoniem' : me.username}
                                                        !
                                                    </>
                                                )}
                                            />
                                        ),
                                    })
                                } catch (e) {
                                    formState.updateErrors(parseFormError(e, 'login'))
                                }
                            }
                        }}
                    />
                    <Box textAlign="center" mt={6}>
                        <Link linkTo={onRegister}>
                            <Trans>No account yet? Register</Trans>
                        </Link>
                    </Box>
                </Box>
            )}
        />
    )
}

export default LoginModalWithState
