import React from 'react'
import { Trans } from '@lingui/macro'

export const root = () => '/'

export const adminEditor = () => '/admin-editor/'

export const loginFacebook = () => '/login-facebook'

export const assetViewer = (id = ':id') => `/asset-preview/${id}`

export const assetEditor = () => '/asset-editor/'

export const home = (slug = ':slug') => `/${slug}`

export const verifyEmail = (slug = ':slug', verificationCode = ':verificationCode') => `${home(slug)}/verify-email/${verificationCode}`

export const editor = (slug = ':slug') => `${home(slug)}/editor`

export const designs = (slug = ':slug') => `${home(slug)}/designs`

export const news = (slug = ':slug') => `${home(slug)}/news`

export const milestones = (slug = ':slug') => `${home(slug)}/milestones`

export const profile = (slug = ':slug') => `${home(slug)}/profile`

export const resetPassword = (slug = ':slug', resetToken = ':resetToken') => `${home(slug)}/password/reset/${resetToken}`

export const userLinks = [{
    name: <Trans>Home</Trans>,
    path: home,
    section: 'home',
}, {
    name: <Trans>Ideas</Trans>,
    path: designs,
    section: 'designs',
}, {
    name: <Trans>News</Trans>,
    path: home,
    section: 'news',
}, {
    name: <Trans>Milestones</Trans>,
    path: home,
    section: 'milestones',
}]

export const externalLinks = [{
    name: 'Innovatie steunpunt',
    href: 'https://www.innovatiesteunpunt.be',
}, {
    name: 'Bendemakers',
    href: 'https://www.innovatiesteunpunt.be/nl/platteland/over/consulenten',
}, {
    name: <Trans>Privacy statement</Trans>,
    href: 'https://www.innovatiesteunpunt.be/nl/privacy',
}, {
    name: <Trans>Terms of use</Trans>,
    href: 'https://www.innovatiesteunpunt.be/nl/gebruikersvoorwaarden',
}]
