import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import { isValue } from 'validation/index'
import { DESIGN_STATUS_PUBLISHED } from 'util/constants'

const designToFormValues = (design, designShouldBePublished) => ({
    name: design.name,
    description: design.description,
    scaleModifier: design.scaleModifier,
    featuredScreenshot: design.featuredScreenshot || { urls: { thumb: '' } },
    status: designShouldBePublished ? DESIGN_STATUS_PUBLISHED : design.status,
})

const validation = createFormValidation([{
    path: 'name',
    validate: (name) => isValue(name),
    message: <Trans>Please fill in a name.</Trans>,
}, {
    path: 'description',
    validate: (description) => isValue(description),
    message: <Trans>Please fill in a short description</Trans>,
}])

const toUpdateDesignInput = ({ featuredScreenshot, ...formValues }) => ({
    ...formValues,
    featuredScreenshot: featuredScreenshot.id,
})

const useEditDesignForm = ({ design, designShouldBePublished }, options) => (
    useFormState(
        designToFormValues(design, designShouldBePublished),
        {
            validation,
            valuesToInput: toUpdateDesignInput,
            ...options,
        },
    )
)

export default useEditDesignForm
