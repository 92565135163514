import React, { useRef } from 'react'
import { useToast } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import ResetPasswordForm from './ResetPasswordForm'
import useResetPasswordForm from './useResetPasswordForm'
import useResetPasswordMutation from './useResetPasswordMutation'

const ResetPasswordModalWithState = ({
    onClose,
    resetToken,
}) => {
    const focusRef = useRef()
    const toast = useToast()
    const formState = useResetPasswordForm(resetToken)
    const [resetPassword, { loading: isResettingPassword }] = useResetPasswordMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Choose a new password</Trans>}
            modalContent={(
                <ResetPasswordForm
                    focusRef={focusRef}
                    formState={formState}
                    isResettingPassword={isResettingPassword}
                    onSubmit={async () => {
                        if (formState.validate()) {
                            try {
                                const input = formState.values
                                await resetPassword({ variables: { input } })
                                toast({
                                    title: 'Je wachtwoord werd aangepast!',
                                    description: 'Je kan nu inloggen met je nieuwe wachtwoord.',
                                    status: 'success',
                                    position: 'top',
                                })
                                onClose()
                            } catch (error) {
                                formState.updateErrors(parseFormError(error, 'resetPassword'))
                            }
                        }
                    }}
                />
            )}
        />
    )
}

export default ResetPasswordModalWithState
