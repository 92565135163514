import React from 'react'
import { Heading, Box } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'
import NewsItemSummary from 'components/util/home/NewsItemSummary'

const HomeNews = ({ news }) => (
    <>
        <Heading
            as="h2"
            fontSize="xl"
        >
            <Trans>Recent news</Trans>
        </Heading>
        {news.map((newsItem) => (
            <NewsItemSummary
                key={newsItem.id}
                newsItem={newsItem}
            />
        ))}
        <Box mt={8}>
            <Link>
                <Trans>See all news items</Trans>
            </Link>
        </Box>
    </>
)

export default HomeNews
