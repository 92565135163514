import * as THREE from 'three'
import { OrbitControls, MapControls } from 'three/examples/jsm/controls/OrbitControls'
import { degreesToRadians } from 'util/converters'
import { isNotEmpty } from 'validation/index'
import { ENV_3D, IMAGE_EXTENSIONS, DEFAULT_CAMERA_POSITION_3D } from './constants'

export const calculateIntersects = (event, canvas, camera, objects) => {
    const mouse = new THREE.Vector2()
    const { offsetTop } = canvas

    if (typeof event.touches !== 'undefined' && isNotEmpty(event.touches)) {
        mouse.x = (event.touches[0].pageX / canvas.clientWidth) * 2 - 1
        mouse.y = ((event.touches[0].pageY - offsetTop) / canvas.clientHeight) * -2 + 1
    } else {
        mouse.x = (event.clientX / canvas.clientWidth) * 2 - 1
        mouse.y = ((event.clientY - offsetTop) / canvas.clientHeight) * -2 + 1
    }

    const raycaster = new THREE.Raycaster()
    raycaster.params.Line.threshold = 0.1
    raycaster.setFromCamera(mouse, camera)

    return raycaster.intersectObjects(objects, true)
}

export const getClickedPoint = (event, canvas, camera, clickSurface) => {
    const intersects = calculateIntersects(event, canvas, camera, [clickSurface])
    let point = null
    if (intersects.length > 0) {
        ([{ point }] = intersects)
        point.y = 0
    }

    return point
}

export const isImage = (fileType) => IMAGE_EXTENSIONS.includes(fileType)

export const getAssetFromObject = (object) => {
    if (typeof object.assetId !== 'undefined') {
        return object
    }

    const { parent } = object
    if (typeof parent === 'undefined' || parent === null) {
        return null
    }
    if (typeof parent.assetId === 'undefined') {
        return getAssetFromObject(parent)
    }

    return parent
}

export const getClickedAssetFromIntersects = (intersects) => {
    const { object } = intersects[0]

    if (object.type === 'LineSegments' || isImage(object.fileType)) {
        return object
    }

    return getAssetFromObject(object)
}

export const createDrawingPlane = (width, height) => {
    const drawingPlaneGeometry = new THREE.PlaneGeometry(width, height)
    drawingPlaneGeometry.rotateX(degreesToRadians(270))
    const drawingPlaneMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })

    return new THREE.Mesh(drawingPlaneGeometry, drawingPlaneMaterial)
}

export const createLine = (vertices, material) => {
    const lineGeometree = new THREE.BufferGeometry()
    lineGeometree.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
    const line = new THREE.LineSegments(lineGeometree, material)

    return line
}

export const configureCameraPosition = (design, editorHeight) => {
    if (design === null) {
        return DEFAULT_CAMERA_POSITION_3D
    }
    if (design.cameraPosition === null) {
        const cameraPosition = DEFAULT_CAMERA_POSITION_3D
        cameraPosition.position.y = editorHeight + 150
        return cameraPosition
    }

    return design.cameraPosition
}

export const createCamera = (aspectRatio, position) => {
    const camera = new THREE.PerspectiveCamera(60, aspectRatio, 1, 100000)
    camera.position.set(position.x, position.y, position.z)

    return camera
}

export const createOrbitControls = (camera, canvas, target) => {
    const orbitControls = new OrbitControls(camera, canvas)
    orbitControls.maxPolarAngle = degreesToRadians(75)
    orbitControls.minDistance = 5
    orbitControls.maxDistance = 10000
    orbitControls.keyPanSpeed = 25
    orbitControls.controlMode = 'orbit'
    orbitControls.target.set(target.x, target.y, target.z)

    return orbitControls
}

export const createTranslateControls = (camera, canvas, env, target) => {
    const translateControls = new MapControls(camera, canvas)
    if (env === ENV_3D) {
        translateControls.enabled = false
    }
    translateControls.minDistance = 5
    translateControls.maxDistance = 10000
    translateControls.controlMode = 'translate'
    translateControls.target.set(target.x, target.y, target.z)
    // Prevent rotation when using two fingers on a touch display
    translateControls.touches.TWO = 1

    return translateControls
}

export const createLights = (width, height) => {
    const lights = []
    lights[0] = new THREE.PointLight(0xffffff, 1, 0, 1)
    lights[1] = new THREE.PointLight(0xffffff, 1, 0, 1)
    lights[2] = new THREE.PointLight(0xffffff, 1, 0, 1)
    lights[3] = new THREE.PointLight(0xffffff, 1, 0, 1)
    lights[4] = new THREE.PointLight(0xffffff, 1, 0, 1)
    lights[0].position.set(-width, 0, height)
    lights[1].position.set(width, 0, height)
    lights[2].position.set(-width, 0, -height)
    lights[3].position.set(-width, 0, -height)
    lights[4].position.set(0, 2500, 0)

    return lights
}

export const threeRotationDesignAssetRotationInput = ({ _x, _y, _z }) => ({
    x: _x,
    y: _y,
    z: _z,
})

export const threeAssetToDesignAssetStateInput = (threeAsset, designId) => {
    const {
        assetId,
        position,
        rotation,
        scale,
        commentContent,
    } = threeAsset
    return ({
        assetId,
        designId,
        position: { ...position },
        rotation: { ...threeRotationDesignAssetRotationInput(rotation) },
        scale: { ...scale },
        comment: commentContent,
    })
}

export const threeLineToVectorStateInput = (threeLine, designId) => {
    const {
        position,
        rotation,
        scale,
        geometry: { attributes: { position: { array: points } } },
    } = threeLine
    return ({
        designId,
        position: { ...position },
        rotation: { ...threeRotationDesignAssetRotationInput(rotation) },
        scale: { ...scale },
        points,
    })
}

export const createPlane = async (url) => {
    const texture = await new Promise((resolve, reject) => new THREE.TextureLoader().load(url, resolve, null, reject))
    const { image: { width, height } } = texture
    const geometry = new THREE.PlaneGeometry(width, height)
    geometry.rotateX(degreesToRadians(-90))
    const material = new THREE.MeshBasicMaterial({ map: texture })
    const plane = new THREE.Mesh(geometry, material)

    return ({ width, height, plane })
}

export const createImagePlane = async (url) => {
    const { plane } = await createPlane(url)
    plane.name = 'image'

    return plane
}

export const createSurface = async (url) => {
    const { width, height, plane } = await createPlane(url)
    plane.name = 'surface'

    return ({
        threeSurface: plane,
        surfaceWidth: width,
        surfaceHeight: height,
    })
}

export const showCommentTooltip = (event, tooltip, content) => {
    if (tooltip !== null) {
        const pointCoords = new THREE.Vector2()

        if (typeof event.touches !== 'undefined' && isNotEmpty(event.touches)) {
            pointCoords.x = event.touches[0].pageX - tooltip.clientWidth / 2
            pointCoords.y = event.touches[0].pageY - tooltip.clientHeight * 2
        } else {
            pointCoords.x = event.clientX - tooltip.clientWidth / 2
            pointCoords.y = event.clientY - tooltip.clientHeight * 2
        }

        /* eslint-disable no-param-reassign */
        tooltip.style.left = `${pointCoords.x}px`
        tooltip.style.top = `${pointCoords.y}px`
        tooltip.textContent = content
        tooltip.style.display = 'block'
    }
}

export const hideCommentTooltip = (tooltip) => {
    if (tooltip !== null) {
        /* eslint-disable no-param-reassign */
        tooltip.style.display = 'none'
    }
}

export const getObjectScale = ({ x, y, z }) => {
    if (x === y) {
        return z
    }
    if (x === z) {
        return y
    }
    return x
}
