import React from 'react'
import { useTheme, Text } from '@chakra-ui/core'

const ErrorField = ({
    errorMessage,
    ...props
}) => {
    const theme = useTheme()

    return (
        <Text
            color={theme.colors.red['500']}
            h={theme.fontSizes.sm}
            fontSize="sm"
            mt={1}
            pl={2}
            fontStyle="italic"
            {...props}
        >
            {errorMessage}
        </Text>
    )
}

export default ErrorField
