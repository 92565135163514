import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import {
    isValidRegisterPassword,
    isValidConfirmPassword,
    isValidEmail,
} from 'validation/user'

const initialResetPasswordFormValues = (token) => ({
    email: '',
    password: '',
    passwordConfirmation: '',
    token,
})

const validation = createFormValidation([{
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: <Trans key="email">Please fill in a valid email.</Trans>,
}, {
    path: 'password',
    validate: (password) => isValidRegisterPassword(password),
    message: <Trans key="password">Please fill in a password of at least 8 characters</Trans>,
}, {
    path: 'passwordConfirmation',
    validate: (passwordConfirmation, { password }) => isValidConfirmPassword(password, passwordConfirmation),
    message: <Trans key="passwordConfirmation">Please fill in two matching paswords</Trans>,
}])

const useResetPasswordForm = (token, options) => (
    useFormState(
        initialResetPasswordFormValues(token),
        {
            validation,
            ...options,
        },
    )
)

export default useResetPasswordForm
