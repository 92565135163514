import React from 'react'
import ImageSlider from 'components/util/image-slider/ImageSlider'

const HomePictures = ({ photos }) => {
    const urls = photos.map((photo) => photo.urls.medium)

    return (
        <ImageSlider
            mt={2}
            urls={urls}
        />
    )
}

export default HomePictures
