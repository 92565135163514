import React from 'react'
import { Heading, ListItem } from '@chakra-ui/core'
import DateTag from '../DateTag'

const MilestoneListItem = ({ milestone }) => (
    <ListItem mt={8}>
        <DateTag dateString={milestone.date} />
        <Heading
            mt={2}
            fontSize="20px"
        >
            {milestone.title}
        </Heading>
    </ListItem>
)

export default MilestoneListItem
