import React from 'react'
import { Stack } from '@chakra-ui/core'
import EditorContainer from '../editor/EditorContainer'
import AssetEditorTopbar from './AssetEditorTopbar'
import AssetEditorControlsWithState from './AssetEditorControlsWithState'
import AssetControlsWithState from '../editor/editor-controls/AssetControlsWithState'

const AssetEditorPage = ({
    asset,
    onSaveAndExit,
    onOpenInfoModal,
    isUpdatingAsset,
    editorIsLoading,
    onEditorIsLoaded,
    onTakePreviewPicture,
}) => (
    <Stack h="full">
        <AssetEditorTopbar
            asset={asset}
            onOpenInfoModal={onOpenInfoModal}
            onTakePreviewPicture={onTakePreviewPicture}
            onSaveAndExit={onSaveAndExit}
            isUpdatingAsset={isUpdatingAsset}
        />
        <EditorContainer
            onEditorIsLoaded={onEditorIsLoaded}
        />
        {!editorIsLoading && (
            <>
                <AssetControlsWithState isAssetEditor />
                <AssetEditorControlsWithState />
            </>
        )}
    </Stack>
)

export default AssetEditorPage
