const sharedProps = {
    size: 'sm',
    w: '75px',
    _hover: 'none',
    rounded: 'none',
}
const firstProps = {
    roundedLeft: 'lg',
    borderRight: 'none',
}
const middleProps = {
    borderX: 'none',
}
const lastProps = {
    roundedRight: 'lg',
    borderLeft: 'none',
}
const selectedProps = {
    zIndex: 1,
    rounded: 'lg',
    bg: 'colorPurple',
    color: 'colorWhite',
}
const deselectedProps = {
    bg: 'colorGrayWhite',
    color: 'secondaryTextColor',
    variant: 'outline',
}

/* eslint-disable import/prefer-default-export */
export const getOptionProps = (position, option, optionCount, selectedValue) => {
    let positionProps

    if (position === 0) {
        positionProps = firstProps
    } else if (position === optionCount - 1) {
        positionProps = lastProps
    } else {
        positionProps = middleProps
    }

    let selectionProps
    if (selectedValue === option.value) {
        selectionProps = selectedProps
    } else {
        selectionProps = deselectedProps
    }

    return {
        ...sharedProps,
        ...positionProps,
        ...selectionProps,
    }
}
