import React from 'react'
import { home } from 'routes/index'
import usePushHistory from 'components/hooks/usePushHistory'
import ResetPasswordModalWithState from './ResetPasswordModalWithState'
import HomePageWithState from '../home/HomePageWithState'

const ResetPasswordPageWithState = ({ match }) => {
    const {
        slug,
        resetToken,
    } = match.params
    const pushRouteWithSlug = usePushHistory()

    return (
        <>
            <HomePageWithState slug={slug} />
            <ResetPasswordModalWithState
                onClose={() => pushRouteWithSlug(home)}
                resetToken={resetToken}
            />
        </>
    )
}

export default ResetPasswordPageWithState
