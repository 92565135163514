import React from 'react'
import { Stack, Text } from '@chakra-ui/core'
import useEditorState from 'components/hooks/useEditorState'
import EditorContentMenuWithState from './editor-content-menu/EditorContentMenuWithState'
import EditorInstructions from './EditorInstructions'
import EditorControlsWithState from './editor-controls/EditorControlsWithState'
import EditorContainer from './EditorContainer'
import EditorTopbarWithState from './editor-topbar/EditorTopbarWithState'
import AdminEditorTopbarWithState from '../admin-editor/AdminEditorTopbarWithState'

const EditorPage = ({
    design,
    isAdminEditor,
    isReadOnly,
    onEditComment,
    onOpenEditModal,
    onOpenShotsModal,
    onOpenShareModal,
    onTakeScreenshot,
    onOpenEditorDesignInfoModal,
}) => {
    const {
        loadDesign,
        editorDrawMode,
        editorIsWaiting,
        isPlacingComments,
    } = useEditorState()

    return (
        <Stack h="full">
            {isAdminEditor
                ? (
                    <AdminEditorTopbarWithState
                        design={design}
                        onOpenEditModal={onOpenEditModal}
                        onOpenShotsModal={onOpenShotsModal}
                    />
                )
                : (
                    <EditorTopbarWithState
                        design={design}
                        isReadonly={isReadOnly}
                        onOpenEditModal={onOpenEditModal}
                        onOpenShotsModal={onOpenShotsModal}
                        onOpenShareModal={onOpenShareModal}
                        onOpenEditorDesignInfoModal={onOpenEditorDesignInfoModal}
                    />
                )}
            <EditorInstructions
                isPlacingComments={isPlacingComments}
                editorDrawMode={editorDrawMode}
                editorIsWaiting={editorIsWaiting}
            />
            <EditorContainer
                onEditorIsLoaded={loadDesign}
            />
            <Text
                id="commentTooltip"
                display="none"
                pos="fixed"
                bg="colorWhite"
                px={4}
                py={1}
                rounded="lg"
            />
            <EditorControlsWithState
                is2D={design.is2D}
                isReadOnly={isReadOnly}
                onTakeScreenshot={onTakeScreenshot}
                onEditComment={onEditComment}
            />
            {!isReadOnly && (
                <EditorContentMenuWithState />
            )}
        </Stack>
    )
}

export default EditorPage
