import useAppState from './useAppState'

const useUserStatus = () => {
    const {
        currentUser,
        isAuthenticated,
    } = useAppState()

    let isOnboardedForTrajectory = false
    let isAnonymous = false
    let hasAdminEditorAccess = false
    if (currentUser !== null) {
        ({ isOnboardedForTrajectory } = currentUser);
        ({ isAnonymous } = currentUser);
        ({ hasAdminEditorAccess } = currentUser)
    }

    return {
        currentUser,
        isAuthenticated,
        isOnboardedForTrajectory,
        isAnonymous,
        hasAdminEditorAccess,
    }
}

export default useUserStatus
