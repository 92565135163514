import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import { isValidPostalCode, isValidAge } from 'validation/user'

const initialLoginFormValues = (trajectorySlug) => ({
    trajectorySlug,
    postalCode: '',
    sex: 'OTHER',
    age: '',
    agreedConditions: false,
})

const validation = createFormValidation([{
    path: 'postalCode',
    validate: (postalCode) => isValidPostalCode(postalCode),
    message: <Trans key="postalCode">Please fill in a postal code.</Trans>,
}, {
    path: 'age',
    validate: (age) => isValidAge(age),
    message: <Trans key="password">Please fill in your age</Trans>,
}, {
    path: 'agreedConditions',
    validate: (agreedConditions) => agreedConditions,
    message: <Trans key="agreedConditions">Please read and accept the conditions</Trans>,
}])

const useRegisterAnonymousForm = (trajectorySlug, options) => (
    useFormState(
        initialLoginFormValues(trajectorySlug),
        {
            validation,
            ...options,
        },
    )
)

export default useRegisterAnonymousForm
