import { gql } from 'apollo-boost'

const CREATE_DESIGN_ASSET_MUTATION = gql`
    mutation createDesignAsset($input: DesignAssetStateInput) {
        createDesignAssetState(input: $input) {
            id
        }
    }
`

export default async function createDesignAssetState(apolloClient, input, options) {
    const mutation = CREATE_DESIGN_ASSET_MUTATION
    const variables = { input }
    const { data } = await apolloClient.mutate({ mutation, variables, ...options })

    return data.createDesignAssetState
}
