import React, { useState } from 'react'
import { Text, Box } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'
import { isLongerThan } from 'validation/index'

const DESCRIPTION_LENGTH = 225

const CollapseableDescription = ({
    description = '',
    length = DESCRIPTION_LENGTH,
    isMarkup = false,
    ...props
}) => {
    const descriptionIsTooLong = isLongerThan(length)(description === null ? '' : description)
    const [showFullDescription, setShowFullDescription] = useState(!descriptionIsTooLong)

    let shownDescription
    if (showFullDescription) {
        shownDescription = description
    } else if (length === 0) {
        shownDescription = ''
    } else {
        shownDescription = `${description.substring(0, length)} ...`
    }

    return (
        <Box {...props}>
            {isMarkup
                ? (
                    <Text
                        color="secondaryTextColor"
                        dangerouslySetInnerHTML={{ __html: shownDescription }}
                    />
                )
                : (
                    <Text color="secondaryTextColor">
                        {shownDescription}
                    </Text>
                )}
            {descriptionIsTooLong && (
                <Text>
                    <Link linkTo={() => setShowFullDescription(!showFullDescription)}>
                        {showFullDescription
                            ? <Trans>Hide</Trans>
                            : <Trans>Discover more</Trans>}
                    </Link>
                </Text>
            )}
        </Box>
    )
}

export default CollapseableDescription
