import React from 'react'
import { Box } from '@chakra-ui/core'

const ProgressBar = ({
    progress,
    height = 4,
    color,
}) => (
    <Box w="full" h={height} bg="colorGrayLighter" rounded="full">
        <Box w={`${progress}%`} h="full" bg={color} rounded="full" />
    </Box>
)

export default ProgressBar
