import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloClient } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { loadAndValidateTokens } from '../util/auth'
import { containsUnauthenticatedError } from '../util/graphql'
import { removeAccessToken } from '../util/storage'

const setAuthContext = async (request, { headers }) => {
    const { accessToken } = await loadAndValidateTokens()
    if (accessToken === null) {
        return { headers }
    }
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
        },
    }
}

const removeJwtTokenFromCacheLink = onError((error) => {
    if (containsUnauthenticatedError(error)) {
        removeAccessToken()
    }
})

export default function createApolloClient() {
    const { REACT_APP_GRAPHQL_URI } = process.env

    const httpLink = new HttpLink({
        uri: REACT_APP_GRAPHQL_URI,
    })
    const setAuthorizationHeadersLink = setContext(setAuthContext)
    const linkFlow = setAuthorizationHeadersLink.concat(
        removeJwtTokenFromCacheLink.concat(
            httpLink,
        ),
    )

    return new ApolloClient({
        link: linkFlow,
        cache: new InMemoryCache({
            addTypename: false,
        }),
    })
}
