import React from 'react'
import { Box, Heading, Stack } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'
import { getDesignCardImageUrlFromDesign } from 'util/design'
import Link from 'components/util/Link'

const ProfileDesignCard = ({
    design,
    onContinueWithDesign,
}) => (
    <Box w="xs">
        <Link linkTo={() => onContinueWithDesign(design.id)}>
            <Stack
                bgImage={`url(${getDesignCardImageUrlFromDesign(design)})`}
                bgSize="cover"
                w="full"
                h="xs"
                bg="colorGrayLightest"
                rounded="lg"
                justify="flex-end"
                alignItems="flex-end"
                pb={4}
                pr={4}
            >
                <MainButton onClick={() => onContinueWithDesign(design.id)}>
                    <Trans>Open</Trans>
                </MainButton>
            </Stack>
        </Link>
        <Box mt={4}>
            <Heading as="h3" fontSize="lg">{design.name}</Heading>
        </Box>
    </Box>
)

export default ProfileDesignCard
