import React from 'react'
import useFetchAssetMenuData from './useFetchSubcategoryMenuData'
import SubcategoryMenu from './SubcategoryMenu'

const SubcategoryMenuWithState = ({
    category,
    onChangeSubcategory,
    goBackToCategoryMenu,
    is2D,
}) => {
    const {
        isFetching,
        subcategories,
        assets,
    } = useFetchAssetMenuData(category.slug, is2D)

    return (
        <SubcategoryMenu
            category={category}
            onChangeSubcategory={onChangeSubcategory}
            isFetching={isFetching}
            subcategories={subcategories}
            assets={assets}
            goBackToCategoryMenu={goBackToCategoryMenu}
        />
    )
}

export default SubcategoryMenuWithState
