import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const CONVERT_ANONYMOUS_USER_MUTATION = gql`
    mutation convertAnonymousUser($slug: String!, $input: ConvertAnonymousUserInput!) {
        convertAnonymousUser(input: $input) {
            id
            username
            email
            age
            sex
            isAnonymous
            isOnboardedForTrajectory(slug: $slug)
        }
    }
`

const useConvertAnonymousUserMutation = () => useMutation(CONVERT_ANONYMOUS_USER_MUTATION)

export default useConvertAnonymousUserMutation
