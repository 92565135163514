import React from 'react'
import { Stack, Heading } from '@chakra-ui/core'

const PhaseOrderTag = ({
    phaseOrder,
    isCurrent = false,
}) => (
    <Stack
        w={16}
        h={16}
        bg={isCurrent ? 'primaryColor' : 'colorGrayLighter'}
        color="colorWhite"
        rounded="full"
        alignItems="center"
        justify="center"
    >
        <Heading fontSize="xl">
            {phaseOrder}
        </Heading>
    </Stack>
)

export default PhaseOrderTag
