import React from 'react'
import { useToast } from '@chakra-ui/core'
import InfoToast from 'components/util/InfoToast'
import useUserStatus from 'components/hooks/useUserStatus'
import useToggleDesignLikeMutation from './useToggleDesignLikeMutation'
import DesignCard from './DesignCard'

const DesignCardWithState = ({
    design,
    onViewDesign,
    onLikeDesignComplete,
}) => {
    const toast = useToast()
    const {
        currentUser,
        isOnboardedForTrajectory,
    } = useUserStatus()
    const [toggleDesignLike] = useToggleDesignLikeMutation()
    const canLike = currentUser !== null && isOnboardedForTrajectory && currentUser.id !== design.user.id

    return (
        <DesignCard
            design={design}
            canLike={canLike}
            onViewDesign={onViewDesign}
            onToggleLike={async () => {
                try {
                    const variables = { id: design.id }
                    const { data } = await toggleDesignLike({ variables })
                    onLikeDesignComplete(data.toggleDesignLike)
                    toast({
                        position: 'top',
                        render: () => <InfoToast content="Bedankt voor je feedback!" />,
                    })
                } catch (e) {
                    toast({
                        title: 'Er liep iets mis...',
                        description: 'Je like kon niet verwerkt worden. Probeer het later opnieuw.',
                        status: 'error',
                        position: 'top',
                    })
                }
            }}
        />
    )
}

export default DesignCardWithState
