import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import DEFAULT_COORDINATE_FRAGMENT from 'apollo/fragments/defaultCoordinate'

const UPDATE_ASSET_STATE_MUTATION = gql`
    mutation updateAssetStateMutation($id: Int!$input: AssetStateInput) {
        updateAssetState(id: $id, input: $input) {
            id
            name
            url
            scaleModifier
            position {
                ...defaultCoordinate
            }
            rotation {
                ...defaultCoordinate
            }
            preview {
                id
                urls
            }
        }
    }
    ${DEFAULT_COORDINATE_FRAGMENT}
`

const useUpdateAssetStateMutation = () => useMutation(UPDATE_ASSET_STATE_MUTATION)

export default useUpdateAssetStateMutation
