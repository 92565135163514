import { gql } from 'apollo-boost'

const UPDATE_DESIGN_ASSET_MUTATION = gql`
    mutation updateDesignAsset($id: Int!, $input: DesignAssetStateInput) {
        updateDesignAssetState(id: $id, input: $input) {
            id
        }
    }
`

export default async function updateDesignAssetState(apolloClient, { id, input }, options) {
    const mutation = UPDATE_DESIGN_ASSET_MUTATION
    const variables = { id, input }
    await apolloClient.mutate({ mutation, variables, ...options })
}
