import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const EDITOR_INFO_MODAL_QUERY = gql`
    query editorInfoModalQuery($slug: String!, $id: Int!) {
        trajectory(slug: $slug) {
            title
            description
            city
        }
        design(id :$id){
            id
            name
            description
            status
            user {
                id
                isAnonymous
                username
            }
        }
    }
`

const useFetchEditorDesignInfoModalData = (slug, id) => {
    const variables = {
        slug,
        id,
    }
    const {
        loading,
        data,
    } = useQuery(EDITOR_INFO_MODAL_QUERY, { variables })

    if (loading) {
        return ({
            isFetching: true,
            trajectory: {},
            design: {},
        })
    }

    const { trajectory, design } = data

    return {
        isFetching: false,
        design,
        trajectory,
    }
}

export default useFetchEditorDesignInfoModalData
