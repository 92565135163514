import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input)
    }
`

const useResetPasswordMutation = () => useMutation(RESET_PASSWORD_MUTATION)

export default useResetPasswordMutation
