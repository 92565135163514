import React from 'react'
import { Button, Box } from '@chakra-ui/core'

const SecondaryButton = ({
    loading,
    onClick,
    children,
    ...props
}) => (
    <Box>
        <Button
            h="full"
            onClick={onClick}
            isLoading={loading}
            fontWeight="bold"
            rounded="lg"
            variant="outline"
            color="primaryColor"
            borderColor="primaryColor"
            borderWidth={2}
            px={4}
            py={2}
            _hover="none"
            _focus="none"
            {...props}
        >
            {children}
        </Button>
    </Box>
)

export default SecondaryButton
