import React, { useState } from 'react'
import {
    Text,
    Stack,
    Image,
    Icon,
    Box,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'
import MainButton from 'components/util/buttons/MainButton'

const PageError = ({
    onReportFeedback,
    error,
}) => {
    const [isShowingError, setIsShowingError] = useState(false)

    return (
        <Stack
            w="full"
            h="full"
            alignItems="center"
            justify="center"
        >
            <Box textAlign="center">
                <Image src="logos/logo.png" />
                <Text my={8}>
                    <Trans>Something went wrong, please try again later</Trans>
                </Text>
                {error
                    ? (
                        <>
                            <Text mb={2}>
                                <Link linkTo={() => setIsShowingError(!isShowingError)}>
                                    {isShowingError
                                        ? <Trans>Hide error</Trans>
                                        : <Trans>Show error</Trans>}
                                    <Icon
                                        name={isShowingError ? 'arrowUp' : 'arrowDown'}
                                        size="24px"
                                        stroke="primaryColor"
                                        fill="none"
                                    />
                                </Link>
                            </Text>
                            <Box
                                opacity={isShowingError ? 1 : 0}
                                w="full"
                                border="1px"
                                borderColor="primaryColor"
                                px={4}
                                py={2}
                                rounded="md"
                            >
                                {error}
                            </Box>
                        </>
                    )
                    : (
                        <MainButton onClick={onReportFeedback}>
                            <Trans>Report error</Trans>
                        </MainButton>
                    )}
            </Box>
        </Stack>
    )
}

export default PageError
