import React from 'react'
import { Flex, Icon } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import FormInput from 'components/util/forms/FormInput'
import Link from 'components/util/Link'
import MainButton from 'components/util/buttons/MainButton'
import ConfirmButton from 'components/util/buttons/ConfirmButton'
import SecondaryButton from 'components/util/buttons/SecondaryButton'

const EditDesignForm = ({
    focusRef,
    formState,
    isAdminEditor,
    designShouldBePublished,
    isDesignPublished,
    isSubmitting,
    onSubmit,
    onDelete,
    loading,
    onCancel,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    let submitButtonText
    if (designShouldBePublished) {
        if (isAdminEditor) {
            submitButtonText = <Trans>Publish</Trans>
        } else {
            submitButtonText = <Trans>Send</Trans>
        }
    } else if (isDesignPublished) {
        submitButtonText = <Trans>Save</Trans>
    } else {
        submitButtonText = <Trans>Save for now</Trans>
    }

    return (
        <>
            <FormInput
                {...getInputProps('name')}
                error={getErrorMessages('name')}
                id="name"
                label={isAdminEditor
                    ? <Trans>Give this template a nice name</Trans>
                    : <Trans>Give your idea a nice name</Trans>}
                type="text"
                placeholder="Name"
                focusRef={focusRef}
            />
            <FormInput
                {...getInputProps('description')}
                error={getErrorMessages('description')}
                id="description"
                label={isAdminEditor
                    ? <Trans>Describe this template</Trans>
                    : <Trans>Describe your idea</Trans>}
                type="textarea"
                placeholder="Short description"
            />
            <ConfirmButton
                onYes={onDelete}
                confirmMessage={isAdminEditor
                    ? <Trans>Are you sure you want to delete this template?</Trans>
                    : <Trans>Are you sure you want to delete this idea?</Trans>}
                loading={loading}
            >
                <Flex mt={1}>
                    <Icon name="trash" size="24px" stroke="colorRed" fill="colorWhite" />
                    <Link color="colorRed">
                        {isAdminEditor
                            ? <Trans>Delete this template</Trans>
                            : <Trans>Delete this idea</Trans>}
                    </Link>
                </Flex>
            </ConfirmButton>
            <Flex mt={10} justify="space-between">
                <SecondaryButton
                    onClick={onCancel}
                    disabled={loading}
                >
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <MainButton
                    onClick={onSubmit}
                    isLoading={isSubmitting}
                    disabled={loading}
                >
                    {submitButtonText}
                </MainButton>
            </Flex>
        </>
    )
}

export default EditDesignForm
