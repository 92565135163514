import { theme } from '@chakra-ui/core'
import { customIcons } from './icons'

const colorRed = '#FA5252'
const colorWhite = '#FFFFFF'
const colorBlack = '#1A202C'

const colorGrayWhite = '#F7FAFC'
const colorGrayLightest = '#EDF2F7'
const colorGrayLighter = '#E2E8F0'
const colorGrayLight = '#CBD5E0'
const colorGray = '#A0AEC0'
const colorGrayDark = '#718096'
const colorGrayDarker = '#4A5568'
const colorGrayDarkest = '#2D3748'
const colorGrayBlack = '#1A202C'

const colorPurpleWhite = '#E9E1ED'
const colorPurpleLightest = '#D0C0D9'
const colorPurpleLighter = '#BAA2C8'
const colorPurpleLight = '#A788B9'
const colorPurple = '#9671AB'
const colorPurpleDark = '#845C9B'
const colorPurpleDarker = '#735087'
const colorPurpleDarkest = '#644675'
const colorPurpleBlack = '#573D66'

const colorFacebook = '#1778F2'

const primaryTextColor = colorBlack
const secondaryTextColor = colorGrayDark
const accentTextColor = colorPurple
const placeholderTextColor = colorGrayLight
const primaryIconColor = colorGray
const primaryColor = colorPurple

const customColors = {
    colorRed,
    colorWhite,
    colorBlack,
    colorGrayWhite,
    colorGrayLightest,
    colorGrayLighter,
    colorGrayLight,
    colorGray,
    colorGrayDark,
    colorGrayDarker,
    colorGrayDarkest,
    colorGrayBlack,
    colorPurpleWhite,
    colorPurpleLightest,
    colorPurpleLighter,
    colorPurpleLight,
    colorPurple,
    colorPurpleDark,
    colorPurpleDarker,
    colorPurpleDarkest,
    colorPurpleBlack,
    colorFacebook,
    primaryTextColor,
    secondaryTextColor,
    accentTextColor,
    placeholderTextColor,
    primaryIconColor,
    primaryColor,
}

export default {
    ...theme,
    fonts: {
        body: 'Inter',
        heading: 'Inter',
    },
    fontSizes: {
        ...theme.fontSizes,
        sm: '14px',
        md: '16px',
        lg: '22px',
        xl: '36px',
        '2xl': '48px',
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 600,
    },
    colors: {
        ...theme.colors,
        ...customColors,

    },
    icons: {
        ...theme.icons,
        ...customIcons,
    },
}
