import React from 'react'
import {
    FormControl,
    FormLabel,
    NumberInputField,
    NumberInput,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/core'
import ErrorField from 'components/util/forms/ErrorField'

const FormInputNumberWithControls = ({
    id,
    label,
    placeholder,
    focusRef,
    value,
    onChange,
    error,
    ...props
}) => (
    <FormControl mt={2}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <NumberInput
            ref={focusRef}
            mt={1}
            id={id}
            value={value}
            onChange={onChange}
            {...props}
        >
            <NumberInputField
                bg="colorGrayWhite"
                focusBorderColor="colorPurple"
                placeholder={placeholder}
                type="number"
            />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
        <ErrorField errorMessage={error} />
    </FormControl>
)

export default FormInputNumberWithControls
