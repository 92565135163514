import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import ModalLoading from 'components/util/ModalLoading'

const LoggingInModal = () => (
    <Modal
        isCloseable={false}
        modalTitle={<Trans>Logging you in...</Trans>}
        modalContent={<ModalLoading />}
    />
)

export default LoggingInModal
