import React, { useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import useEditorState from 'components/hooks/useEditorState'
import CommentForm, { INITIAL_COMMENT_CONTENT } from './CommentForm'

const CommentModalWithState = ({
    onClose,
    commentPoint,
    asset,
}) => {
    const [commentContent, setCommentContent] = useState(typeof asset === 'undefined'
        ? INITIAL_COMMENT_CONTENT
        : asset.commentContent)
    const focusRef = useRef()
    const {
        placeComment,
        updateComment,
        togglePlacingComments,
    } = useEditorState()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Place a comment</Trans>}
            isCloseable={false}
            modalContent={(
                <CommentForm
                    comment={commentContent}
                    onChangeComment={setCommentContent}
                    focusRef={focusRef}
                    onSubmit={async () => {
                        if (typeof asset === 'undefined') {
                            await placeComment(commentPoint, commentContent)
                        } else {
                            await updateComment(asset, commentContent)
                        }
                        onClose()
                    }}
                    onCancel={() => {
                        if (typeof asset === 'undefined') {
                            togglePlacingComments()
                        }
                        onClose()
                    }}
                />
            )}
        />
    )
}

export default CommentModalWithState
