import React from 'react'
import { Box, Flex } from '@chakra-ui/core'

const PageContainer = ({ children }) => (
    <Flex
        w="full"
        px="5%"
        justify="center"
    >
        <Box
            w="full"
            maxW="6xl"
        >
            {children}
        </Box>
    </Flex>
)

export default PageContainer
