import React from 'react'
import { Text, Box } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { isEmpty } from 'validation/index'
import ModalLoading from 'components/util/ModalLoading'
import ChooseTemplateForm from './ChooseTemplateForm'

const ChooseTemplateModal = ({
    focusRef,
    isLoading,
    templates,
    onChoose,
}) => {
    if (isLoading) {
        return <ModalLoading />
    }
    if (isEmpty(templates)) {
        return (
            <Box
                mt={8}
                textAlign="center"
                color="secondaryTextColor"
            >
                <Text>
                    <Trans>
                        Sorry, there are no templates available right now.
                    </Trans>
                </Text>
                <Text>
                    <Trans>Please try again later.</Trans>
                </Text>
            </Box>
        )
    }

    return (
        <>
            <Text color="secondaryTextColor" mb={4}>
                <Trans>Choose one of the following templates.</Trans>
            </Text>
            <ChooseTemplateForm
                focusRef={focusRef}
                templates={templates}
                onChoose={onChoose}
            />
        </>
    )
}

export default ChooseTemplateModal
