import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const UPDATE_PASSWORD_MUTATION = gql`
    mutation updatePassword($slug: String!, $input: UpdatePasswordInput!) {
        updatePassword(input: $input) {
            id
            username
            email
            age
            sex
            isAnonymous
            isOnboardedForTrajectory(slug: $slug)
        }
    }
`

const useUpdatePasswordMutation = () => useMutation(UPDATE_PASSWORD_MUTATION)

export default useUpdatePasswordMutation
