import React from 'react'
import {
    Flex,
    Box,
    Heading,
    Text,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { isEmpty } from 'validation/index'
import MainButton from 'components/util/buttons/MainButton'
import ProfileDesignCard from './ProfileDesignCard'
import AuthModalWrapperWithState from '../auth/AuthModalWrapperWithState'

const ProfileDesignsList = ({
    designs,
    onContinueWithDesign,
    ...props
}) => {
    const isEmptyList = isEmpty(designs)

    /* eslint-disable array-callback-return */
    /* eslint-disable consistent-return */
    return (
        <Box
            textAlign={isEmptyList ? 'center' : 'left'}
            {...props}
        >
            <Heading
                as="h2"
                fontSize="lg"
                mt={12}
                mb={8}
            >
                <Trans>My ideas</Trans>
            </Heading>
            {isEmptyList
                ? (
                    <AuthModalWrapperWithState>
                        {({ onParticipate }) => (
                            <Box>
                                <Text
                                    mt={8}
                                    mb={4}
                                    color="secondaryTextColor"
                                >
                                    <Trans>You haven&apos;t given form to any ideas yet</Trans>
                                </Text>
                                <MainButton
                                    px={6}
                                    py={4}
                                    onClick={onParticipate}
                                >
                                    <Trans>Get started</Trans>
                                </MainButton>
                            </Box>
                        )}
                    </AuthModalWrapperWithState>
                )
                : (
                    <Flex wrap="wrap">
                        {designs.map((design) => (
                            <Box
                                key={design.id}
                                w="sm"
                                h="sm"
                            >
                                <ProfileDesignCard
                                    design={design}
                                    onContinueWithDesign={onContinueWithDesign}
                                />
                            </Box>
                        ))}
                    </Flex>
                )}
        </Box>
    )
}

export default ProfileDesignsList
