import { gql } from 'apollo-boost'
import DEFAULT_MEDIA_ITEM_FRAGMENT from './defaultMediaItem'
import DEFAULT_COORDINATE_FRAGMENT from './defaultCoordinate'

const DEFAULT_ASSET_FRAGMENT = gql`
    fragment defaultAsset on Asset {
        id
        name
        url
        isReference
        scaleModifier
        position {
            ...defaultCoordinate
        }
        rotation {
            ...defaultCoordinate
        }
        preview {
            ...defaultMediaItem
        }
    }
    ${DEFAULT_COORDINATE_FRAGMENT}
    ${DEFAULT_MEDIA_ITEM_FRAGMENT}
`

export default DEFAULT_ASSET_FRAGMENT
