import React from 'react'
import { Heading } from '@chakra-ui/core'
import AssetList from './AssetList'
import EditorMenuLoading from '../EditorMenuLoading'
import EditorContentMenuPageContainer from '../EditorContentMenuPageContainer'

const AssetMenu = ({
    isFetching,
    category,
    assets,
    goBackToSubcategoryMenu,
}) => (
    <EditorContentMenuPageContainer goBack={goBackToSubcategoryMenu}>
        <Heading
            as="h2"
            fontSize="lg"
            mb={6}
        >
            {category.name}
        </Heading>
        {isFetching && <EditorMenuLoading />}
        <AssetList assets={assets} />
    </EditorContentMenuPageContainer>
)

export default AssetMenu
