import React from 'react'
import {
    Image,
    Flex,
    Icon,
    Box,
    Spinner,
    Text,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'
import EditDesignForm from './EditDesignForm'

const EditDesignModalContent = ({
    focusRef,
    formState,
    isAdminEditor,
    designShouldBePublished,
    isDesignPublished,
    isUploadingScreenshot,
    isFormLoading,
    isSubmitting,
    onChangeFeaturedScreenshot,
    onSubmit,
    onDelete,
    onCancel,
}) => (
    <>
        {isUploadingScreenshot
            ? (
                <Flex py={8} justify="center">
                    <Box textAlign="center">
                        <Text mb={8}>
                            <Trans>Loading image...</Trans>
                        </Text>
                        <Spinner color="colorGrayDark" />
                    </Box>
                </Flex>
            )
            : (
                <>
                    <Image
                        w="full"
                        h={48}
                        src={formState.values.featuredScreenshot.urls.thumb}
                    />
                    <Flex w="full" mt={4}>
                        <Icon mr={1} name="screenshot" size="24px" stroke="primaryColor" fill="colorWhite" />
                        <Link linkTo={onChangeFeaturedScreenshot}>
                            <Trans>Choose a different preview</Trans>
                        </Link>
                    </Flex>
                </>
            )}
        <Box mt={4}>
            <EditDesignForm
                focusRef={focusRef}
                formState={formState}
                isAdminEditor={isAdminEditor}
                designShouldBePublished={designShouldBePublished}
                isDesignPublished={isDesignPublished}
                loading={isFormLoading}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                onDelete={onDelete}
                onCancel={onCancel}
            />
        </Box>
    </>
)

export default EditDesignModalContent
