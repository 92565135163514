import * as THREE from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { isLessOrEqualThan } from 'validation/index'
import {
    createCamera,
    createTranslateControls,
    getObjectScale,
    isImage,
} from './util/three'
import {
    ASSET_CONTROL_MODE_TRANSLATE,
    ASSET_CONTROL_MODE_ROTATE,
    ENV_2D as ENV,
    DEFAULT_CAMERA_POSITION_2D,
    ASSET_MIN_Y,
    ASSET_CONTROL_MODE_SCALE,
    ASSET_MIN_SCALE,
    IMAGE_MIN_SCALE,
} from './util/constants'
import EditorWithState from './EditorWithProvider'

class Editor2DWithState extends EditorWithState {
    constructor(props) {
        super(props)

        this.setAssetControlMode = this.setAssetControlMode.bind(this)

        this.state = {
            ...this.state,
            setAssetControlMode: this.setAssetControlMode,
        }
    }

    setupScene(container, backgroundColor) {
        const width = container.clientWidth
        const height = container.clientHeight
        const canvas = this.renderer.domElement

        let { cameraPosition } = this.design
        if (cameraPosition === null) {
            cameraPosition = DEFAULT_CAMERA_POSITION_2D
            cameraPosition.position.y = height + 150
        }
        this.camera = createCamera(width / height, cameraPosition.position)
        this.editorControls = createTranslateControls(this.camera, canvas, ENV, cameraPosition.target)

        const assetControls = new TransformControls(this.camera, this.renderer.domElement)
        assetControls.addEventListener('dragging-changed', (event) => {
            this.editorControls.enabled = !event.value
            const { object } = event.target
            if (typeof object !== 'undefined') {
                object.originalPosition = object.position.clone()
            }
        })
        assetControls.addEventListener('objectChange', (event) => {
            const { object } = event.target
            if (typeof object !== 'undefined') {
                // Stop assets from going below the surface
                if (this.assetControls.mode === ASSET_CONTROL_MODE_TRANSLATE) {
                    if (object.position.y <= ASSET_MIN_Y) {
                        object.position.y = ASSET_MIN_Y
                    }
                }
                // Stop assets from shrinking below the minimum scale
                if (this.assetControls.mode === ASSET_CONTROL_MODE_SCALE) {
                    let scale = getObjectScale(object.scale)
                    const minScale = isImage(object.fileType) ? IMAGE_MIN_SCALE : ASSET_MIN_SCALE
                    if (isLessOrEqualThan(minScale)(scale)) {
                        scale = minScale
                    }
                    object.scale.set(scale, scale, scale)
                }
                // Only move an asset vertically when it is dragged along the Y axis
                if (this.assetControls.axis !== 'Y' && typeof object.originalPosition !== 'undefined') {
                    object.position.y = object.originalPosition.y
                }
                this.renderEditor()
            }
        })
        this.assetControls = assetControls
        this.setAssetControlMode(ASSET_CONTROL_MODE_TRANSLATE)

        this.scene.background = new THREE.Color(backgroundColor)
        this.renderer.setSize(width, height)
        container.appendChild(canvas)

        this.editorControls.update()
        this.camera.updateProjectionMatrix()
    }

    setAssetControlMode(mode) {
        if (mode === ASSET_CONTROL_MODE_TRANSLATE) {
            this.assetControls.showX = true
            this.assetControls.showY = false
            this.assetControls.showZ = true
        }

        super.setAssetControlMode(mode)
    }
}

export default Editor2DWithState
