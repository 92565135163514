import React, { useState } from 'react'
import useEditorState from 'components/hooks/useEditorState'
import AssetControls from './AssetControls'

const AssetControlsWithState = ({
    isAssetEditor = false,
    onEditComment,
}) => {
    const [isScalingAsset, setIsScalingAsset] = useState(false)
    const {
        // AssetControls
        removeAsset,
        deselectAsset,
        selectedAsset,
        assetControlMode,
        setEditorIsWaiting,
        setAssetControlMode,
    } = useEditorState()

    if (selectedAsset === null) {
        return null
    }

    return (
        <AssetControls
            asset={selectedAsset}
            assetControlMode={assetControlMode}
            onChangeControlMode={setAssetControlMode}
            onEditComment={onEditComment}
            onRemoveAsset={removeAsset}
            onDeselectAsset={async () => {
                setEditorIsWaiting(true)
                setIsScalingAsset(false)
                await deselectAsset()
                setEditorIsWaiting(false)
            }}
            onScaleAsset={(value) => selectedAsset.scale.set(value, value, value)}
            isScalingAsset={isScalingAsset}
            onToggleScaleAsset={() => setIsScalingAsset(!isScalingAsset)}
            isAssetEditor={isAssetEditor}
        />
    )
}

export default AssetControlsWithState
