import React from 'react'
import { Flex, Box } from '@chakra-ui/core'
import DesignOverviewCard from 'components/util/home/DesignOverviewCard'
import { HOME_PAGE_SECTION_WIDTH as SECTION_WIDTH } from 'util/constants'

const HomeDesigns = ({ designs }) => (
    <Flex
        mt={16}
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
    >
        {designs.map((design) => (
            <Box
                key={design.id}
                w={SECTION_WIDTH}
                mb={10}
            >
                <DesignOverviewCard design={design} />
            </Box>
        ))}
    </Flex>
)

export default HomeDesigns
