import React, { useState } from 'react'
import {
    Flex,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    useTheme,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import SecondaryButton from './SecondaryButton'
import MainButton from './MainButton'

const ConfirmButton = ({
    onYes,
    confirmMessage,
    loading,
    placement = 'top',
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const theme = useTheme()

    return (
        <Popover
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            placement={placement}
        >
            <PopoverTrigger>
                {children}
            </PopoverTrigger>
            <PopoverContent
                zIndex="popover"
                bg="colorWhite"
                _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
            >
                <PopoverArrow />
                <PopoverBody>
                    {confirmMessage}
                </PopoverBody>
                <PopoverFooter>
                    <Flex justify="space-between">
                        <SecondaryButton
                            onClick={() => setIsOpen(false)}
                            disabled={loading}
                        >
                            <Trans>No</Trans>
                        </SecondaryButton>
                        <MainButton
                            onClick={onYes}
                            isLoading={loading}
                        >
                            <Trans>Yes</Trans>
                        </MainButton>
                    </Flex>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}

export default ConfirmButton
