import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import ModalLoading from 'components/util/ModalLoading'
import useFetchEditorDesignInfoModalData from './useFetchEditorDesignInfoModalData'
import EditorDesignInfoModalContent from './EditorDesignInfoModalContent'

const EditorDesignInfoModal = ({
    onClose,
    designId,
    slug,
    isReadOnly,
}) => {
    const {
        isFetching,
        design,
        trajectory,
    } = useFetchEditorDesignInfoModalData(slug, designId)

    return (
        <Modal
            onClose={onClose}
            modalTitle={<Trans>Useful information</Trans>}
            modalContent={isFetching
                ? <ModalLoading />
                : (
                    <EditorDesignInfoModalContent
                        design={design}
                        trajectory={trajectory}
                        isReadOnly={isReadOnly}
                    />
                )}
        />
    )
}

export default EditorDesignInfoModal
