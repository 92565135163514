import React from 'react'
import { Flex, Spinner } from '@chakra-ui/core'

const ModalLoading = () => (
    <Flex
        mt={8}
        w="full"
        justify="center"
    >
        <Spinner
            size="lg"
            color="secondaryTextColor"
        />
    </Flex>
)

export default ModalLoading
