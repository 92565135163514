import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const REQUEST_PASSWORD_RESET = gql`
    mutation requestPasswordReset($input: SendResetLinkEmailInput!) {
        sendResetLinkEmail(input: $input)
    }
`

const useRequestPasswordResetMutation = () => useMutation(REQUEST_PASSWORD_RESET)

export default useRequestPasswordResetMutation
