import React, { useRef } from 'react'
import { useToast } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import useAppState from 'components/hooks/useAppState'
import useUpdatePasswordForm from './useUpdatePasswordForm'
import UpdatePasswordForm from './UpdatePasswordForm'
import useUpdatePasswordMutation from './useUpdatePasswordMutation'

const UpdatePasswordModalWithState = ({
    onClose,
    slug,
}) => {
    const toast = useToast()
    const focusRef = useRef()
    const { updateCurrentUser } = useAppState()
    const formState = useUpdatePasswordForm()
    const [updatePassword, { loading: isUpdatingPassword }] = useUpdatePasswordMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Change password</Trans>}
            modalContent={(
                <UpdatePasswordForm
                    focusRef={focusRef}
                    formState={formState}
                    isUpdatingPassword={isUpdatingPassword}
                    onSubmit={async () => {
                        if (formState.validate()) {
                            try {
                                const input = formState.values
                                const { data } = await updatePassword({ variables: { input, slug } })
                                updateCurrentUser(data.updatePassword)
                                onClose()
                                toast({
                                    title: 'Gelukt!',
                                    description: 'Je wachtwoord is veranderd.',
                                    status: 'success',
                                    position: 'top',
                                })
                            } catch (e) {
                                formState.updateErrors(parseFormError(e, 'updatePassword'))
                            }
                        }
                    }}
                />
            )}
        />
    )
}

export default UpdatePasswordModalWithState
