import React, { useRef } from 'react'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import useAppState from 'components/hooks/useAppState'
import useRegisterAnonymousForm from './useRegisterAnonymousForm'
import RegisterAnonymousForm from './RegisterAnonymousForm'
import useRegisterAnonymousMutation from './useRegisterAnonymousMutation'

const RegisterAnonymousModalWithState = ({
    onClose,
    onCompleteRegisterAnonymous,
}) => {
    const focusRef = useRef()
    const { login, slug } = useAppState()
    const formState = useRegisterAnonymousForm(slug)
    const [registerAnonymous, { loading: isRegisteringAnonymous }] = useRegisterAnonymousMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Almost ready to start</Trans>}
            modalContent={(
                <RegisterAnonymousForm
                    focusRef={focusRef}
                    formState={formState}
                    isRegisteringAnonymous={isRegisteringAnonymous}
                    onSubmit={async () => {
                        if (formState.validate()) {
                            try {
                                const input = formState.values
                                const { data } = await registerAnonymous({ variables: { slug, input } })
                                const {
                                    accessToken,
                                    refreshToken,
                                    me,
                                } = data.createAnonymousUser
                                await login(accessToken, refreshToken, me)
                                onCompleteRegisterAnonymous()
                                onClose()
                            } catch (error) {
                                formState.updateErrors(parseFormError(error, 'createAnonymousUser'))
                            }
                        }
                    }}
                />
            )}
        />
    )
}

export default RegisterAnonymousModalWithState
