import React from 'react'
import { Text, Box, Icon } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'

const ConfirmEmailBox = ({ email }) => (
    <>
        <Box textAlign="center">
            <Icon
                name="email"
                size="72px"
                stroke="primaryIconColor"
                fill="none"
            />
        </Box>
        <Text mt={8} color="secondaryTextColor">
            <Trans>We&apos;ve sent a confirmation email to</Trans>
            {' '}
            {email}
            .
            {' '}
            <Trans>Please check your inbox and confirm your email address.</Trans>
        </Text>
    </>
)

export default ConfirmEmailBox
