import * as Sentry from '@sentry/browser'

export default function initializeSentry() {
    const {
        REACT_APP_ENV,
        REACT_APP_SENTRY_DSN,
    } = process.env

    if (REACT_APP_ENV !== 'development') {
        Sentry.init({
            dsn: REACT_APP_SENTRY_DSN,
            environment: REACT_APP_ENV,
        })
    }
}
