import React from 'react'
import {
    Box,
    Button,
    Flex,
    Icon,
} from '@chakra-ui/core'

const FacebookButton = ({
    focusRef,
    loading,
    children,
    ...props
}) => (
    <Box>
        <Button
            ref={focusRef}
            h="full"
            rounded="lg"
            bg="colorFacebook"
            color="colorWhite"
            fontWeight="bold"
            px={6}
            py={3}
            _hover="none"
            _focus="none"
            isLoading={loading}
            {...props}
        >
            <Flex>
                <Icon
                    name="facebook"
                    size="20px"
                    fill="colorWhite"
                    mr={4}
                />
                {children}
            </Flex>
        </Button>
    </Box>
)

export default FacebookButton
