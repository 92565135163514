import React from 'react'
import {
    Flex,
    Button,
    Stack,
    useTheme,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { LOGGED_IN_HEADER_PROFILE, LOGGED_IN_HEADER_DESIGNS, LOGGED_IN_HEADER_TRAJECTORY } from 'util/constants'
import Image from 'components/util/Image'
import { home, designs } from 'routes/index'
import GetStartedButton from 'components/util/buttons/GetStartedButton'
import AuthModalWrapperWithState from 'components/pages/auth/AuthModalWrapperWithState'
import usePushHistory from 'components/hooks/usePushHistory'
import UserMenu from '../util/UserMenu'

const PageHeader = ({ page }) => {
    const theme = useTheme()
    const pushRouteWithSlug = usePushHistory()

    return (
        <AuthModalWrapperWithState>
            {({
                onParticipate,
                isAuthenticated,
                isOnboardedForTrajectory,
            }) => (
                <Flex
                    mt={8}
                    justify="space-between"
                >
                    <Stack justify="center">
                        <Button
                            variant="unstyled"
                            onClick={() => pushRouteWithSlug(home)}
                        >
                            <Image src="logos/logo.png" />
                        </Button>
                    </Stack>
                    <Flex>
                        <Button
                            variant="link"
                            color={page === LOGGED_IN_HEADER_TRAJECTORY ? 'primaryTextColor' : 'secondaryTextColor'}
                            fontWeight="bold"
                            onClick={() => pushRouteWithSlug(home)}
                            _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
                        >
                            <Trans>Trajectory</Trans>
                        </Button>
                        <Button
                            ml={12}
                            variant="link"
                            color={page === LOGGED_IN_HEADER_DESIGNS ? 'primaryTextColor' : 'secondaryTextColor'}
                            fontWeight="bold"
                            onClick={() => pushRouteWithSlug(designs)}
                            _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
                        >
                            <Trans>Ideas</Trans>
                        </Button>
                    </Flex>
                    {isAuthenticated
                        ? <UserMenu isFocussed={page === LOGGED_IN_HEADER_PROFILE} />
                        : (
                            <Stack justify="center">
                                <GetStartedButton
                                    size="sm"
                                    onClick={onParticipate}
                                    isAuthenticated={isAuthenticated}
                                    isOnboardedForTrajectory={isOnboardedForTrajectory}
                                />
                            </Stack>
                        )}
                </Flex>
            )}
        </AuthModalWrapperWithState>
    )
}

export default PageHeader
