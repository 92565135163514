import React from 'react'
import {
    Button,
    Flex,
    Icon,
    useTheme,
} from '@chakra-ui/core'

const EditorTopbarButton = ({
    text,
    icon,
    onClick,
    ...props
}) => {
    const theme = useTheme()

    return (
        <Button
            size="sm"
            variant="unstyled"
            onClick={onClick}
            ml={5}
            _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
            {...props}
        >
            <Flex color="colorGrayDark">
                <Icon name={icon} mr={1} size="24px" stroke="colorGrayLight" fill="colorWhite" />
                {text}
            </Flex>
        </Button>
    )
}

export default EditorTopbarButton
