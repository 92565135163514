import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/forms/createFormValidation'
import {
    isValidPostalCode,
    isValidAge,
    isValidUsername,
    isValidEmail,
} from 'validation/user'

const userToUpdateUserFormValues = (user) => ({
    username: user.username,
    email: user.email,
    postalCode: user.postalCode,
    sex: user.sex,
    age: user.age,
})

const validation = createFormValidation([{
    path: 'username',
    validate: (username) => isValidUsername(username),
    message: <Trans key="username">Please fill in a valid username.</Trans>,
}, {
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: <Trans key="email">Please fill in a valid email.</Trans>,
}, {
    path: 'postalCode',
    validate: (postalCode) => isValidPostalCode(postalCode),
    message: <Trans key="postalCode">Please fill in a postal code.</Trans>,
}, {
    path: 'age',
    validate: (age) => isValidAge(age),
    message: <Trans key="age">Please fill in your age</Trans>,
}])

const useUpdateUserForm = (user, options) => (
    useFormState(
        userToUpdateUserFormValues(user),
        {
            validation,
            ...options,
        },
    )
)

export default useUpdateUserForm
