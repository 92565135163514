import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'
import DEFAULT_ASSET_FRAGMENT from 'apollo/fragments/defaultAsset'

export const SEARCH_ASSETS_QUERY = gql`
    query searchAssets($query: String!) {
        searchAssetsByTag(query: $query) {
            ...defaultAsset
        }
    }
    ${DEFAULT_ASSET_FRAGMENT}
`

const useSearchAssetsLazyQuery = () => useLazyQuery(SEARCH_ASSETS_QUERY)

export default useSearchAssetsLazyQuery
