import * as THREE from 'three'

export default class Drawer {
    constructor({
        camera,
        scene,
        container,
        drawingPlane,
        apolloClient,
        designId,
        toast,
    }) {
        this.camera = camera
        this.scene = scene
        this.container = container
        this.drawingPlane = drawingPlane
        this.apolloClient = apolloClient
        this.designId = designId
        this.toast = toast
        this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff })
        this.lastPoint = null
        this.linePieces = []
        this.lines = []

        this.setupDrawer = this.setupDrawer.bind(this)
        this.cleanupDrawer = this.cleanupDrawer.bind(this)
    }

    setupDrawer() {
        this.container.addEventListener('mousedown', this.startDraw, false)
        this.container.addEventListener('mouseup', this.stopDraw, false)
        this.container.addEventListener('mousemove', this.doDraw, false)

        this.container.addEventListener('touchstart', this.startDraw, false)
        this.container.addEventListener('touchend', this.stopDraw, false)
        this.container.addEventListener('touchmove', this.doDraw, false)
    }

    cleanupDrawer() {
        this.container.removeEventListener('mousedown', this.startDraw, false)
        this.container.removeEventListener('mouseup', this.stopDraw, false)
        this.container.removeEventListener('mousemove', this.doDraw, false)

        this.container.removeEventListener('touchstart', this.startDraw, false)
        this.container.removeEventListener('touchend', this.stopDraw, false)
        this.container.removeEventListener('touchmove', this.doDraw, false)

        return this.lines
    }
}
