import React, { useState } from 'react'
import useEditorState from 'components/hooks/useEditorState'
import { DESIGN_STATUS_PUBLISHED } from 'util/constants'
import AdminEditorTopbar from './AdminEditorTopbar'

const AdminEditorTopbarWithState = ({
    design,
    onOpenEditModal,
    onOpenShotsModal,
}) => {
    const { status, scaleModifier: designScaleModifier } = design
    const { changeScaleModifier } = useEditorState()
    const [scaleModifier, setScaleModifier] = useState(designScaleModifier)
    const designIsPublished = status === DESIGN_STATUS_PUBLISHED

    return (
        <AdminEditorTopbar
            designIsPublished={designIsPublished}
            scaleModifier={scaleModifier}
            onSaveTemplate={() => onOpenEditModal({ designShouldBePublished: false })}
            onOpenShotsModal={onOpenShotsModal}
            onChangeScaleModifier={(modifier) => {
                if (modifier > 0) {
                    changeScaleModifier(modifier)
                    setScaleModifier(modifier)
                }
            }}
            onPublishTemplate={() => onOpenEditModal({ designShouldBePublished: true })}
            onCloseEditor={() => {
                const { REACT_APP_BACKEND_URL } = process.env
                window.location.href = `${REACT_APP_BACKEND_URL}/resources/designs`
            }}
        />
    )
}

export default AdminEditorTopbarWithState
