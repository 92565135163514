export const EDITOR_CONTROL_MODE_TRANSLATE = 'translate'
export const EDITOR_CONTROL_MODE_ORBIT = 'orbit'

export const ASSET_CONTROL_MODE_TRANSLATE = 'translate'
export const ASSET_CONTROL_MODE_ROTATE = 'rotate'
export const ASSET_CONTROL_MODE_SCALE = 'scale'

export const DRAW_MODE_NONE = 'none'
export const DRAW_MODE_CURVED = 'curved'
export const DRAW_MODE_STRAIGHT = 'straight'

export const TEMPLATE_WIDTH = 100
export const TEMPLATE_HEIGHT = 100

export const ENV_2D = '2d'
export const ENV_3D = '3d'

export const ASSET_TYPE_COMMENT = 'comment'
export const ASSET_TYPE_VECTOR = 'vector'

export const ASSET_MIN_Y = 0.1
export const ASSET_MIN_SCALE = 0.1
export const IMAGE_MIN_SCALE = 0.01

export const DEFAULT_CAMERA_POSITION_2D = {
    position: {
        x: 0,
        y: 0,
        z: 0,
    },
    target: {
        x: 0,
        y: 0,
        z: 0,
    },
}

export const DEFAULT_CAMERA_POSITION_3D = {
    position: {
        x: 0,
        y: 0,
        z: 50,
    },
    target: {
        x: 0,
        y: 0,
        z: 0,
    },
}

export const IMAGE_EXTENSIONS = [
    'PNG',
    'JPG',
    'JPEG',
]

export const ASSET_TYPE_GLTF = 'GLTF'
export const ASSET_TYPE_COLLADA = 'DAE'

export const ZOOM_IN = 'IN'
export const ZOOM_OUT = 'OUT'
export const ZOOM_STEP = 15

export const SCALE_UP = 'UP'
export const SCALE_DOWN = 'DOWN'
export const SCALE_STEP = 0.15
