import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const DELETE_DESIGN_MUTATION = gql`
    mutation deleteDesign($id: Int!) {
        deleteDesign(id: $id) {
            id
        }
    }
`

const useDeleteDesignMutation = () => useMutation(DELETE_DESIGN_MUTATION)

export default useDeleteDesignMutation
