import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const LOGIN_WITH_EMAIL_MUTATION = gql`
    mutation loginWithEmail($slug: String!, $input: LoginInput!) {
        login(input: $input) {
            accessToken
            refreshToken
            me {
                id
                username
                email
                age
                sex
                isAnonymous
                isOnboardedForTrajectory(slug: $slug)
            }
        }
    }
`

const useLoginWithEmailMutation = () => useMutation(LOGIN_WITH_EMAIL_MUTATION)

export default useLoginWithEmailMutation
