import React, { useRef } from 'react'
import { Trans } from '@lingui/macro'
import useAppState from 'components/hooks/useAppState'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import useOnboardUserMutation from 'components/pages/onboarding/useOnboardUserMutation'
import useOnboardingForm from './useOnboardingForm'
import OnboardingForm from './OnboardingForm'

const OnboardingModalWithState = ({ onClose }) => {
    const focusRef = useRef()
    const {
        currentUser,
        updateCurrentUser,
        slug,
    } = useAppState()
    const formState = useOnboardingForm({ slug, currentUser })
    const [onboardUser, { loading: isOnboardingUser }] = useOnboardUserMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Welcome back!</Trans>}
            modalContent={(
                <OnboardingForm
                    focusRef={focusRef}
                    formState={formState}
                    isOnboarding={isOnboardingUser}
                    onSubmit={async () => {
                        if (formState.validate()) {
                            try {
                                const input = formState.values
                                const { data } = await onboardUser({ variables: { slug, input } })
                                updateCurrentUser(data.onboardUser)
                                onClose()
                            } catch (e) {
                                formState.updateErrors(parseFormError(e, 'onboardUser'))
                            }
                        }
                    }}
                />
            )}
        />
    )
}

export default OnboardingModalWithState
