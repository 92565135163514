import React from 'react'
import { Trans } from '@lingui/macro'
import useEditorState from 'components/hooks/useEditorState'
import { DESIGN_STATUS_PUBLISHED } from 'util/constants'
import EditorTopbarButton from './EditorTopbarButton'

const EditorTopbarButtons = ({
    isDesignPublished,
    onOpenEditModal,
    onOpenShotsModal,
    onOpenShareModal,
    onOpenEditorDesignInfoModal,
}) => {
    const { design } = useEditorState()
    const canShare = design.status === DESIGN_STATUS_PUBLISHED
    return (
        <>
            <EditorTopbarButton
                text={<Trans>Info</Trans>}
                icon="info"
                onClick={onOpenEditorDesignInfoModal}
            />
            <EditorTopbarButton
                text={isDesignPublished
                    ? <Trans>Save</Trans>
                    : <Trans>Save for now</Trans>}
                icon="edit"
                onClick={onOpenEditModal}
            />
            <EditorTopbarButton
                text={<Trans>Your screenshots</Trans>}
                icon="screenshot"
                onClick={onOpenShotsModal}
            />
            {canShare && (
                <EditorTopbarButton
                    text={<Trans>Share</Trans>}
                    icon="share"
                    onClick={onOpenShareModal}
                />
            )}
        </>
    )
}

export default EditorTopbarButtons
