import React from 'react'
import { I18nProvider } from '@lingui/react'
import en from '../../locales/en/messages'
import nl from '../../locales/nl/messages'

const EN = 'en'
const NL = 'nl'
const FR = 'fr'

const LanguageProvider = ({
    languageCode = NL,
    children,
}) => (
    <I18nProvider
        language={languageCode}
        catalogs={{ en, nl }}
        missing="🚨 No translation found. 🚨"
    >
        {children}
    </I18nProvider>
)

LanguageProvider.EN = EN
LanguageProvider.NL = NL
LanguageProvider.FR = FR

export default LanguageProvider
