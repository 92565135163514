import * as Sentry from '@sentry/browser'

export const scopeSentryUser = (user) => {
    const {
        id,
        email,
        username,
    } = user
    Sentry.configureScope((scope) => {
        scope.setUser({ id, email, username })
    })
}

export const removeScopedSentryUser = () => {
    Sentry.configureScope((scope) => {
        scope.setUser({ })
    })
}
