import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const VERIFY_EMAIL_MUTATION = gql`
    mutation verifyEmail($slug: String!, $input: VerifyEmailInput!) {
        verifyEmail(input: $input) {
            accessToken
            refreshToken
            me {
                id
                username
                email
                age
                sex
                isAnonymous
                isOnboardedForTrajectory(slug: $slug)
            }
        }
    }
`

const useVerifyEmailMutation = () => useMutation(VERIFY_EMAIL_MUTATION)

export default useVerifyEmailMutation
