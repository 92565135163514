import React, { useEffect, useRef } from 'react'
import { Accordion, Heading } from '@chakra-ui/core'
import PageContainer from 'components/layout/PageContainer'
import PageHeader from 'components/layout/PageHeader'
import { LOGGED_IN_HEADER_DESIGNS } from 'util/constants'
import { getPhaseStatus } from 'util/phase'
import PhaseAccordionItem from './PhaseAccordionItem'
import Footer from '../../layout/Footer'

const DesignsPage = ({
    trajectory,
    onViewDesign,
    onLikeDesignComplete,
}) => {
    const scrollRef = useRef(null)
    const {
        title,
        phases,
    } = trajectory

    useEffect(() => {
        if (scrollRef.current !== null) {
            window.scrollTo(0, scrollRef.current.offsetTop)
        }
    }, [])

    return (
        <PageContainer>
            <PageHeader page={LOGGED_IN_HEADER_DESIGNS} />
            <Heading
                as="h1"
                fontSize="xl"
                my={16}
            >
                {title}
            </Heading>
            <Accordion mt={8} allowMultiple={false}>
                {phases.map((phase, index) => {
                    const { startDate, endDate } = phase
                    const {
                        isCurrent,
                        hasEnded,
                    } = getPhaseStatus(startDate, endDate)

                    return (
                        <PhaseAccordionItem
                            scrollRef={scrollRef}
                            key={phase.id}
                            phaseIndex={index}
                            phase={{
                                ...phase,
                                order: index + 1,
                            }}
                            isCurrent={isCurrent}
                            hasEnded={hasEnded}
                            onViewDesign={onViewDesign}
                            onLikeDesignComplete={onLikeDesignComplete}
                        />
                    )
                })}
            </Accordion>
            <Footer />
        </PageContainer>
    )
}

export default DesignsPage
