import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const REGISTER_MUTATION = gql`
    mutation register($input: RegisterInput!) {
        register(input: $input)
    }
`

const useRegisterMutation = () => useMutation(REGISTER_MUTATION)

export default useRegisterMutation
