import React from 'react'
import { useTheme } from '@chakra-ui/core'
import AssetViewerPage from './AssetViewerPage'
import useFetchAssetViewerPageData from './useFetchAssetViewerPageData'

const AssetViewerPageWithState = ({
    id,
    initializePreview,
    cleanupPreview,
}) => {
    const theme = useTheme()
    const {
        isFetching,
        asset,
    } = useFetchAssetViewerPageData(id)

    if (isFetching) {
        return null
    }

    return (
        <AssetViewerPage
            initializePreview={(container) => initializePreview(container, asset, theme.colors.colorGrayLight)}
            cleanupPreview={cleanupPreview}
        />
    )
}

export default AssetViewerPageWithState
