import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const HOME_PAGE_QUERY = gql`
    query homePageQuery($slug: String!, $isTemplate: Boolean, $limit: Int) {
        trajectory(slug: $slug){
            title
            slug
            featured {
                id
                urls
            }
            description
            startDate
            endDate
            address
            city
            postalCode
            news {
                id
                title
                article
                publishedAt
            }
            phases {
                id
                title
                description
                startDate
                endDate
            }
            photos {
                id
                urls
            }
            milestones {
                id
                title
                date
            }
        }
        currentPhaseForTrajectory(trajectory: $slug) {
            designs(isTemplate: $isTemplate, limit: $limit) {
                id
                name
                description
                featuredScreenshot {
                    id
                    urls
                }
                surface {
                    id
                    urls
                }
                user {
                    id
                    username
                }
            }
        }
    }
`

const useFetchHomePageData = (slug) => {
    const variables = {
        slug,
        isTemplate: false,
        limit: 4,
    }
    const {
        error,
        loading,
        data,
    } = useQuery(HOME_PAGE_QUERY, { variables })

    if (error) {
        return ({
            error,
        })
    }

    if (loading) {
        return ({
            isFetching: true,
            trajectory: {},
        })
    }

    let designs = []
    const { trajectory } = data
    if (data.currentPhaseForTrajectory !== null) {
        designs = data.currentPhaseForTrajectory.designs
    }
    trajectory.designs = designs

    return ({
        error,
        isFetching: false,
        trajectory,
    })
}

export default useFetchHomePageData
