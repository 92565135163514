import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import ModalLoading from 'components/util/ModalLoading'
import EditorShotsModalContent from './EditorShotsModalContent'
import useFetchEditorShotsModalData from './useFetchEditorShotsModalData'


const EditorShotsModalWithState = ({
    onClose,
    designId,
    onOpenShot,
    setScreenshot = null,
}) => {
    const {
        isFetching,
        screenshots,
    } = useFetchEditorShotsModalData(designId)

    return (
        <Modal
            onClose={onClose}
            modalTitle={<Trans>Shots</Trans>}
            modalContent={isFetching
                ? <ModalLoading />
                : (
                    <EditorShotsModalContent
                        screenshots={screenshots}
                        onClickShot={(shot) => {
                            if (setScreenshot !== null) {
                                setScreenshot(shot)
                                onClose()
                            } else {
                                onOpenShot(shot.urls.medium, shot.id, false)
                            }
                        }}
                    />
                )}
            px={2}
            py={6}
        />
    )
}

export default EditorShotsModalWithState
