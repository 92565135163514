import React from 'react'
import { Text } from '@chakra-ui/core'
import { isProduction, isStaging } from 'util/index'

const EnvironmentBar = () => {
    if (isProduction()) {
        return null
    }

    return (
        <Text
            w="full"
            bg={isStaging() ? '#2f855a' : '#2b6cb0'}
            px={['5%', '10%']}
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            color="colorWhite"
        >
            {isStaging() ? 'Testing Environment' : 'Development Environment'}
        </Text>
    )
}

export default EnvironmentBar
