import React from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader'
import AssetViewerPageWithState from 'components/pages/asset-viewer/AssetViewerPageWithState'
import { ASSET_TYPE_GLTF, ASSET_TYPE_COLLADA } from './util/constants'

class AssetViewer extends React.Component {
    constructor(props) {
        super(props)

        this.scene = new THREE.Scene()
        this.camera = null
        this.controls = null
        this.renderer = new THREE.WebGLRenderer({ antialias: true })
        this.gltfLoader = new GLTFLoader()
        this.colladaLoader = new ColladaLoader()
        this.initializePreview = this.initializePreview.bind(this)
        this.cleanupPreview = this.cleanupPreview.bind(this)
    }

    onWindowResize() {
        const width = this.renderer.domElement.parentElement.clientWidth
        const height = this.renderer.domElement.parentElement.clientHeight

        this.renderer.setSize(width, height)
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
        this.renderEditor()
    }

    setEventListeners() {
        window.addEventListener('resize', () => this.onWindowResize())
    }

    /* eslint-disable consistent-return */
    getLoader(fileType) {
        if (fileType === ASSET_TYPE_GLTF) {
            return this.gltfLoader
        }
        if (fileType === ASSET_TYPE_COLLADA) {
            return this.colladaLoader
        }
    }

    removeEventListeners() {
        window.removeEventListener('resize', () => this.onWindowResize())
    }

    configureAsset(object) {
        const displayObject = object.clone()
        const box = new THREE.Box3().setFromObject(displayObject)
        const size = box.getSize(new THREE.Vector3()).length()
        const center = box.getCenter(new THREE.Vector3())

        displayObject.position.x += (displayObject.position.x - center.x)
        displayObject.position.y += (displayObject.position.y - center.y)
        displayObject.position.z += (displayObject.position.z - center.z)
        this.controls.maxDistance = size * 10
        this.camera.near = size / 100
        this.camera.far = size * 100

        this.camera.position.copy(center)
        this.camera.position.x += size / 2.0
        this.camera.position.y += size / 5.0
        this.camera.position.z += size / 2.0
        this.camera.lookAt(center)

        this.controls.saveState()
        this.controls.update()
        this.camera.updateProjectionMatrix()

        return displayObject
    }

    addLights() {
        const light1 = new THREE.AmbientLight(0xFFFFFF, 0.3)
        light1.name = 'ambient_light'
        this.camera.add(light1)

        const light2 = new THREE.DirectionalLight(0xFFFFFF, 0.8 * Math.PI)
        light2.position.set(0.5, 0, 0.866) // ~60º
        light2.name = 'main_light'
        this.camera.add(light2)
    }

    async loadAsset(url) {
        const fileType = url.split('.').pop().toUpperCase()
        const loader = this.getLoader(fileType)
        const loadedAsset = await new Promise((resolve, reject) => loader.load(url, resolve, null, reject))

        return loadedAsset.scene
    }

    async initializePreview(container, asset, backgroundColor) {
        const width = container.clientWidth
        const height = container.clientHeight
        const canvas = this.renderer.domElement

        this.camera = new THREE.PerspectiveCamera(60, width / height, 0.01, 1000)
        this.scene.add(this.camera)
        this.controls = new OrbitControls(this.camera, canvas)
        this.controls.enabled = false

        const loadedAsset = await this.loadAsset(asset.url)
        const displayAsset = this.configureAsset(loadedAsset)

        this.addLights()

        this.scene.background = new THREE.Color(backgroundColor)
        this.renderer.setSize(width, height)
        container.appendChild(canvas)
        this.scene.add(displayAsset)

        this.setEventListeners()
        this.renderEditor()
    }

    cleanupPreview() {
        this.scene.dispose()
        this.removeEventListeners()
    }

    renderEditor() {
        this.renderer.render(this.scene, this.camera)
    }

    render() {
        const { match: { params: { id } } } = this.props

        return (
            <AssetViewerPageWithState
                id={id}
                initializePreview={this.initializePreview}
                cleanupPreview={this.cleanupPreview}
            />
        )
    }
}

export default AssetViewer
