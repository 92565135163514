import React from 'react'
import { Helmet } from 'react-helmet'
import {
    Image,
    Flex,
    Icon,
    Box,
    Text,
    Spinner,
    Button,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'
import FacebookButton from 'components/util/buttons/FacebookButton'

const EditorShareModalContent = ({
    focusRef,
    isReadOnly,
    isUploadingScreenshot,
    shareImage,
    onChangeSharedImage,
    onShare,
    onDownloadImage,
}) => (
    <>
        <Flex mb={2}>
            <Text>
                <Trans>Image</Trans>
            </Text>
            <Button
                w="24px"
                h="24px"
                ml={2}
                variant="unstyled"
                onClick={onDownloadImage}
            >
                <Icon name="download" size="24px" fill="none" stroke="primaryIconColor" />
            </Button>
        </Flex>
        {isUploadingScreenshot || typeof shareImage === 'undefined'
            ? (
                <Flex py={8} justify="center">
                    <Box textAlign="center">
                        <Text mb={8}>
                            <Trans>Loading image...</Trans>
                        </Text>
                        <Spinner color="colorGrayDark" />
                    </Box>
                </Flex>
            )
            : (
                <>
                    <Helmet>
                        <meta property="og:image" content={shareImage.urls.medium} />
                    </Helmet>
                    <Image
                        w="full"
                        h={48}
                        src={shareImage.urls.thumb}
                    />
                    {!isReadOnly && (
                        <Flex mt={1}>
                            <Icon mr={1} name="screenshot" size="24px" stroke="primaryColor" fill="colorWhite" />
                            <Link linkTo={onChangeSharedImage}>
                                <Trans>Choose a different image</Trans>
                            </Link>
                        </Flex>
                    )}
                </>
            )}
        <Flex justify="center">
            <Box>
                <FacebookButton
                    focusRef={focusRef}
                    mt={8}
                    onClick={onShare}
                    loading={isUploadingScreenshot}
                >
                    <Trans>Share to Facebook</Trans>
                </FacebookButton>
            </Box>
        </Flex>
    </>
)

export default EditorShareModalContent
