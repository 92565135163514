import React, { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/core'
import PageError from 'components/layout/PageError'
import PageLoading from 'components/layout/PageLoading'
import PageNotFound from 'components/layout/PageNotFound'
import useModalState from 'components/hooks/useModalState'
import useEditorState from 'components/hooks/useEditorState'
import AssetEditorPage from './AssetEditorPage'
import AssetEditorInfoModal from './AssetEditorInfoModal'
import AssetEditorPreviewModal from './AssetEditorPreviewModal'
import useFetchAssetEditorPageData from './useFetchAssetEditorPageData'
import useUpdateAssetStateMutation from './useUpdateAssetStateMutation'

const AssetEditorPageWithState = ({
    assetId,
}) => {
    const toast = useToast()
    const previewModalState = useModalState()
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
    const {
        addAssets,
        getAssetState,
        takeScreenshot,
        editorIsLoading,
    } = useEditorState()
    const {
        error,
        isFetching,
        asset,
        referenceAsset,
    } = useFetchAssetEditorPageData(assetId)
    const [updateAsset, { loading: isUpdatingAsset }] = useUpdateAssetStateMutation()

    useEffect(() => {
        setIsInfoModalOpen(true)
    }, [])

    if (typeof assetId === 'undefined') {
        return <PageNotFound />
    }
    if (isFetching) {
        return <PageLoading />
    }
    if (typeof error !== 'undefined') {
        return <PageError error={error} />
    }

    const handleUpdateAsset = async (input) => {
        try {
            const variables = {
                id: asset.id,
                input: {
                    position: asset.position,
                    rotation: asset.rotation,
                    ...input,
                },
            }
            await updateAsset({ variables })
            toast({
                title: 'Gelukt!',
                description: 'Het object werd geüpdate',
                status: 'success',
                position: 'top',
            })
        } catch (e) {
            toast({
                title: 'Er liep iets mis...',
                description: 'We konden het asset niet updaten. Probeer het later opnieuw.',
                status: 'error',
                position: 'top',
            })
        }
    }

    return (
        <>
            <AssetEditorPage
                asset={asset}
                onEditorIsLoaded={() => addAssets(asset, referenceAsset)}
                onOpenInfoModal={() => setIsInfoModalOpen(true)}
                onTakePreviewPicture={() => {
                    const dataURL = takeScreenshot()
                    previewModalState.open({ dataURL })
                }}
                editorIsLoading={editorIsLoading}
                isUpdatingAsset={isUpdatingAsset}
                onSaveAndExit={async () => {
                    await handleUpdateAsset(getAssetState())
                    const { REACT_APP_BACKEND_URL } = process.env
                    window.location.href = `${REACT_APP_BACKEND_URL}/resources/assets/${asset.id}`
                }}
            />
            {isInfoModalOpen && (
                <AssetEditorInfoModal
                    onClose={() => setIsInfoModalOpen(false)}
                    assetName={asset.name}
                />
            )}
            {previewModalState.isOpen && (
                <AssetEditorPreviewModal
                    onClose={previewModalState.close}
                    isUploadingPreview={isUpdatingAsset}
                    onUploadPreview={async (dataUrl) => {
                        await handleUpdateAsset({ preview: dataUrl })
                        previewModalState.close()
                    }}
                    {...previewModalState.initialState}
                />
            )}
        </>
    )
}

export default AssetEditorPageWithState
