import React from 'react'
import { Text, Box, Icon } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import RequestPasswordResetForm from './RequestPasswordResetForm'

const RequestPasswordResetModal = ({
    focusRef,
    resetEmail,
    formState,
    isRequestingPasswordReset,
    onSubmit,
}) => {
    if (resetEmail !== null) {
        return (
            <>
                <Box textAlign="center">
                    <Icon
                        name="email"
                        size="72px"
                        stroke="primaryIconColor"
                        fill="none"
                    />
                </Box>
                <Text mt={8} color="secondaryTextColor">
                    <Trans>We&apos;ve sent a an email to</Trans>
                    {' '}
                    {resetEmail}
                    .
                    {' '}
                    <Trans>Please check your inbox to reset your password.</Trans>
                </Text>
            </>
        )
    }
    return (
        <>
            <Text
                mb={4}
                color="secondaryTextColor"
            >
                <Trans>Fill in your e-mail address and we will send you a link to reset your password.</Trans>
            </Text>
            <RequestPasswordResetForm
                focusRef={focusRef}
                formState={formState}
                isRequestingPasswordReset={isRequestingPasswordReset}
                onSubmit={onSubmit}
            />
        </>

    )
}

export default RequestPasswordResetModal
