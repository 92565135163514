import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { isEmpty } from 'validation/index'
import EditorContentMenuSearch from '../EditorContentMenuSearch'
import CategoryList from './CategoryList'
import EditorMenuLoading from '../EditorMenuLoading'
import CategoryMenuContainer from './CategoryMenuContainer'
import AssetList from '../asset-menu/AssetList'

const CategoryMenu = ({
    isCollapsed,
    onToggleCollapse,
    isFetching,
    categories,
    onChangeCategory,
    searchTerm,
    debouncedSearchTerm,
    onChangeSearchTerm,
    searchResult,
    isSearching,
}) => (
    <CategoryMenuContainer
        isCollapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
    >
        <Heading
            as="h2"
            fontSize="lg"
            mb={6}
        >
            {debouncedSearchTerm === ''
                ? <Trans>Categories</Trans>
                : <Trans>Search results</Trans>}
        </Heading>
        <Box mb={6}>
            <EditorContentMenuSearch
                searchTerm={searchTerm}
                onChangeSearchTerm={onChangeSearchTerm}
            />
        </Box>
        {isFetching && <EditorMenuLoading />}
        {(() => {
            if (debouncedSearchTerm === '') {
                return (
                    <CategoryList
                        categories={categories}
                        onChangeCategory={onChangeCategory}
                    />
                )
            }
            if (isSearching) {
                return (
                    <Box
                        my={8}
                        textAlign="center"
                        color="secondaryTextColor"
                    >
                        <Text>
                            <Trans>Searching...</Trans>
                        </Text>
                    </Box>
                )
            }
            if (isEmpty(searchResult)) {
                return (
                    <Box
                        my={8}
                        textAlign="center"
                        color="secondaryTextColor"
                    >
                        <Text>
                            <Trans>No result</Trans>
                        </Text>
                    </Box>
                )
            }
            return (
                <AssetList assets={searchResult} />
            )
        })()}
    </CategoryMenuContainer>
)

export default CategoryMenu
