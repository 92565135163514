import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import AssetViewer from 'editor/AssetViewer'
import ResetPasswordPageWithState from 'components/pages/reset-password/ResetPasswordPageWithState'
import * as routes from '../../routes/index'
import DesignsPageWithState from '../pages/designs/DesignsPageWithState'
import OnboardingPageWithState from '../pages/onboarding/OnboardingPageWithState'
import useUserStatus from '../hooks/useUserStatus'
import PageNotFound from '../layout/PageNotFound'
import AdminEditorPageWithState from '../pages/admin-editor/AdminEditorPageWithState'
import LoginFacebookPageWithState from '../pages/login-facebook/LoginFacebookPageWithState'
import HomePageWithState from '../pages/home/HomePageWithState'
import EditorPageWithState from '../pages/editor/EditorPageWithState'
import ProfilePageWithState from '../pages/profile/ProfilePageWithState'
import AssetEditorAuthPage from '../pages/asset-editor/AssetEditorAuthPage'

const AppRouter = ({ history }) => {
    const {
        isAuthenticated,
        isOnboardedForTrajectory,
    } = useUserStatus()

    if (!isAuthenticated || !isOnboardedForTrajectory) {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path={routes.adminEditor()} component={AdminEditorPageWithState} />
                    <Route exact path={routes.assetEditor()} component={AssetEditorAuthPage} />
                    <Route exact path={routes.loginFacebook()} component={LoginFacebookPageWithState} />
                    <Route exact path={routes.root()} component={HomePageWithState} />
                    <Route exact path={routes.home()} component={HomePageWithState} />
                    <Route exact path={routes.verifyEmail()} component={OnboardingPageWithState} />
                    <Route exact path={routes.resetPassword()} component={ResetPasswordPageWithState} />
                    <Route exact path={routes.designs()} component={DesignsPageWithState} />
                    <Route exact path={routes.editor()} component={EditorPageWithState} />
                    <Route exact path={routes.assetViewer()} component={AssetViewer} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </Router>
        )
    }

    return (
        <Router history={history}>
            <Switch>
                <Route exact path={routes.adminEditor()} component={AdminEditorPageWithState} />
                <Route exact path={routes.assetEditor()} component={AssetEditorAuthPage} />
                <Route exact path={routes.root()} component={HomePageWithState} />
                <Route exact path={routes.home()} component={HomePageWithState} />
                <Route exact path={routes.verifyEmail()} component={OnboardingPageWithState} />
                <Route exact path={routes.designs()} component={DesignsPageWithState} />
                <Route exact path={routes.profile()} component={ProfilePageWithState} />
                <Route exact path={routes.editor()} component={EditorPageWithState} />
                <Route exact path={routes.assetViewer()} component={AssetViewer} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Router>
    )
}

export default AppRouter
