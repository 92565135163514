import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const TOGGLE_LIKE_MUTATION = gql`
    mutation toggleDesignLike($id: Int!) {
        toggleDesignLike(id: $id) {
            id
            likes
            likedByCurrentUser
            phase {
                id
            }
        }
    }
`

const useToggleDesignLikeMutation = () => useMutation(TOGGLE_LIKE_MUTATION)

export default useToggleDesignLikeMutation
