import React from 'react'
import { Heading, List } from '@chakra-ui/core'
import MilestoneListItem from 'components/util/home/MilestoneListItem'
import Link from 'components/util/Link'
import { Trans } from '@lingui/macro'

const HomeMilestones = ({ milestones }) => (
    <>
        <Heading
            as="h2"
            fontSize="xl"
        >
            <Trans>Milestones</Trans>
        </Heading>
        <List mb={8}>
            {milestones.map((milestone) => (
                <MilestoneListItem
                    key={milestone.id}
                    milestone={milestone}
                />
            ))}
        </List>
        <Link>
            <Trans>Show all milestones</Trans>
        </Link>
    </>
)

export default HomeMilestones
