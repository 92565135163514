import React from 'react'
import { Text } from '@chakra-ui/core'
import { dateTimeStringToEuroDateTimeString } from 'util/converters'

const DateTimeTag = ({ dateString, ...props }) => {
    const euroDateString = dateTimeStringToEuroDateTimeString(dateString)

    return (
        <Text
            color="colorGrayDark"
            fontSize="sm"
            {...props}
        >
            {euroDateString}
        </Text>
    )
}

export default DateTimeTag
