import React, { } from 'react'
import useEditorState from 'components/hooks/useEditorState'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import DesignControls from './DesignControls'
import AssetControlsWithState from './AssetControlsWithState'
import useFetchEditorControlsData from './useFetchEditorControlsData'

const EditorControlsWithState = ({
    is2D,
    isReadOnly,
    onTakeScreenshot,
    onEditComment,
}) => {
    const {
        isFetching,
        commentAsset,
    } = useFetchEditorControlsData(is2D)
    const {
        editorIsLoading,
        loadCommentAsset,
        // DesignControls
        editorHasGrid,
        editorDrawMode,
        isPlacingComments,
        editorControlMode,
        toggleGrid,
        toggleDraw,
        togglePlacingComments,
        takeScreenshot,
        setEditorControlMode,
        zoomEditor,
    } = useEditorState()

    useAsyncEffect(async () => {
        if (!isFetching && typeof commentAsset !== 'undefined') {
            await loadCommentAsset(commentAsset)
        }
    }, [commentAsset])

    if (isFetching || editorIsLoading) {
        return null
    }

    return (
        <>
            <DesignControls
                is2D={is2D}
                isReadOnly={isReadOnly}
                editorHasGrid={editorHasGrid}
                editorDrawMode={editorDrawMode}
                isPlacingComments={isPlacingComments}
                editorControlMode={editorControlMode}
                toggleGrid={toggleGrid}
                toggleDraw={toggleDraw}
                togglePlacingComments={togglePlacingComments}
                onTakeScreenshot={() => {
                    const dataUrl = takeScreenshot()
                    onTakeScreenshot(dataUrl)
                }}
                setEditorControlMode={setEditorControlMode}
                onZoomEditor={zoomEditor}
            />
            <AssetControlsWithState
                onEditComment={onEditComment}
            />
        </>
    )
}

export default EditorControlsWithState
