import { useHistory } from 'react-router-dom'
import pushRouteWithSlug from 'routes/pushRouteWithSlug'

const usePushHistory = () => {
    const history = useHistory()

    return (route, state) => pushRouteWithSlug(history, route, state)
}

export default usePushHistory
