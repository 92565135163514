import React, { useRef, useState } from 'react'
import { useToast } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import usePushHistory from 'components/hooks/usePushHistory'
import { profile, designs } from 'routes/index'
import ModalLoading from 'components/util/ModalLoading'
import useEditorState from 'components/hooks/useEditorState'
import { DESIGN_STATUS_PUBLISHED } from 'util/constants'
import useEditDesignForm from './useEditDesignForm'
import useUploadScreenshotMutation from '../useUploadScreenshotMutation'
import useUpdateDesignMutation from './useUpdateDesignMutation'
import useDeleteDesignMutation from './useDeleteDesignMutation'
import EditDesignModalContent from './EditDesignModalContent'
import useFetchEditorEditDesignModalData from './useFetchEditorEditDesignModalData'

const EditDesignModalWithState = ({
    designId,
    isAdminEditor,
    onClose,
    onChangeFeaturedScreenshot,
    onUpdateDesignComplete,
    designShouldBePublished,
}) => {
    const focusRef = useRef()
    const toast = useToast()
    const pushRouteWithSlug = usePushHistory()
    const {
        takeScreenshot,
        getCameraPosition,
        design: editorDesign,
        updateDesignAssetStates,
    } = useEditorState()
    const [uploadScreenshot, { loading: isUploadingScreenshot }] = useUploadScreenshotMutation()
    const [updateDesign, { loading: isUpdatingDesign }] = useUpdateDesignMutation()
    const [deleteDesign, { loading: isDeletingDesign }] = useDeleteDesignMutation()
    const {
        isFetching,
        design,
    } = useFetchEditorEditDesignModalData(designId)
    const formState = useEditDesignForm({ design, designShouldBePublished })
    const [isUpdatingDesignAssets, setIsUpdatingDesignAssets] = useState(false)
    const [isScreenshotSet, setIsScreenshotSet] = useState(false)

    useAsyncEffect(async () => {
        if (!isFetching && !isScreenshotSet) {
            const dataURL = takeScreenshot()
            const input = { design: design.id, screenshot: dataURL }
            const { data } = await uploadScreenshot({ variables: { input } })
            const featuredScreenshot = data.uploadScreenshotForDesign

            design.featuredScreenshot = {
                id: featuredScreenshot.id,
                urls: featuredScreenshot.urls,
            }
            const designInput = { featuredScreenshot: featuredScreenshot.id, scaleModifier: design.scaleModifier }
            await updateDesign({ variables: { id: design.id, input: designInput } })

            featuredScreenshot.urls = { thumb: dataURL }
            formState.handleChange('featuredScreenshot', featuredScreenshot)
            setIsScreenshotSet(true)
        }
    }, [design])

    const isDesignPublished = design.status === DESIGN_STATUS_PUBLISHED
    const isFormLoading = isUploadingScreenshot || isUpdatingDesign || isDeletingDesign
    let modalTitle
    if (designShouldBePublished) {
        if (isAdminEditor) {
            modalTitle = <Trans>Finalise this template</Trans>
        } else {
            modalTitle = <Trans>Finalise your idea</Trans>
        }
    } else if (isAdminEditor) {
        modalTitle = <Trans>Save this template</Trans>
    } else {
        modalTitle = <Trans>Save your idea</Trans>
    }

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={modalTitle}
            modalContent={isFetching
                ? <ModalLoading />
                : (
                    <EditDesignModalContent
                        focusRef={focusRef}
                        formState={formState}
                        isAdminEditor={isAdminEditor}
                        designShouldBePublished={designShouldBePublished}
                        isDesignPublished={isDesignPublished}
                        isUploadingScreenshot={isUploadingScreenshot}
                        isFormLoading={isFormLoading}
                        isSubmitting={isUpdatingDesign || isUpdatingDesignAssets}
                        onChangeFeaturedScreenshot={() => onChangeFeaturedScreenshot((shot) => formState.handleChange('featuredScreenshot', shot))}
                        onSubmit={async () => {
                            if (formState.validate) {
                                try {
                                    let input = formState.valuesToInput()
                                    input = {
                                        ...input,
                                        cameraPosition: getCameraPosition(),
                                    }
                                    if (design.scaleModifier !== editorDesign.scaleModifier) {
                                        setIsUpdatingDesignAssets(true)
                                        input.scaleModifier = editorDesign.scaleModifier
                                        await updateDesignAssetStates()
                                        setIsUpdatingDesignAssets(false)
                                    }
                                    const { data } = await updateDesign({ variables: { id: design.id, input } })
                                    if (designShouldBePublished && !isAdminEditor) {
                                        pushRouteWithSlug(designs)
                                    } else {
                                        onUpdateDesignComplete(data.updateDesign)
                                        onClose()
                                    }

                                    toast({
                                        title: 'Gelukt!',
                                        description: `${isAdminEditor ? 'Dit template' : 'Je idee'} werd ${designShouldBePublished ? 'verstuurd' : 'bewaard'}.`,
                                        status: 'success',
                                        position: 'top',
                                    })
                                } catch (e) {
                                    toast({
                                        title: 'Er liep iets mis...',
                                        description: `We konden ${isAdminEditor ? 'dit template' : 'je idee'} niet ${designShouldBePublished ? 'versturen' : 'bewaren'}. Probeer het later opnieuw.`,
                                        status: 'error',
                                        position: 'top',
                                    })
                                }
                            }
                        }}
                        onDelete={async () => {
                            try {
                                const { REACT_APP_BACKEND_URL } = process.env
                                await deleteDesign({ variables: { id: design.id } })
                                if (isAdminEditor) {
                                    window.location.href = `${REACT_APP_BACKEND_URL}/resources/designs`
                                } else {
                                    pushRouteWithSlug(profile)
                                }
                                toast({
                                    title: 'Gelukt!',
                                    description: `${isAdminEditor ? 'Dit template' : 'Je idee'} werd verwijderd.`,
                                    status: 'success',
                                    position: 'top',
                                })
                            } catch (e) {
                                toast({
                                    title: 'Er liep iets mis...',
                                    description: `We konden ${isAdminEditor ? 'dit template' : 'je idee'} niet verwijderen. Probeer het later opnieuw.`,
                                    status: 'error',
                                    position: 'top',
                                })
                            }
                        }}
                        onCancel={onClose}
                    />
                )}
            px={2}
            py={6}
        />
    )
}

export default EditDesignModalWithState
