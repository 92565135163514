import * as THREE from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { isLessOrEqualThan } from 'validation/index'
import {
    createCamera,
    createOrbitControls,
    createTranslateControls,
    getObjectScale,
    isImage,
    configureCameraPosition,
} from './util/three'
import {
    ASSET_CONTROL_MODE_ROTATE,
    ENV_3D as ENV,
    EDITOR_CONTROL_MODE_ORBIT,
    ASSET_CONTROL_MODE_SCALE,
    ASSET_CONTROL_MODE_TRANSLATE,
    ASSET_MIN_Y,
    ASSET_MIN_SCALE,
    IMAGE_MIN_SCALE,
} from './util/constants'
import EditorWithState from './EditorWithProvider'

class Editor3DWithState extends EditorWithState {
    constructor(props) {
        super(props)

        this.orbitControls = null
        this.translateControls = null

        this.setEditorControlMode = this.setEditorControlMode.bind(this)
        this.setAssetControlMode = this.setAssetControlMode.bind(this)

        this.state = {
            ...this.state,
            setEditorControlMode: this.setEditorControlMode,
            setAssetControlMode: this.setAssetControlMode,
        }
    }

    setupScene(container, backgroundColor) {
        const width = container.clientWidth
        const height = container.clientHeight
        const canvas = this.renderer.domElement

        const cameraPosition = configureCameraPosition(this.design, height)
        this.camera = createCamera(width / height, cameraPosition.position)
        this.orbitControls = createOrbitControls(this.camera, canvas, cameraPosition.target)
        this.editorControls = this.orbitControls
        this.translateControls = createTranslateControls(this.camera, canvas, ENV, cameraPosition.target)

        const assetControls = new TransformControls(this.camera, canvas)
        assetControls.addEventListener('dragging-changed', (event) => {
            this.editorControls.enabled = !event.value
            const { object } = event.target
            if (typeof object !== 'undefined') {
                object.originalPosition = object.position.clone()
            }
        })
        assetControls.addEventListener('objectChange', (event) => {
            const { object } = event.target
            if (typeof object !== 'undefined') {
                // Stop assets from going below the surface
                if (this.assetControls.mode === ASSET_CONTROL_MODE_TRANSLATE) {
                    if (object.position.y <= ASSET_MIN_Y) {
                        object.position.y = ASSET_MIN_Y
                    }
                }
                // Stop assets from shrinking below the minimum scale
                if (this.assetControls.mode === ASSET_CONTROL_MODE_SCALE) {
                    let scale = getObjectScale(object.scale)
                    const minScale = isImage(object.fileType) ? IMAGE_MIN_SCALE : ASSET_MIN_SCALE
                    if (isLessOrEqualThan(minScale)(scale)) {
                        scale = minScale
                    }
                    object.scale.set(scale, scale, scale)
                }
                // Only move an asset vertically when it is dragged along the Y axis
                if (this.assetControls.axis !== 'Y' && typeof object.originalPosition !== 'undefined') {
                    object.position.y = object.originalPosition.y
                }
                this.renderEditor()
            }
        })
        this.assetControls = assetControls

        this.scene.background = new THREE.Color(backgroundColor)
        this.renderer.setSize(width, height)
        container.appendChild(canvas)

        this.editorControls.update()
        this.camera.updateProjectionMatrix()
    }

    // TODO: check efficiency
    setEditorControlMode(controlMode) {
        const { target } = this.editorControls
        if (controlMode === EDITOR_CONTROL_MODE_ORBIT) {
            this.translateControls.enabled = false
            this.orbitControls.enabled = true
            this.editorControls = this.orbitControls
        } else {
            this.orbitControls.enabled = false
            this.translateControls.enabled = true
            this.editorControls = this.translateControls
        }
        this.editorControls.controlMode = controlMode
        this.editorControls.target.set(target.x, target.y, target.z)

        this.setState({
            editorControlMode: controlMode,
        })
    }

    setAssetControlMode(mode) {
        if (mode === ASSET_CONTROL_MODE_TRANSLATE) {
            this.assetControls.showX = true
            this.assetControls.showY = true
            this.assetControls.showZ = true
        }

        super.setAssetControlMode(mode)
    }
}

export default Editor3DWithState
