import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const FACEBOOK_REDIRECT_MUTATION = gql`
    mutation facebookRedirect($trajectorySlug: String!) {
        facebookRedirectLink(trajectorySlug: $trajectorySlug)
    }
`

const useRedirectToFacebookMutation = () => useMutation(FACEBOOK_REDIRECT_MUTATION)

export default useRedirectToFacebookMutation
