import { gql } from 'apollo-boost'

const UPDATE_VECTOR_MUTATION = gql`
    mutation updateVector($id: Int!, $input: VectorStateInput) {
        updateVectorState(id: $id, input: $input) {
            id
        }
    }
`

export default async function updateVectorState(apolloClient, { id, input }, options) {
    const mutation = UPDATE_VECTOR_MUTATION
    const variables = { id, input }
    await apolloClient.mutate({ mutation, variables, ...options })
}
