import React from 'react'
import useFetchAssetMenuData from './useFetchAssetMenuData'
import AssetMenu from './AssetMenu'

const AssetMenuWithState = ({
    category,
    goBackToSubcategoryMenu,
    is2D,
}) => {
    const {
        isFetching,
        assets,
    } = useFetchAssetMenuData(category.slug, is2D)

    return (
        <AssetMenu
            isFetching={isFetching}
            category={category}
            assets={assets}
            goBackToSubcategoryMenu={goBackToSubcategoryMenu}
        />
    )
}

export default AssetMenuWithState
