import React, { useRef } from 'react'
import { Image, Flex, useToast } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import MainButton from 'components/util/buttons/MainButton'
import SecondaryButton from 'components/util/buttons/SecondaryButton'
import { downloadImage } from 'util/entities'
import useUploadScreenshotMutation from './useUploadScreenshotMutation'

const EditorScreenshotModalWithState = ({
    onClose,
    designId,
    dataURL,
    shotId,
    isNewScreenshot,
}) => {
    const toast = useToast()
    const focusRef = useRef()
    const [uploadScreenshot, { loading: isUploadingScreenshot }] = useUploadScreenshotMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Screenshot</Trans>}
            px={2}
            py={6}
            size="5xl"
            modalContent={(
                <>
                    <Image w="full" src={dataURL} />
                    {isNewScreenshot && (
                        <Flex
                            justify="space-between"
                            mt={8}
                        >
                            <SecondaryButton
                                disabled={isUploadingScreenshot}
                                onClick={onClose}
                            >
                                <Trans>Discard</Trans>
                            </SecondaryButton>
                            <MainButton
                                ref={focusRef}
                                isLoading={isUploadingScreenshot}
                                onClick={async () => {
                                    try {
                                        const input = { design: designId, screenshot: dataURL }
                                        await uploadScreenshot({ variables: { input } })
                                        onClose()
                                        toast({
                                            title: 'Gelukt!',
                                            description: 'Je screenshot werd geüpload',
                                            status: 'success',
                                            position: 'top',
                                        })
                                    } catch (e) {
                                        toast({
                                            title: 'Er liep iets mis...',
                                            description: 'We konden je screenshot niet uploaden. Probeer het later opnieuw.',
                                            status: 'error',
                                            position: 'top',
                                        })
                                    }
                                }}
                            >
                                <Trans>Save</Trans>
                            </MainButton>
                        </Flex>
                    )}
                    {!isNewScreenshot && (
                        <Flex
                            w="full"
                            justify="flex-end"
                            mt={8}
                        >
                            <MainButton
                                ref={focusRef}
                                isLoading={isUploadingScreenshot}
                                onClick={async () => {
                                    await downloadImage(dataURL, `ontwerp${designId}-screenshot${shotId}.jpg`)
                                }}
                            >
                                <Trans>Download</Trans>
                            </MainButton>
                        </Flex>
                    )}
                </>
            )}
        />
    )
}

export default EditorScreenshotModalWithState
