import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'

export const ADMIN_EDITOR_LOGIN_QUERY = gql`
    query adminEditorLoginQuery($id: Int!) {
        design(id :$id){
            id
            phase {
                id
                trajectory {
                    slug
                }
            }
        }
    }
`

const useFetchAdminEditorLoginData = () => useLazyQuery(ADMIN_EDITOR_LOGIN_QUERY)

export default useFetchAdminEditorLoginData
