import React from 'react'
import {
    Text,
    Stack,
    Box,
    Icon,
    Flex,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Image from 'components/util/Image'
import MainButton from 'components/util/buttons/MainButton'
import usePushHistory from 'components/hooks/usePushHistory'
import { home } from 'routes/index'

// Todo: Be more creative
const PageNotFound = () => {
    const pushRouteWithSlug = usePushHistory()

    return (
        <Stack
            w="full"
            h="full"
            alignItems="center"
            justify="center"
        >
            <Box
                w="xs"
                textAlign="center"
            >
                <Image src="logos/logo.png" />
                <Text my={8}>
                    <Trans>We can&apos;t seem to find this page...</Trans>
                </Text>
                <MainButton onClick={() => pushRouteWithSlug(home)}>
                    <Flex>
                        <Trans>To the homepage</Trans>
                        <Icon
                            name="arrowRight"
                            size="24px"
                            stroke="colorWhite"
                            fill="none"
                        />
                    </Flex>
                </MainButton>
            </Box>
        </Stack>
    )
}

export default PageNotFound
