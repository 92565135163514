import React from 'react'
import { Divider } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'
import FormInput from 'components/util/forms/FormInput'
import ErrorField from 'components/util/forms/ErrorField'
import { withI18n } from '@lingui/react'

const ResetPasswordForm = withI18n()(({
    i18n,
    focusRef,
    formState,
    isResettingPassword,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <>
            <FormInput
                {...getInputProps('email')}
                focusRef={focusRef}
                error={getErrorMessages('email')}
                id="email"
                label={<Trans>Email</Trans>}
                type="email"
                placeholder={i18n._('Email')}
            />
            <FormInput
                {...getInputProps('password')}
                ref={focusRef}
                error={getErrorMessages('password')}
                id="password"
                label={<Trans>New password</Trans>}
                type="password"
                placeholder={i18n._('Password')}
            />
            <FormInput
                {...getInputProps('passwordConfirmation')}
                error={getErrorMessages('passwordConfirmation')}
                id="passwordConfirmation"
                label={<Trans>Confirm new password</Trans>}
                type="password"
                placeholder={i18n._('Confirm password')}
            />
            <ErrorField
                mt={2}
                textAlign="center"
                errorMessage={getErrorMessages('updatePassword')}
            />
            <Divider w="full" />
            <MainButton
                loading={isResettingPassword}
                onClick={onSubmit}
                mt={8}
                w="full"
            >
                <Trans>Update password</Trans>
            </MainButton>
        </>
    )
})

export default ResetPasswordForm
