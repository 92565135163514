import React from 'react'
import useAppState from './hooks/useAppState'
import AppRouter from './app/AppRouter'
import PageLoading from './layout/PageLoading'

const App = ({ history }) => {
    const {
        isLoadingTokens,
        isCheckingSlug,
        isLoadingInitialData,
    } = useAppState()

    const isLoading = isLoadingTokens || isLoadingInitialData || isCheckingSlug

    if (isLoading) {
        return <PageLoading />
    }

    return (
        <AppRouter history={history} />
    )
}

export default App
