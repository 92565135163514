import React from 'react'
import {
    Flex,
    Box,
    Heading,
    Text,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { dateTimeStringToEuroDateTimeString } from 'util/converters'
import MainButton from 'components/util/buttons/MainButton'
import EditorTopbarButtons from './EditorTopbarButtons'
import EditorReadOnlyTopbarButtons from './EditorReadOnlyTopbarButtons'
import EditorTopbarButton from './EditorTopbarButton'

const EditorTopbar = ({
    isReadonly,
    trajectory,
    designLastUpdate,
    isDesignPublished,
    onOpenEditModal,
    onOpenShotsModal,
    onOpenShareModal,
    onOpenEditorDesignInfoModal,
    onCloseEditor,
    onPublishDesign,
}) => (
    <Flex
        w="full"
        h={20}
        bg="colorWhite"
        justify="space-between"
        px={8}
        py={2}
    >
        <Box>
            <Flex align="center">
                <Box
                    w={4}
                    h={4}
                    rounded="full"
                    bg={isDesignPublished ? '#37CD85' : '#FA5252'}
                />
                <Heading
                    ml={5}
                    fontSize="lg"
                >
                    {isDesignPublished ? trajectory.title : <Trans>Draft</Trans>}
                </Heading>
            </Flex>
            <Text mt={2} color="secondaryTextColor">
                <Trans>Last update</Trans>
                :
                {' '}
                {dateTimeStringToEuroDateTimeString(designLastUpdate)}
            </Text>
        </Box>
        <Flex h="full" align="center">
            {isReadonly
                ? (
                    <EditorReadOnlyTopbarButtons
                        onOpenShareModal={onOpenShareModal}
                        onOpenEditorDesignInfoModal={onOpenEditorDesignInfoModal}
                    />
                )
                : (
                    <EditorTopbarButtons
                        isDesignPublished={isDesignPublished}
                        onOpenEditModal={onOpenEditModal}
                        onOpenShotsModal={onOpenShotsModal}
                        onOpenShareModal={onOpenShareModal}
                        onOpenEditorDesignInfoModal={onOpenEditorDesignInfoModal}
                    />
                )}
            {(!isDesignPublished && !isReadonly) && (
                <MainButton
                    size="sm"
                    py={2}
                    ml={10}
                    onClick={onPublishDesign}
                >
                    <Trans>Send your idea</Trans>
                </MainButton>
            )}
            <EditorTopbarButton
                icon="remove"
                onClick={onCloseEditor}
                ml={12}
            />
        </Flex>
    </Flex>
)

export default EditorTopbar
