import React from 'react'
import {
    Box,
    Flex,
    Text,
    Heading,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'
import EditorTopbarButton from '../editor/editor-topbar/EditorTopbarButton'

const AdminEditorTopbar = ({
    designIsPublished,
    scaleModifier,
    onSaveTemplate,
    onOpenShotsModal,
    onChangeScaleModifier,
    onPublishTemplate,
    onCloseEditor,
}) => (
    <Flex
        bg="colorWhite"
        h={20}
        justify="space-between"
        px={8}
        py={2}
    >
        <Flex align="center">
            <Box
                w={4}
                h={4}
                rounded="full"
                bg={designIsPublished ? '#37CD85' : '#FA5252'}
            />
            <Heading
                ml={5}
                fontSize="lg"
            >
                <Trans>Administrator Editor</Trans>
            </Heading>
        </Flex>
        <Flex align="center">
            <Text>
                <Trans>Asset scale modifier</Trans>
                :
            </Text>
            <NumberInput
                value={scaleModifier}
                onChange={onChangeScaleModifier}
                step={0.1}
                min={0.1}
                ml={4}
                w={20}
            >
                <NumberInputField
                    bg="colorGrayWhite"
                    focusBorderColor="colorPurple"
                />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Flex>
        <Flex align="center">
            <EditorTopbarButton
                text={designIsPublished
                    ? <Trans>Save</Trans>
                    : <Trans>Save for now</Trans>}
                icon="edit"
                onClick={onSaveTemplate}
            />
            <EditorTopbarButton
                text={<Trans>Screenshots</Trans>}
                icon="screenshot"
                onClick={onOpenShotsModal}
            />
        </Flex>
        {!designIsPublished && (
            <MainButton
                size="sm"
                py={2}
                onClick={onPublishTemplate}
            >
                <Trans>Publish</Trans>
            </MainButton>
        )}
        <Flex align="center">
            <EditorTopbarButton
                icon="remove"
                onClick={onCloseEditor}
            />
        </Flex>
    </Flex>
)

export default AdminEditorTopbar
