import React from 'react'
import { Box, Heading } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import usePushHistory from 'components/hooks/usePushHistory'
import { editor } from 'routes/index'
import { getDesignCardImageUrlFromDesign } from 'util/design'
import { HOME_PAGE_SECTION_WIDTH as SECTION_WIDTH } from 'util/constants'
import Link from '../Link'
import CollapseableDescription from '../CollapseableDescription'

const DesignOverviewCard = ({ design }) => {
    const pushRouteWithSlug = usePushHistory()

    return (
        <>
            <Link linkTo={() => pushRouteWithSlug(editor, { designId: design.id })}>
                <Box
                    w="full"
                    h={SECTION_WIDTH}
                    bgImage={`url(${getDesignCardImageUrlFromDesign(design)})`}
                    bgSize="cover"
                    bgPos="center"
                    rounded="lg"
                />
            </Link>
            <Box textAlign="left">
                <Link
                    linkTo={() => pushRouteWithSlug(editor, { designId: design.id })}
                    color="primaryTextColor"
                >
                    <Heading
                        as="h3"
                        fontSize="lg"
                        mt={6}
                    >
                        {design.name}
                    </Heading>
                </Link>
                <CollapseableDescription
                    designId={design.id}
                    description={design.description}
                    mt={4}
                />
                <Box mt={4}>
                    <Link>
                        <Trans>By</Trans>
                        {' '}
                        {design.user.username === null
                            ? <Trans>Anonymous</Trans>
                            : design.user.username}
                    </Link>
                </Box>
            </Box>
        </>
    )
}

export default DesignOverviewCard
