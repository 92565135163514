import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const DESIGNS_PAGE_QUERY = gql`
    query designPageQuery($slug: String!, $isTemplate: Boolean) {
        trajectory(slug: $slug) {
            slug
            title
            description
            phases {
                id
                title
                startDate
                endDate
                description
                designs(isTemplate: $isTemplate) {
                    id
                    name
                    description
                    status
                    likes
                    likedByCurrentUser
                    featuredScreenshot {
                        id
                        urls
                    }
                    surface {
                        id
                        urls
                    }
                    user {
                        id
                        username
                    }
                }
            }
        }
    }
`

const useFetchDesignsPageData = (slug) => {
    const variables = {
        slug,
        isTemplate: false,
    }
    const {
        error,
        loading,
        data,
        updateQuery,
    } = useQuery(DESIGNS_PAGE_QUERY, { variables, fetchPolicy: 'network-only' })

    if (loading) {
        return ({
            isFetching: true,
            trajectory: {},
        })
    }

    const { trajectory } = data

    return ({
        error,
        isFetching: false,
        trajectory,
        updateQuery,
    })
}

export default useFetchDesignsPageData
