import React, { useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import useResetPasswordForm from './useRequestPasswordResetForm'
import useRequestPasswordResetMutation from './useRequestPasswordResetMutation'
import RequestPasswordResetModal from './RequestPasswordResetModal'

const RequestPasswordResetModalWithState = ({
    onClose,
    slug,
}) => {
    const focusRef = useRef()
    const [resetEmail, setResetEmail] = useState(null)
    const formState = useResetPasswordForm(slug)
    const [requestPasswordReset, { loading: isRequestingPasswordReset }] = useRequestPasswordResetMutation()

    return (
        <Modal
            onClose={onClose}
            initialFocusRef={focusRef}
            modalTitle={<Trans>Reset your password</Trans>}
            modalContent={(
                <RequestPasswordResetModal
                    focusRef={focusRef}
                    resetEmail={resetEmail}
                    formState={formState}
                    isRequestingPasswordReset={isRequestingPasswordReset}
                    onSubmit={async () => {
                        if (formState.validate()) {
                            try {
                                const input = formState.values
                                const { data } = await requestPasswordReset({ variables: { input } })
                                if (data.sendResetLinkEmail) {
                                    setResetEmail(input.email)
                                } else {
                                    formState.updateErrors([{ path: 'sendResetLinkEmail', message: <Trans>Something went wrong. Please try again.</Trans> }])
                                }
                            } catch (error) {
                                formState.updateErrors(parseFormError(error, 'sendResetLinkEmail'))
                            }
                        }
                    }}
                />
            )}
        />
    )
}

export default RequestPasswordResetModalWithState
