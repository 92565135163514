import { gql } from 'apollo-boost'

export const INITIAL_DATA_QUERY = gql`
    query initialData($slug: String!) {
        me {
            id
            username
            email
            age
            sex
            postalCode
            isAnonymous
            isOnboardedForTrajectory(slug: $slug)
            hasAdminEditorAccess
        }
    }
`

export const FALLBACK_INITIAL_DATA_QUERY = gql`
    query initialData {
        me {
            id
            username
            email
            age
            sex
            postalCode
            isAnonymous
            hasAdminEditorAccess
        }
    }
`

export default async function getInitialData(apolloClient, slug) {
    const query = slug === null ? FALLBACK_INITIAL_DATA_QUERY : INITIAL_DATA_QUERY
    const variables = slug === null ? {} : { slug }
    try {
        const response = await apolloClient.query({ query, variables })
        return response.data
    } catch (error) {
        return null
    }
}
