export const flatten = (a, b) => [...a, ...b]

export const isStaging = () => process.env.REACT_APP_ENV === 'staging'

export const isDevelopment = () => process.env.REACT_APP_ENV === 'development'

export const isProduction = () => process.env.REACT_APP_ENV === 'production'

export const where = (...keys) => (...values) => (obj) => keys.every((key) => (key.includes('!') ? !values.includes(obj[key.replace('!', '')]) : values.includes(obj[key])))

export const whereIsNot = (key) => (value) => (obj) => obj[key] !== value
