import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import PageError from '../layout/PageError'

class AppErrorBoundary extends Component {
    constructor(props) {
        super(props)
        const environment = process.env.REACT_APP_ENV
        this.shouldLogError = environment !== 'local'
        this.state = { eventId: null }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        if (this.shouldLogError) {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo)
                const eventId = Sentry.captureException(error)
                this.setState({ eventId })
            })
        }
    }

    render() {
        const {
            hasError,
            eventId,
        } = this.state
        const { children } = this.props

        if (hasError && this.shouldLogError) {
            return (
                <PageError
                    onReportFeedback={() => {
                        Sentry.showReportDialog({ eventId })
                    }}
                />
            )
        }

        return children
    }
}
export default AppErrorBoundary
