import React from 'react'
import {
    Box,
    Icon,
    Button,
    useTheme,
} from '@chakra-ui/core'

const EditorContentMenuPageContainer = ({
    goBack,
    children,
}) => {
    const theme = useTheme()

    return (
        <Box
            w="xs"
            pos="fixed"
            top={40}
            right={0}
        >
            <Box
                bg="colorWhite"
                p={5}
                right={0}
                roundedLeft="lg"
                pos="absolute"
                zIndex="overlay"
            >
                <Button
                    size="xs"
                    variant="unstyled"
                    onClick={goBack}
                    _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
                >
                    <Icon
                        name="arrowUndo"
                        size="24px"
                        stroke="primaryIconColor"
                        fill="none"
                    />
                </Button>
            </Box>
            <Box
                bg="colorWhite"
                p={5}
                roundedLeft="lg"
            >
                {children}
            </Box>
        </Box>
    )
}

export default EditorContentMenuPageContainer
