import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'
import DEFAULT_ASSET_FRAGMENT from 'apollo/fragments/defaultAsset'

export const EDITOR_PAGE_QUERY = gql`
    query editorPageQuery($slug: String!, $id: Int!) {
        trajectory(slug: $slug) {
            slug
            title
            title
            description
            city
        }
        design(id :$id){
            id
            name
            description
            status
            is2D
            scaleModifier
            updatedAt
            cameraPosition {
                position {
                    ...defaultCoordinate
                }
                target {
                    ...defaultCoordinate
                }
            }
            surface {
                ...defaultMediaItem
            }
            user {
                id
                isAnonymous
                username
            }
            assets {
                ...defaultAsset
            }
            designAssets {
                id
                position {
                    ...defaultCoordinate
                }
                rotation {
                    ...defaultCoordinate
                }
                scale {
                    ...defaultCoordinate
                }
                comment
                asset {
                    id
                    name
                    url
                }
            }
            vectors {
                id
                position {
                    ...defaultCoordinate
                }
                rotation {
                    ...defaultCoordinate
                }
                scale {
                    ...defaultCoordinate
                }
                points
            }
        }
    }
    ${DEFAULT_ASSET_FRAGMENT}
`

export const ADMIN_EDITOR_PAGE_QUERY = gql`
    query adminEditorPageQuery($id: Int!) {
        design(id :$id){
            id
            name
            description
            status
            is2D
            scaleModifier
            updatedAt
            cameraPosition {
                position {
                    ...defaultCoordinate
                }
                target {
                    ...defaultCoordinate
                }
            }
            surface {
                ...defaultMediaItem
            }
            assets {
                ...defaultAsset
            }
            designAssets {
                id
                position {
                    ...defaultCoordinate
                }
                rotation {
                    ...defaultCoordinate
                }
                scale {
                    ...defaultCoordinate
                }
                comment
                asset {
                    id
                    name
                    url
                }
            }
            vectors {
                id
                position {
                    ...defaultCoordinate
                }
                rotation {
                    ...defaultCoordinate
                }
                scale {
                    ...defaultCoordinate
                }
                points
            }
        }
    }
    ${DEFAULT_ASSET_FRAGMENT}
`

const useFetchEditorPageData = (isAdminEditor) => {
    const query = isAdminEditor ? ADMIN_EDITOR_PAGE_QUERY : EDITOR_PAGE_QUERY

    return useLazyQuery(query, { fetchPolicy: 'network-only' })
}

export default useFetchEditorPageData
