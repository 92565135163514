export const LOGGED_IN_HEADER_PROFILE = 'profile'
export const LOGGED_IN_HEADER_DESIGNS = 'designs'
export const LOGGED_IN_HEADER_TRAJECTORY = 'trajectory'

export const HOME_PAGE_SECTION_WIDTH = 'lg'

export const COMPANY_NAME = 'Innovatiesteunpunt'
export const COMPANY_ADDRESS = 'Diestsevest 40, 3000 Leuven'

export const DESIGN_STATUS_DRAFT = 'DRAFT'
export const DESIGN_STATUS_PUBLISHED = 'PUBLISHED'
