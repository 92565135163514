import React from 'react'
import { Trans } from '@lingui/macro'
import { Stack } from '@chakra-ui/core'
import {
    EDITOR_CONTROL_MODE_TRANSLATE,
    EDITOR_CONTROL_MODE_ORBIT,
    DRAW_MODE_NONE,
} from 'editor/util/constants'
import useEditorState from 'components/hooks/useEditorState'
import ButtonWithTooltip from 'components/util/buttons/ButtonWithTooltip'

const AssetEditorControlsWithState = () => {
    const {
        editorDrawMode,
        isPlacingComments,
        editorControlMode,
        setEditorControlMode,
    } = useEditorState()

    return (
        <Stack
            pos="fixed"
            top={40}
            left={0}
            bg="colorWhite"
            roundedRight="lg"
        >
            <ButtonWithTooltip
                type="translate"
                tooltip={<Trans>Translate map</Trans>}
                tooltipDirection="right"
                onClick={() => setEditorControlMode(EDITOR_CONTROL_MODE_TRANSLATE)}
                isSelected={editorControlMode === EDITOR_CONTROL_MODE_TRANSLATE}
                disabled={editorDrawMode !== DRAW_MODE_NONE || isPlacingComments}
            />
            <ButtonWithTooltip
                type="rotate"
                tooltip={<Trans>Rotate map</Trans>}
                tooltipDirection="right"
                onClick={() => setEditorControlMode(EDITOR_CONTROL_MODE_ORBIT)}
                isSelected={editorControlMode === EDITOR_CONTROL_MODE_ORBIT}
                disabled={editorDrawMode !== DRAW_MODE_NONE || isPlacingComments}
            />
        </Stack>
    )
}

export default AssetEditorControlsWithState
