import React from 'react'
import {
    FormControl,
    FormLabel,
    Input,
    Textarea,
} from '@chakra-ui/core'
import ErrorField from 'components/util/forms/ErrorField'

const FormInput = ({
    id,
    label,
    type = 'text',
    placeholder,
    focusRef,
    value,
    onChange,
    error,
    ...props
}) => {
    let InputComponent = Input
    if (type === 'textarea') {
        InputComponent = Textarea
    }

    return (
        <FormControl
            mt={2}
            {...props}
        >
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <InputComponent
                ref={focusRef}
                mt={1}
                bg="colorGrayWhite"
                focusBorderColor="colorPurple"
                placeholder={placeholder}
                type={type}
                id={id}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                resize="none"
            />
            <ErrorField errorMessage={error} />
        </FormControl>
    )
}

export default FormInput
