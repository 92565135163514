import React from 'react'
import {
    Text,
    Stack,
    Image,
    Box,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import Link from 'components/util/Link'

const TrajectoryNotFound = ({
    slug,
}) => (
    <Stack
        w="full"
        h="full"
        alignItems="center"
        justify="center"
    >
        <Box w="xs">
            <Image src="logos/logo.png" />
            <Text
                mt={8}
                mb={2}
                fontWeight="bold"
            >
                {slug}
            </Text>
            <Text>
                <Trans>
                        We don&apos;t know this trajectory.
                        Look for an other one or go to
                </Trans>
                {' '}
                <Link href="https://www.innovatiesteunpunt.be">Innovatiesteunpunt</Link>
            </Text>
        </Box>
    </Stack>
)

export default TrajectoryNotFound
