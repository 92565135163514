import createDesignAssetState from 'api/createDesignAssetState'
import createVectorState from 'api/createVectorState'
import updateDesignAssetState from 'api/updateDesignAssetState'
import updateVectorState from 'api/updateVectorState'
import { threeLineToVectorStateInput, threeAssetToDesignAssetStateInput } from './three'

export const createAssetState = async (asset, designId, apolloClient, toast) => {
    try {
        const input = threeAssetToDesignAssetStateInput(asset, designId)
        const { id } = await createDesignAssetState(apolloClient, input)
        return id
    } catch (error) {
        toast({
            title: 'Er liep iets mis...',
            description: 'Dit voorwerp kon niet worden toegevoegd. Probeer het later opnieuw.',
            status: 'error',
            position: 'top',
        })

        return null
    }
}

export const updateAssetState = async (asset, designId, apolloClient, toast) => {
    try {
        const input = threeAssetToDesignAssetStateInput(asset, designId)
        await updateDesignAssetState(apolloClient, { id: asset.designAssetId, input })
    } catch (error) {
        toast({
            title: 'Er liep iets mis...',
            description: 'Dit voorwerp kon niet bewaard worden. Herlaad de pagina en probeer het opnieuw.',
            status: 'error',
            position: 'top',
        })
    }
}

export const createVectorAssetState = async (vector, designId, apolloClient, toast) => {
    try {
        const input = threeLineToVectorStateInput(vector, designId)
        const { id } = await createVectorState(apolloClient, input)

        return id
    } catch (error) {
        toast({
            title: 'Er liep iets mis...',
            description: 'Deze lijn kon niet worden toegevoegd. Probeer het later opnieuw.',
            status: 'error',
            position: 'top',
        })

        return null
    }
}

export const updateVectorAssetState = async (vector, designId, apolloClient, toast) => {
    try {
        const input = threeLineToVectorStateInput(vector, designId)
        await updateVectorState(apolloClient, { id: vector.vectorId, input })
    } catch (error) {
        toast({
            title: 'Er liep iets mis...',
            description: 'Deze lijn kon niet bewaard worden. Herlaad de pagina en probeer het opnieuw.',
            status: 'error',
            position: 'top',
        })
    }
}
