import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import DEFAULT_ASSET_FRAGMENT from 'apollo/fragments/defaultAsset'

export const EDITOR_CONTROLS_QUERY = gql`
    query editorControlsQuery($where: AssetsWhereWhereConditions) {
        assets(where: $where) {
            ...defaultAsset
        }
    }
    ${DEFAULT_ASSET_FRAGMENT}
`

const useFetchEditorControlsData = (is2D) => {
    const variables = {
        where: {
            AND: [{
                column: 'IS3D',
                value: !is2D,
            }, {
                column: 'TYPE',
                value: 'comment',
            }],
        },
    }
    const {
        loading,
        data,
    } = useQuery(EDITOR_CONTROLS_QUERY, { variables })

    if (loading) {
        return ({
            isFetching: true,
            asset: [],
        })
    }

    const { assets } = data

    return ({
        isFetching: false,
        commentAsset: assets[0],
    })
}

export default useFetchEditorControlsData
