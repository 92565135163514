import React from 'react'
import {
    Flex,
    Heading,
    Text,
    Box,
    Button,
    useTheme,
} from '@chakra-ui/core'
import { isEmpty } from 'validation/index'
import { Trans } from '@lingui/macro'
import CollapseableDescription from 'components/util/CollapseableDescription'

const ChooseTemplateForm = ({
    focusRef,
    templates,
    onChoose,
}) => {
    const theme = useTheme()

    if (isEmpty(templates)) {
        return (
            <Box
                mt={8}
                textAlign="center"
                color="secondaryTextColor"
            >
                <Text>
                    <Trans>
                        Sorry, there are no templates available right now.
                    </Trans>
                </Text>
                <Text>
                    <Trans>
                        Please try again later.
                    </Trans>
                </Text>
            </Box>
        )
    }

    return (
        <Flex justify="space-between" wrap="wrap">
            {templates.map((template, index) => {
                const templateImage = template.featuredScreenshot === null
                    ? template.surface.urls.thumb
                    : template.featuredScreenshot.urls.thumb

                return (
                    <Box
                        key={template.id}
                        w="48%"
                        mb={6}
                    >
                        <Button
                            ref={index === 0 ? focusRef : null}
                            bgImage={`url(${templateImage})`}
                            variant="unstyled"
                            w="100%"
                            h="150px"
                            _focus={{ boxShadow: `0 0 0 2px ${theme.colors.primaryColor}` }}
                            onClick={() => onChoose(template.id)}
                        />
                        <Heading
                            mt={2}
                            fontSize="lg"
                        >
                            {template.name}
                        </Heading>
                        {template.description !== null && (
                            <CollapseableDescription mt={1} description={template.description} />
                        )}
                    </Box>
                )
            })}
        </Flex>
    )
}

export default ChooseTemplateForm
