import React from 'react'
import {
    Heading,
    Flex,
    Text,
    Box,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import PhaseOrderTag from './PhaseOrderTag'
import DateTag from '../DateTag'

const PhaseSummary = ({
    phase,
    hasEnded,
    ...props
}) => (
    <Flex {...props}>
        <Box>
            <PhaseOrderTag phaseOrder={phase.order} />
        </Box>
        <Box
            mt={2}
            ml={4}
            textAlign="left"
        >
            <Heading
                as="h3"
                fontSize="lg"
            >
                {phase.title}

            </Heading>
            <Flex mt={1}>
                <Text color="secondaryTextColor">
                    {hasEnded
                        ? <Trans>Ended on</Trans>
                        : <Trans>Starts on</Trans>}
                </Text>
                <DateTag
                    ml={1}
                    fontSize="md"
                    dateString={hasEnded ? phase.endDate : phase.startDate}
                />
            </Flex>
        </Box>
    </Flex>
)

export default PhaseSummary
