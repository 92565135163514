import React from 'react'
import { Divider } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { withI18n } from '@lingui/react'
import MainButton from 'components/util/buttons/MainButton'
import FormInput from 'components/util/forms/FormInput'
import ErrorField from 'components/util/forms/ErrorField'
import Toggle from 'components/util/forms/Toggle'
import FormInputNumberWithControls from 'components/util/forms/FormInputNumberWithControls'
import FormInputNumber from 'components/util/forms/FormInputNumber'
import Link from 'components/util/Link'

const UpdateUserForm = withI18n()(({
    i18n,
    focusRef,
    formState,
    isUpdatingUser,
    onUpdatePassword,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <>
            <FormInput
                {...getInputProps('username')}
                error={getErrorMessages('username')}
                id="username"
                label={<Trans>Username</Trans>}
                placeholder={i18n._('Username')}
                focusRef={focusRef}
            />
            <FormInput
                {...getInputProps('email')}
                error={getErrorMessages('email')}
                id="email"
                label={<Trans>Email</Trans>}
                type="email"
                placeholder={i18n._('Email')}
            />
            <FormInputNumber
                {...getInputProps('postalCode')}
                error={getErrorMessages('postalCode')}
                id="postalCode"
                label={<Trans>Residence</Trans>}
                placeholder={i18n._('Postal code')}
            />
            <Toggle
                {...getInputProps('sex')}
                error={getErrorMessages('sex')}
                id="sex"
                label={<Trans>Gender</Trans>}
                options={[{
                    value: 'MALE',
                    label: <Trans>Male</Trans>,
                }, {
                    value: 'FEMALE',
                    label: <Trans>Female</Trans>,
                }, {
                    value: 'OTHER',
                    label: <Trans>X</Trans>,
                }]}
                mt={4}
            />
            <FormInputNumberWithControls
                {...getInputProps('age')}
                error={getErrorMessages('age')}
                id="age"
                label={<Trans>Age</Trans>}
                placeholder="xx"
                w="35%"
            />
            <Link linkTo={onUpdatePassword}>
                <Trans>Change password</Trans>
            </Link>
            <ErrorField
                errorMessage={getErrorMessages('updateUser')}
                mt={2}
                textAlign="center"
            />
            <Divider w="full" />
            <MainButton
                loading={isUpdatingUser}
                mt={8}
                w="full"
                onClick={onSubmit}
            >
                <Trans>Save</Trans>
            </MainButton>
        </>
    )
})

export default UpdateUserForm
