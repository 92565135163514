import React from 'react'
import { Divider } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import MainButton from 'components/util/buttons/MainButton'
import FormInput from 'components/util/forms/FormInput'
import ErrorField from 'components/util/forms/ErrorField'
import { withI18n } from '@lingui/react'

const ConvertAnonymousUserForm = withI18n()(({
    i18n,
    focusRef,
    formState,
    isConvertingAnonymousUser,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <>
            <FormInput
                {...getInputProps('username')}
                error={getErrorMessages('username')}
                id="username"
                label={<Trans>Username</Trans>}
                placeholder={i18n._('Username')}
                focusRef={focusRef}
            />
            <FormInput
                {...getInputProps('email')}
                error={getErrorMessages('email')}
                id="email"
                label={<Trans>Email</Trans>}
                type="email"
                placeholder={i18n._('Email')}
            />
            <FormInput
                {...getInputProps('password')}
                error={getErrorMessages('password')}
                id="password"
                label={<Trans>Password</Trans>}
                type="password"
                placeholder={i18n._('Password')}
            />
            <FormInput
                {...getInputProps('passwordConfirmation')}
                error={getErrorMessages('passwordConfirmation')}
                id="passwordConfirmation"
                label={<Trans>Confirm password</Trans>}
                type="password"
                placeholder={i18n._('Confirm password')}
            />
            <ErrorField
                my={2}
                textAlign="center"
                errorMessage={getErrorMessages('convertAnonymousUser')}
            />
            <Divider w="full" />
            <MainButton
                loading={isConvertingAnonymousUser}
                onClick={onSubmit}
                mt={8}
                w="full"
            >
                <Trans>Register</Trans>
            </MainButton>
        </>
    )
})

export default ConvertAnonymousUserForm
