import React from 'react'
import { Divider } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { withI18n } from '@lingui/react'
import MainButton from 'components/util/buttons/MainButton'
import FormInput from 'components/util/forms/FormInput'
import ErrorField from 'components/util/forms/ErrorField'

const RequestPasswordResetForm = withI18n()(({
    i18n,
    focusRef,
    formState,
    isRequestingPasswordReset,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <>
            <FormInput
                {...getInputProps('email')}
                focusRef={focusRef}
                error={getErrorMessages('email')}
                id="email"
                label={<Trans>Email</Trans>}
                type="email"
                placeholder={i18n._('Email')}
            />
            <ErrorField
                mt={2}
                textAlign="center"
                errorMessage={getErrorMessages('sendResetLinkEmail')}
            />
            <Divider w="full" />
            <MainButton
                loading={isRequestingPasswordReset}
                onClick={onSubmit}
                mt={8}
                w="full"
            >
                <Trans>Request password reset</Trans>
            </MainButton>
        </>
    )
})

export default RequestPasswordResetForm
