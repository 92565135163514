import React, { useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import ConfirmEmailBox from 'components/util/ConfirmEmailBox'
import Modal from 'components/util/Modal'
import { parseFormError } from 'util/errors'
import useAppState from 'components/hooks/useAppState'
import useConvertAnonymousUserForm from './useConvertAnonymousUserForm'
import ConvertAnonymousUserForm from './ConvertAnonymousUserForm'
import useConvertAnonymousUserMutation from './useConvertAnonymousUserMutation'

const ConvertAnonymousUserModalWithState = ({ onClose }) => {
    const focusRef = useRef()
    const { updateCurrentUser, slug } = useAppState()
    const formState = useConvertAnonymousUserForm(slug)
    const [convertedUser, setConvertedUser] = useState(null)
    const [convertAnonymousUser, { loading: isConvertingAnonymousUser }] = useConvertAnonymousUserMutation()
    const isRegistered = convertedUser !== null

    return (
        <Modal
            onClose={() => {
                if (isRegistered) {
                    updateCurrentUser(convertedUser)
                }
                onClose()
            }}
            initialFocusRef={focusRef}
            modalTitle={isRegistered
                ? <Trans>Confirm your email address</Trans>
                : <Trans>Register</Trans>}
            modalContent={isRegistered
                ? (<ConfirmEmailBox email={convertedUser.email} />)
                : (
                    <ConvertAnonymousUserForm
                        focusRef={focusRef}
                        formState={formState}
                        isConvertingAnonymousUser={isConvertingAnonymousUser}
                        onSubmit={async () => {
                            if (formState.validate()) {
                                try {
                                    const input = formState.values
                                    const { data } = await convertAnonymousUser({ variables: { slug, input } })
                                    setConvertedUser(data.convertAnonymousUser)
                                } catch (e) {
                                    formState.updateErrors(parseFormError(e, 'convertAnonymousUser'))
                                }
                            }
                        }}
                    />
                )}
        />
    )
}

export default ConvertAnonymousUserModalWithState
