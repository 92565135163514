import React from 'react'
import { Divider } from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import { withI18n } from '@lingui/react'
import MainButton from 'components/util/buttons/MainButton'
import Link from 'components/util/Link'
import ErrorField from 'components/util/forms/ErrorField'
import Toggle from 'components/util/forms/Toggle'
import FormInputNumberWithControls from 'components/util/forms/FormInputNumberWithControls'
import FormInputNumber from 'components/util/forms/FormInputNumber'

const RegisterAnonymousForm = withI18n()(({
    i18n,
    focusRef,
    formState,
    isRegisteringAnonymous,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <>
            <FormInputNumber
                {...getInputProps('postalCode')}
                error={getErrorMessages('postalCode')}
                id="postalCode"
                label={<Trans>Where do you live?</Trans>}
                placeholder={i18n._('Postal code')}
                focusRef={focusRef}
            />
            <Toggle
                {...getInputProps('sex')}
                error={getErrorMessages('sex')}
                id="sex"
                label={<Trans>What is your gender?</Trans>}
                options={[{
                    value: 'MALE',
                    label: <Trans>Male</Trans>,
                }, {
                    value: 'FEMALE',
                    label: <Trans>Female</Trans>,
                }, {
                    value: 'OTHER',
                    label: <Trans>X</Trans>,
                }]}
                mt={4}
            />
            <FormInputNumberWithControls
                {...getInputProps('age')}
                error={getErrorMessages('age')}
                id="age"
                label={<Trans>How old are you?</Trans>}
                placeholder="xx"
                w="35%"
            />
            <Toggle
                {...getInputProps('agreedConditions')}
                error={getErrorMessages('agreedConditions')}
                id="agreedConditions"
                label={(
                    <>
                        <Trans>Do you agree with the</Trans>
                        {' '}
                        <Link>
                            <Trans>general terms and conditions</Trans>
                        </Link>
                        ?
                    </>
                )}
                options={[{
                    value: true,
                    label: <Trans>Yes</Trans>,
                }, {
                    value: false,
                    label: <Trans>No</Trans>,
                }]}
                mt={4}
            />
            <ErrorField
                errorMessage={getErrorMessages('createAnonymousUser')}
                mt={2}
                textAlign="center"
            />
            <Divider w="full" />
            <MainButton
                mt={8}
                w="full"
                loading={isRegisteringAnonymous}
                onClick={onSubmit}
            >
                <Trans>Get started!</Trans>
            </MainButton>
        </>
    )
})

export default RegisterAnonymousForm
