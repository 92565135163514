import React, { useState } from 'react'
import useUserStatus from 'components/hooks/useUserStatus'
import PageLoading from 'components/layout/PageLoading'
import PageError from 'components/layout/PageError'
import usePushHistory from 'components/hooks/usePushHistory'
import { editor } from 'routes/index'
import ProfilePage from './ProfilePage'
import ProfilePageAnonymous from './ProfilePageAnonymous'
import UpdateUserModalWithState from './UpdateUserModalWithState'
import UpdatePasswordModalWithState from './UpdatePasswordModalWithState'
import useFetchProfilePageData from './useFetchProfilePageData'
import ConvertAnonymousUserModalWithState from './ConvertAnonymousUserModalWithState'

const ProfilePageWithState = ({ match }) => {
    const { slug } = match.params
    const pushRouteWithSlug = usePushHistory()
    const [isUpdateProfileModalOpen, setIsUpdateProfileModalOpen] = useState(false)
    const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState(false)
    const [isConvertAnonymousUserModalOpen, setIsConvertAnonymousUserModalOpen] = useState(false)
    const { isAnonymous } = useUserStatus()
    const {
        error,
        isFetching,
        me,
        designs,
    } = useFetchProfilePageData()

    if (isFetching) {
        return <PageLoading />
    }
    if (typeof error !== 'undefined') {
        return <PageError error={error} />
    }

    if (isAnonymous) {
        return (
            <>
                <ProfilePageAnonymous
                    designs={designs}
                    onConvertAnonymousUser={() => setIsConvertAnonymousUserModalOpen(true)}
                    onContinueWithDesign={(designId) => pushRouteWithSlug(editor, { designId })}
                />
                {isConvertAnonymousUserModalOpen && (
                    <ConvertAnonymousUserModalWithState
                        onClose={() => setIsConvertAnonymousUserModalOpen(false)}
                    />
                )}
            </>
        )
    }

    return (
        <>
            <ProfilePage
                slug={slug}
                user={me}
                designs={designs}
                onUpdateUser={() => setIsUpdateProfileModalOpen(true)}
                onContinueWithDesign={(designId) => pushRouteWithSlug(editor, { designId })}
            />
            {isUpdateProfileModalOpen && (
                <UpdateUserModalWithState
                    onClose={() => setIsUpdateProfileModalOpen(false)}
                    onUpdatePassword={() => {
                        setIsUpdateProfileModalOpen(false)
                        setIsUpdatePasswordModalOpen(true)
                    }}
                    trajectorySlug={slug}
                    user={me}
                />
            )}
            {isUpdatePasswordModalOpen && (
                <UpdatePasswordModalWithState
                    onClose={() => setIsUpdatePasswordModalOpen(false)}
                    slug={slug}
                />
            )}
        </>
    )
}

export default ProfilePageWithState
