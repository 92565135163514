import React from 'react'
import { Text } from '@chakra-ui/core'
import { dateStringToEuroDateString } from 'util/converters'

const DateTag = ({ dateString, ...props }) => {
    const euroDateString = dateStringToEuroDateString(dateString)

    return (
        <Text
            color="colorGrayDark"
            fontSize="sm"
            {...props}
        >
            {euroDateString}
        </Text>
    )
}

export default DateTag
