import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import DEFAULT_ASSET_FRAGMENT from 'apollo/fragments/defaultAsset'

export const ASSET_EDITOR_PAGE_QUERY = gql`
    query assetEditorPageQuery($id: Int, $where: AssetsWhereWhereConditions) {
        asset (id: $id) {
            ...defaultAsset

        }
        assets (where: $where) {
            ...defaultAsset
        }
    }
    ${DEFAULT_ASSET_FRAGMENT}
`

const useFetchAssetEditorPageData = (assetId) => {
    const variables = {
        id: assetId,
        where: {
            column: 'ISREFERENCE',
            value: true,
        },
    }
    const {
        error,
        loading,
        data,
    } = useQuery(ASSET_EDITOR_PAGE_QUERY, { variables })

    if (error) {
        return ({ error })
    }

    if (loading) {
        return ({
            isFetching: loading,
            asset: {},
            referenceAsset: {},
        })
    }

    const { asset, assets } = data

    return ({
        error,
        isFetching: loading,
        asset,
        referenceAsset: assets[0],
    })
}

export default useFetchAssetEditorPageData
