import React from 'react'
import {
    Modal as ChakraModal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Scale,
    Flex,
    Button,
    Icon,
    useTheme,
    Stack,
} from '@chakra-ui/core'

const Modal = ({
    isOpen = true,
    onClose,
    isCloseable = true,
    initialFocusRef,
    modalTitle,
    modalContent,
    size = ['xs', 'md'],
    ...props
}) => {
    const theme = useTheme()

    return (
        <Scale in={isOpen}>
            {(styles) => (
                <ChakraModal
                    preserveScrollbar
                    isOpen={isOpen}
                    onClose={onClose}
                    closeOnOverlayClick={isCloseable}
                    closeOnEsc={isCloseable}
                    size={size}
                    initialFocusRef={initialFocusRef}
                >
                    <ModalOverlay opacity={styles.opacity} />
                    <ModalContent rounded="lg" p={8} {...styles} {...props}>
                        <Flex justify="space-between">
                            <ModalHeader as="h1" fontSize="lg" fontWeight="bold">{modalTitle}</ModalHeader>
                            {isCloseable && (
                                <Button
                                    variant="unstyled"
                                    minW="30px"
                                    h="30px"
                                    mt={4}
                                    mr={4}
                                    _focus={{ boxShadow: `0 0 0 3px ${theme.colors.colorPurple}` }}
                                    onClick={onClose}
                                >
                                    <Stack align="center">
                                        <Icon name="remove" stroke="colorGrayLight" size="24px" />
                                    </Stack>
                                </Button>
                            )}
                        </Flex>
                        <ModalBody>{modalContent}</ModalBody>
                    </ModalContent>
                </ChakraModal>
            )}
        </Scale>
    )
}

export default Modal
