import React from 'react'
import { Trans } from '@lingui/macro'
import ButtonWithTooltip from 'components/util/buttons/ButtonWithTooltip'
import {
    EDITOR_CONTROL_MODE_TRANSLATE,
    EDITOR_CONTROL_MODE_ORBIT,
    DRAW_MODE_NONE,
    ZOOM_IN,
    ZOOM_OUT,
} from 'editor/util/constants'
import EditorControlsDrawMenu from './EditorControlsDrawMenu'

const Design2DControlButtons = ({
    isReadOnly,
    editorHasGrid,
    editorDrawMode,
    isPlacingComments,
    editorControlMode,
    toggleGrid,
    toggleDraw,
    togglePlacingComments,
    onTakeScreenshot,
    setEditorControlMode,
    onZoomEditor,
}) => {
    if (isReadOnly) {
        return (
            <>
                <ButtonWithTooltip
                    type="grid"
                    tooltip={<Trans>Toggle grid</Trans>}
                    tooltipDirection="right"
                    onClick={toggleGrid}
                    isSelected={editorHasGrid === true}
                />
                <ButtonWithTooltip
                    type="translate"
                    tooltip={<Trans>Translate map</Trans>}
                    tooltipDirection="right"
                    onClick={() => setEditorControlMode(EDITOR_CONTROL_MODE_TRANSLATE)}
                    isSelected={editorControlMode === EDITOR_CONTROL_MODE_TRANSLATE}
                />
                <ButtonWithTooltip
                    type="rotate"
                    tooltip={<Trans>The controls are locked to translation</Trans>}
                    tooltipDirection="right"
                    onClick={() => setEditorControlMode(EDITOR_CONTROL_MODE_ORBIT)}
                    isSelected={editorControlMode === EDITOR_CONTROL_MODE_ORBIT}
                    disabled
                />
                <ButtonWithTooltip
                    type="zoomIn"
                    tooltip={<Trans>Zoom in on map</Trans>}
                    tooltipDirection="right"
                    onClick={() => onZoomEditor(ZOOM_IN)}
                />
                <ButtonWithTooltip
                    type="zoomOut"
                    tooltip={<Trans>Zoom out on map</Trans>}
                    tooltipDirection="right"
                    onClick={() => onZoomEditor(ZOOM_OUT)}
                />
            </>
        )
    }

    return (
        <>
            <ButtonWithTooltip
                type="grid"
                tooltip={<Trans>Toggle grid</Trans>}
                tooltipDirection="right"
                onClick={toggleGrid}
                isSelected={editorHasGrid === true}
            />
            <EditorControlsDrawMenu
                editorDrawMode={editorDrawMode}
                onDraw={toggleDraw}
                disabled={isPlacingComments}
            />
            <ButtonWithTooltip
                type="message"
                tooltip={<Trans>Place comment</Trans>}
                tooltipDirection="right"
                onClick={togglePlacingComments}
                isSelected={isPlacingComments}
                disabled={editorDrawMode !== DRAW_MODE_NONE}
            />
            <ButtonWithTooltip
                type="screenshot"
                tooltip={<Trans>Take screenshot</Trans>}
                tooltipDirection="right"
                onClick={onTakeScreenshot}
                disabled={editorDrawMode !== DRAW_MODE_NONE || isPlacingComments}
            />
            <ButtonWithTooltip
                type="translate"
                tooltip={<Trans>The controls are locked to translation</Trans>}
                tooltipDirection="right"
                isSelected
                disabled
            />
            <ButtonWithTooltip
                type="zoomIn"
                tooltip={<Trans>Zoom in on map</Trans>}
                tooltipDirection="right"
                onClick={() => onZoomEditor(ZOOM_IN)}
            />
            <ButtonWithTooltip
                type="zoomOut"
                tooltip={<Trans>Zoom out on map</Trans>}
                tooltipDirection="right"
                onClick={() => onZoomEditor(ZOOM_OUT)}
            />

        </>
    )
}

export default Design2DControlButtons
