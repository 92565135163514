import React, { useEffect } from 'react'
import PageLoading from 'components/layout/PageLoading'
import PageError from 'components/layout/PageError'
import { designs } from 'routes/index'
import usePushHistory from 'components/hooks/usePushHistory'
import TrajectoryNotFound from 'components/layout/TrajectoryNotFound'
import useAppState from 'components/hooks/useAppState'
import { getStateFromLocation } from 'util/entities'
import HomePage from './HomePage'
import useFetchHomePageData from './useFetchHomePageData'
import AuthModalWrapperWithState from '../auth/AuthModalWrapperWithState'

const HomePageWithState = ({
    trajectorySlug,
    location,
}) => {
    const { section } = getStateFromLocation(location)
    let { slug } = useAppState()
    if (typeof trajectorySlug !== 'undefined') {
        slug = trajectorySlug
    }
    const pushRouteWithSlug = usePushHistory()
    const {
        error,
        isFetching,
        trajectory,
    } = useFetchHomePageData(slug)

    useEffect(() => {
        if (typeof section !== 'undefined') {
            const sectionDiv = document.getElementById(section)
            if (sectionDiv !== null) {
                window.scrollTo(0, sectionDiv.offsetTop)
            }
        }
    }, [section])

    if (isFetching) {
        return <PageLoading />
    }
    if (typeof error !== 'undefined') {
        return <PageError error={error} />
    }
    if (trajectory === null) {
        return <TrajectoryNotFound slug={slug} />
    }

    return (
        <AuthModalWrapperWithState>
            {({
                onParticipate,
                isAuthenticated,
                isOnboardedForTrajectory,
            }) => (
                <HomePage
                    onParticipate={onParticipate}
                    isAuthenticated={isAuthenticated}
                    isOnboardedForTrajectory={isOnboardedForTrajectory}
                    onShowAllDesigns={() => pushRouteWithSlug(designs)}
                    trajectory={trajectory}
                    section={section}
                />
            )}
        </AuthModalWrapperWithState>
    )
}

export default HomePageWithState
