import React, { useRef } from 'react'
import {
    Text,
    Stack,
    Box,
    useTheme,
} from '@chakra-ui/core'
import { Trans } from '@lingui/macro'
import useEditorState from 'components/hooks/useEditorState'
import useAsyncEffect from 'components/hooks/useAsyncEffect'

const EditorContainer = ({
    onEditorIsLoaded = null,
    children,
}) => {
    const container = useRef()
    const theme = useTheme()
    const {
        cleanUpEditor,
        editorIsLoading,
        initializeEditor,
        setEditorIsLoading,
    } = useEditorState()

    useAsyncEffect(async () => {
        setEditorIsLoading(true)
        await initializeEditor(container.current, theme.colors.colorGrayLight)
        if (onEditorIsLoaded !== null) {
            await onEditorIsLoaded()
        }
        setEditorIsLoading(false)

        return cleanUpEditor
    }, [])

    console.log(editorIsLoading)

    return (
        <Box
            flex={1}
            bg="colorGrayLight"
        >
            {editorIsLoading && (
                <Stack
                    w="full"
                    h="full"
                    justify="center"
                    align="center"
                >
                    <Box
                        rounded="lg"
                        bg="colorWhite"
                        p={8}
                    >
                        <Text
                            fontSize="xl"
                            color="primaryIconColor"
                            fontWeight="medium"
                        >
                            <Trans>Initializing editor...</Trans>
                        </Text>
                    </Box>
                </Stack>
            )}
            <Box
                h="full"
                ref={container}
                display={editorIsLoading ? 'none' : 'block'}
            />
            {children}
        </Box>
    )
}

export default EditorContainer
